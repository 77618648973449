import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { TfiImport } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { LocationModal } from "./LocationModal";
import { getLocations } from "../../../../../services/operations/locationAPI";
import { useForm } from "react-hook-form";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export const LocationSettings = () => {
  ///////////////Dummy Data//////////////////
  const DummyData = [
    {
      name: "Back Office",
      address: "3891 Ranchview Dr. Richardson, California 62639",
    },
    {
      name: "Back Office",
      address: "3891 Ranchview Dr. Richardson, California 62639",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [location, setLocation] = useState(null);
  const [purchaseUpdated, setPurchaseUpdated] = useState(false);
  const [mode, setMode] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [searchQueryParam, setSearchQueryParam] = useState('');

  const getAllLocations = async () => {
    setLoading(true);
    const tempData = await getLocations();
    // console.log(tempData);
    const chunkedArray = chunkArray(tempData, 5);
    setValue("number" , pageNo+1)
    setTableData(chunkedArray[pageNo]);
    setTotalPage(chunkedArray.length);
    setStartIndex(pageNo * 5);
    setLoading(false);
  };

  useEffect(() => {
    getAllLocations();
    //   setTableData(DummyData)
  }, [purchaseUpdated , pageNo , totalPage]);

  // Function to chunk the array into arrays of size n
  function chunkArray(array, size) {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    // console.log("chunkedArray", chunkedArr);
    return chunkedArr;
  }

  const openLocationModal = (viewMode, location) => {
    setMode(viewMode);
    setLocation(location);
    setShowLocationModal(true);
  };

  

  const prevPage = () => {
    if (pageNo !== 0) {
      setPageNo(pageNo - 1);
    } else {
    //   console.log("m");
      return;
    }
  };

  const nextPage = () => {
    if (pageNo < totalPage - 1) {
      setPageNo(pageNo + 1);
    } else {
    //   console.log("o");
      return;
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchPage();
    }
  };

  const searchPage = () => {
    let desiredPage = Number(searchQueryParam);
    if (desiredPage <= totalPage && desiredPage > 0) {
      setPageNo(desiredPage - 1);
      // console.log("updated" , page)
    } else {
      //  console.log(desiredPage)
    }
  };

  const {
    register,
    setValue
  } = useForm();

  return (
    <div className="w-[75%] h-fit relative">
      <div className="flex items-center gap-x-5 justify-end">
        <button
          className="font-semibold text-black text-lg flex items-center gap-x-1 "
          onClick={() => openLocationModal("Create")}
        >
          <AiOutlinePlusCircle />
          Add Location
        </button>
        <button className="font-semibold text-black text-lg flex items-center gap-x-1 ">
          <TfiImport /> Import Locations
        </button>
      </div>

      <div className="w-full overflow-hidden rounded-lg min-h-[320px]">
        {loading === true ? (
          <div className="w-full min-h-[320px] bg-white text-xl text-black text-opacity-70 font-inter flex items-center justify-center mt-1">
            Loading...
          </div>
        ) : tableData.length === 0 ? (
          <div className="w-full min-h-[320px] bg-grey-white text-xl text-black text-opacity-70 font-inter flex items-center justify-center mt-1">
            No Data Found. Please Try Again
          </div>
        ) : (
          <table className="w-full mt-5 mb-2 rounded-t-lg shadow-md overflow-hidden ">
            <thead className="h-[50px] bg-grey-25 text-black text-opacity-60 font-semibold font-inter  rounded-t-lg ">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Address</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              <>
                {tableData.map((location, index) => (
                  <tr
                    key={index}
                    className="bg-white h-[50px] font-inter text-black text-opacity-60 text-center"
                  >
                    <td>{`${
                      startIndex + index < 9 ? `0${startIndex + index + 1}` : `${startIndex + index + 1}`
                    }`}</td>
                    <td>{location.name}</td>
                    <td>{location.address}</td>
                    <td onClick={() => openLocationModal("Edit", location)}>
                      <Link className="w-full flex justify-center hover:text-opacity-100 hover:text-black">
                        <FaRegEdit />
                      </Link>
                    </td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        )}
      </div>

      <div className="w-full overflow-hidden rounded-lg z-0 my-7 flex justify-center ">
        <button
          className=" p-4 rounded-md shadow-md text-grey-50 bg-grey-100 bg-opacity-30 hover:text-black"
          onClick={prevPage}
        >
          {" "}
          <MdKeyboardArrowLeft />
        </button>
        <div className="flex items-center mx-4">
          <input
            type="number"
            id="number"
            name="number"
            {...register("number", {})}
            className="appearance-none bg-white w-[35px] remove-arrow outline-none border-none border-transparent focus:border-transparent focus:ring-0"
            onChange={(event) => setSearchQueryParam(event.target.value)}
            onKeyDown={handleKeyPress}
          />
          {/* <p>{pageNo + 1}</p> */}
          <p>of</p>
          <p className="ml-3">{totalPage}</p>
        </div>
        <button
          className=" p-4 rounded-md shadow-md text-grey-50 bg-grey-100 bg-opacity-30 hover:text-black"
          onClick={nextPage}
        >
          {" "}
          <MdKeyboardArrowRight />
        </button>
      </div>

      {showLocationModal && (
        <LocationModal
          setShowLocationModal={setShowLocationModal}
          mode={mode}
          location={location}
          setPurchaseUpdated={setPurchaseUpdated}
        />
      )}
    </div>
  );
};
