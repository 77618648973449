import React, { useEffect, useState } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useLocation, useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { ProfileDropdown } from "./ProfileDropdown";


const Navbar = () => {
   const [heading , setHeading] = useState("");
  const location = useLocation();
  let link = location.pathname.split("/");
   
  useEffect(() => {
    if(link.includes("update-purchase-requests")){
      setHeading(link[3].replaceAll("-", " "));
    }else{
     setHeading(link[link.length - 1].replaceAll("-", " "));
    }
  } , [location , link])

 
   const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  return (
    <div className=" w-[calc(100vw-280px)] h-[6.5rem] pl-[32px] flex items-center justify-between z-[20] ">
      <div className="z-20">
        <div
          className={`h-full flex items-center gap-x-2  ${
            heading === "" ? "hidden" : "block"
          }`}
        >
         <button  onClick={() => navigate(-1)}><FiArrowLeftCircle className="text-green-5 text-[32px]" /></button>
          
          <p className="font-semibold text-xl text-black font-inter capitalize">
            {heading}
          </p>
        </div>

        <div
          className={`w-[30%] flex justify-start items-center absolute top-8 left-12 ${
            heading === "" ? "block" : "hidden"
          }`}
        >
          <div className=" flex  items-center mt-1 relative">
            <FaCaretLeft className="text-4xl  text-green-5 font-semibold absolute top-0 right-5" />
            <FaCaretRight className="text-4xl text-green-5 " />
          </div>
          <p className="text-4xl font-extrabold text-green-5 tracking-[0.1em] ">
            Procura<span className="font-extralight">Flow</span>
          </p>
        </div>
      </div>

      <div className="flex items-center gap-x-4 mr-6 z-20">
        {token === null && (
          <div className="flex items-center gap-x-4 ">
            <Link to={"/login"}>
              <button className="bg-green-5 text-white py-[8px] px-[12px] rounded-[8px] z-20">
                Log in
              </button>
            </Link>
            <Link to="/signup">
              <button className="bg-green-5 text-white py-[8px] px-[12px] rounded-[8px]">
                Sign Up
              </button>
            </Link>
          </div>
        )}

        {token !== null && <ProfileDropdown />}
      </div>
    </div>
  );
};

export default Navbar;
