import React from "react";
import Create from "../admin_common/Create";
import Draft from "../admin_common/Draft";
import Pending from "../admin_common/Pending";
import { GetPurchase } from "../PurchaseRequests/GetPurchase/GetPurchase";
import { GetQuotationRequests } from "./GetQuotationRequests/GetQuotaionRequests";


export default function QuotationRequests() {
    return (
        <div className="  w-[calc(100vw-280px)] min-h-screen bg-grey-25 ">
      <div className="w-[95%] mx-auto flex flex-col items-center gap-x-4 ">
        <div className="flex items-center justify-start w-full gap-x-5 mt-14">
          <Pending
            pending={2}
            linkedto={"/admin/quotation-requests/update-quotation-requests/pending-quotation-request"}
          />
          <Draft
            drafts={4}
            linkedto={"/admin/quotation-requests/update-quotation-requests/draft-quotation-request"}
          />
          <Create
            linkto={"/admin/quotation-requests/create-quotation-requisition"}
          />
        </div>
           
           {/* table  */}
        <div className="w-full my-10">
          <GetQuotationRequests />
        </div>
      </div>
    </div>
    )
}
