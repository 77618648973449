import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FilterData } from "../PurchaseRequests/GetPurchase/FilterData";
import { LuIndianRupee } from "react-icons/lu";
import { InventoryModal } from "./InventoryModal";

export const Inventory = () => {
     ////////////////Dummy Data//////////////////////
     const dummyData = [{
        itemnName:"Mouse",
        SKU:"PFI0089",
        itemQuantity:2,
        itemPrice:300,
        itemCategory:"IT"
    }]
    const [tableData , setTableData] = useState(null);
    const [filteredData , setFilteredData] = useState([]);
    const [inventoryModal ,setInventoryModal] = useState(null);

    useEffect(() => {
           setTableData(dummyData)
        //    console.log(tableData)
           
    },[])
  return (
    <div className="w-[calc(100vw-280px)] flex flex-col justify-start pt-20 gap-y-12 min-h-screen ">
    <div className="w-[90%] mx-auto">
        {inventoryModal && <InventoryModal inventoryModal={inventoryModal}/>}
    </div>

    {
      tableData !== null ? (
          <div className=" w-full flex flex-col gap-y-8">

      {/* filter component  */}
      <div className="w-[90%] mx-auto">
      <FilterData
            data={tableData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
          />
      </div>
      {/* table  */}
      <div className="w-[90%] mx-auto overflow-hidden rounded-xl shadow-lg">
        <table className="w-full">
          <thead className="rounded-t-xl h-[40px] px-5 bg-grey-75 text-black text-opacity-[70%] font-inter">
            <tr>
              <th>No.</th>
              <th> Item Name</th>
              <th>SKU</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
              <th>Category</th>
              <th>Action</th>
              
            </tr>
          </thead>
          <tbody className="bg-white">
              {
                  tableData.map((item , index) => (
                      <tr key={index} className="h-[40px] text-center text-black text-opacity-70 font-inter cursor-pointer  " onClick={() => setInventoryModal(item)}>
                          <td>{`${index < 10 ? `0${index+1}` : `${index+1}`}`}</td>
                          <td>{item?.itemnName}</td>
                          <td>{item?.SKU}</td>
                          <td>{`${item?.itemQuantity < 10 ? `0${item?.itemQuantity}` : `${item?.itemQuantity}`}`}</td>
                          <td><p className="flex items-center justify-center gap-x-1"><LuIndianRupee/>{item?.itemPrice}</p></td>
                          <td><p className="flex items-center justify-center gap-x-1"><LuIndianRupee/>{item?.itemQuantity * item?.itemPrice}</p></td>
                          <td>{item?.itemCategory}</td>
                          <td><p className="flex justify-center items-center cursor-pointer hover:text-grey-50"><FaRegEdit /></p></td>
                      </tr>
                  ))
              }
          </tbody>
        </table>
      </div>
    </div>
      ) : (<p className="w-full text-center text-2xl text-black text-opacity-60 flex items-center justify-center gap-x-3">
          <BiErrorCircle />
          No Data Found</p>)
    }
  </div>
  )
}
