import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../slices/purchasesSlice";
const Draft = ({ drafts , linkedto}) => {
  const {totalPurchases , filters} = useSelector((state) => state.purchases);
  const totalDraftRequest = totalPurchases.filter((item) => item.status === "Draft");
   const dispatch = useDispatch();

   const isFilterPresent = (filterArray) => {
    for (let i=0 ; i<filters.length ; i++){
      if(filters[i].type !== filterArray[i].type && filters[i].value !== filterArray[i].value){
        return false;
      }
    }

    return true;
   }
  const applyDraftFilter = () => {
    const value ="Draft"
    const filterArray = [{type:"status" , value}];
    if(filters.length === 1 && isFilterPresent(filterArray) ){
      dispatch(setFilters([]));
    }else{
      // set this filter 
      dispatch(setFilters(filterArray));
    }
      

  }
  return (
   
      <div className=" px-6 py-[11px] bg bg-white rounded-lg flex items-center justify-center gap-x-3 shadow-lg cursor-pointer" onClick={applyDraftFilter}>
        <div className=" h-10 w-10 rounded-full flex justify-center items-center bg-grey-25">
          <p className="text-black text-lg">{totalDraftRequest.length}</p>
        </div>
        <p className="text-lg ">Drafts</p>
      </div>
  
  );
};

export default Draft;
