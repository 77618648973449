import React from 'react'
import CreateFormTemplate from '../../../common/CreateFormTemplate'

export const CreateExpense = () => {
  return (
    <div className='w-[calc(100vw-280px)] min-h-screen py-10 flex items-center'>
         
         <div className=' absolute top-[1rem] left-[0px] w-screen  bg-grey-25 min-h-screen flex  justify-center pb-10' >
              <CreateFormTemplate
               issueDate={true}
               dueDate={true}
               location={true}
               expenseID={true}
               note={true} />
         </div>
    </div>
  )
}
