import React, { useEffect, useRef, useState } from 'react'
import { FaBell } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { MdSpaceDashboard } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { logout } from '../../services/operations/userAPI';
export const ProfileDropdown = () => {
    const { user } = useSelector((state) => state.profile);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const profileRef = useRef();
    const menuRef = useRef();
    const[showModal , setShowModal] = useState(false);

    useEffect(() => {
        let handler = (e) => {
            if(!menuRef.current.contains(e.target) &&!profileRef.current.contains(e.target)) {
                setShowModal(false)
            }
        };
        document.addEventListener("mousedown" , handler);

        return() => {
            document.removeEventListener("mousedown" , handler)
        }
    })
  return (
    <div className="flex items-center gap-x-5 mr-6">
        <FaBell className="text-2xl text-grey-50 cursor-pointer" />

        {/* image  */}
        <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
          <img src={user?.photo} className="h-full w-full" alt={`profile-${user?.name}`} />
        </div>

        <div className="flex flex-col ">
          <p className="font-semibold text-[16px] text-black">{user?.name}</p>
          <Link
            className="font-inter font-extralight text-[12px]"
            to={"/admin/dashboard"}
          >
            View Profile
          </Link>
        </div>

           <div ref={profileRef} onClick={() => setShowModal(!showModal)} >
           <BsThreeDotsVertical className="text-[24px] ml-5  cursor-pointer hover:text-green-5" />
           </div>
        

         {/* dropdown  */}
        <div ref={menuRef} className={`flex flex-col gap-y-3 rounded-lg px-4 py-3 shadow-lg absolute top-[6rem] right-5 z-[100] bg-white ${showModal === true ? "block" : "hidden"}`} >
            <Link to={"/admin/dashboard"}>
            <button className='flex items-center gap-x-1 text-lg text-grey-50 rounded-md hover:bg-green-5 px-2 py-1 hover:text-white w-full'>
                <MdSpaceDashboard  />
                <p>Dashboard</p>
                
            </button>
            </Link>

            
            <button  className='flex items-center gap-x-1 text-lg text-grey-50 rounded-md hover:bg-green-5 px-2 py-1 hover:text-white w-full'
            onClick={() => dispatch(logout(navigate)) } >
                <IoLogOut />
                <p>Logout</p> 
            </button>

        </div>
      </div>
  )
}
