import React, { useEffect, useState } from "react";
import { getPurchases } from "../../../../../services/operations/purchaseAPI";
import { LuIndianRupee } from "react-icons/lu";
import { Link } from "react-router-dom";
import { MdError } from "react-icons/md";
import { FilterData } from "../../PurchaseRequests/GetPurchase/FilterData";


export const GetQuotationRequests = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  

  const getAllPurchases = async () => {
    //update it
    const tempData = await getPurchases();
    setData(tempData);
    setFilteredData(tempData);
    // console.log(data);
    return tempData;
  };


  useEffect(() => {
    // getPurchases
    setLoading(true);
    getAllPurchases();
    setLoading(false);
  }, []);

  return (
    <div>
      <div>
        {loading === true ? (
          <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75">
            Loading...
          </p>
        ) : (
          <>
            <FilterData
              data={data}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
            />
            {filteredData.length === 0 ? (
              <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75 gap-x-2 mt-10">
                <MdError className="text-2xl  text-opacity-50" />
                No Purchase Requests Found
              </p>
            ) : (
              <div>
                {/* //filter component  */}
                {/* table  */}
                <div className="w-full rounded-lg overflow-hidden shadow-lg mt-10">
                  <table className="w-full rounded-lg">
                    <thead className="text-grey-50 text-opacity-85 bg-grey-75 h-[35px] py-4 text-center">
                      <tr className=" font-[200]">
                        <th>No.</th>
                        <th>Item Name</th>
                        <th>Request ID</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        <th>Created On</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody className="bg-grey-5 text-center">
                      {filteredData.map((item, index) => (
                        <tr
                          key={index}
                          className="h-[55px] font-inter font-extralight text-opacity-75 text-black"
                        >
                          <td>{`${
                            index < 9 ? `0${index + 1}` : `${index + 1}`
                          }`}</td>
                          <td>{item?.items[0]?.name}</td>
                          <td>{item?._id}</td>
                          <td>{`${item?.items[0]?.quantity < 10  ? `0${item?.items[0]?.quantity}` : `${item?.items[0]?.quantity}`  }`}</td>
                          <td>
                            <Link
                              to={`${
                                item?.status === "Draft"
                                  ? "/admin/quotation-requests/update-quotation-requests/draft-quotation-request"
                                  : item?.status === "Pending"
                                  ? "/admin/quotation-requests/update-quotation-requests/pending-quotation-request"
                                  : "/admin/quotation-requests/update-quotation-requests/completed-quotation-request"
                              }`}
                            >
                              <p
                                className={` rounded-2xl text-sm py-1 w-[80%] mx-auto flex items-center justify-center ${
                                  item?.status === "Draft"
                                    ? "bg-blue-5 bg-opacity-30 text-blue-5"
                                    : item.status === "Pending"
                                    ? " bg-orange-5 bg-opacity-30 text-orange-5"
                                    : "bg-blue-5 bg-opacity-30 text-blue-5"
                                }`}
                              >
                                {item?.status}
                              </p>
                            </Link>
                          </td>
                          <td>{item?.createdAt.split("T").at("0")}</td>
                          <td>
                            <p className="flex items-center gap-x-1 justify-center">
                              <LuIndianRupee />
                              {item?.items[0]?.price}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
