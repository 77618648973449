
import React, { useEffect, useState } from "react";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdError } from "react-icons/md";
import { FilterData } from "../../PurchaseRequests/GetPurchase/FilterData";
import { Pagination } from "../../admin_common/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setPurchasePages,
  setTableData,
  setTotalPurchases,
} from "../../../../../slices/purchasesSlice";
import { setPage, setTotalPages } from "../../../../../slices/paginationSlice";
import { getPurchaseOrders, getPurchaseOrdersByUser } from "../../../../../services/operations/purchaseOrderAPI";

export const GetPurchaseOrders = () => {
  const { totalPurchases, purchasePages, tableData, filters } = useSelector(
    (state) => state.purchases
  );

  const {user} = useSelector((state) => state.profile)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { page, totalPages } = useSelector((state) => state.pagination);
  const [startIndex, setStartIndex] = useState(0);
  // console.log(tableData) ;

  // whenever filters array changes update the data
  useEffect(() => {
    setLoading(true);
    //  getAllPurchases();
    getFilteredPurchases();
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    setStartIndex(page * 5);
  }, [page]);

  const getFilteredPurchases = async () => {
    setLoading(true);
    let tempData =[];
    if(user.role === "admin"){
       tempData = await getPurchaseOrders();
    }else{
      tempData = await getPurchaseOrdersByUser(user?._id);
    }
   
    // update  the state
    dispatch(setTotalPurchases(tempData));
    // console.log("Total Purchases - ", totalPurchases);

    if (filters.length === 0) {
      // console.log("Inside first case")
      const chunkedArray = chunkArray(tempData, 5);
      dispatch(setPurchasePages(chunkedArray));

      dispatch(setPage(0));
      dispatch(setTotalPages(chunkedArray.length));
      // console.log(page , totalPages);
      setLoading(false);
      return;
    }

    // apply filters on the total puchases
    const filteredPurchases = applyMultipleFilters(totalPurchases, filters);
    //  console.log("Filterd Purchases - ", filteredPurchases);

    // convert them in page format
    const chunkedArray = chunkArray(filteredPurchases, 5);
    dispatch(setPurchasePages(chunkedArray));
    //  console.log("purchasePages - " , purchasePages);

    //  update tableData  and update page no and total pages
    dispatch(setPage(0));
    dispatch(setTotalPages(chunkedArray.length));
    //  console.log(page , totalPages);
    setLoading(false);
  };

  const applyMultipleFilters = (data, filtersArray) => {
    if (filtersArray.length === 0) {
      return data; // No filters, return original data
    }

    // Filter based on each filter independently using logical OR (||)
    const filteredData = data.filter((item) => {
      return filtersArray.some((filter) => {
        switch (filter.type) {
          case "status":
            return item.status === filter.value;
          case "location":
            return item.location === filter.value;
          case "department":
            return item.department === filter.value;
          case "deliveryDate":
            const deliveryDate = new Date(item.deliveryDate);
            const value1 = new Date(filter.value.value1);
            const value2 = new Date(filter.value.value2);
            return deliveryDate >= value1 && deliveryDate <= value2;
             // console.log(deliveryDate , value1 , value2 , index , deliveryDate >= value1 && deliveryDate <= value2 );
          // Add more cases for additional filter types if needed
          default:
            return false; // Default to false for unknown filter type
        }
      });
    });

    // If there is only one type of filter, return filtered data directly
    if (filtersArray.every((filter) => filter.type === filters[0].type)) {
      return filteredData;
    }

    // Filter the results that satisfy both conditions using logical AND (&&)
    const finalFilteredData = filteredData.filter((item) => {
      return filtersArray.every((filter) => {
        switch (filter.type) {
          case "status":
            return item.status === filter.value;
          case "location":
            return item.location === filter.value;
          case "department":
            return item.department === filter.value;
          case "deliveryDate":
            const deliveryDate = new Date(item.deliveryDate);
            const value1 = new Date(filter.value.value1);
            const value2 = new Date(filter.value.value2);
            return deliveryDate >= value1 && deliveryDate <= value2;
          // Add more cases for additional filter types if needed
          default:
            return false; // Default to false for unknown filter type
        }
      });
    });

    return finalFilteredData;
  };

  // Function to chunk the array into arrays of size n
  function chunkArray(array, size) {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    // console.log("chunkedArray", chunkedArr);
    return chunkedArr;
  }

  return (
    <div>
      <div>
        {loading === true ? (
          <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75">
            Loading...
          </p>
        ) : (
          <>
            <FilterData />
            {purchasePages.length === 0 ? (
              <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75 gap-x-2 mt-10">
                <MdError className="text-2xl  text-opacity-50" />
                No Purchase Requests Found
              </p>
            ) : (
              <>
                <div>
                  {/* //filter component  */}
                  {/* table  */}
                  <div className="w-full min-h-[320px] rounded-lg overflow-hidden mt-10">
                    <table className="w-full rounded-lg">
                      <thead className="text-grey-50 text-opacity-85 bg-grey-75  shadow-lg h-[35px] py-4 text-center">
                      <tr className=" font-[200]">
                        <th>No.</th>
                        <th>Status</th>
                        <th>Location/Purchaser</th>
                         <th>Supplier</th>
                        <th>Gross</th>
                        <th>Delivery Date</th>
                      </tr>
                      </thead>
                      <tbody className="bg-grey-5 text-center">
                        {purchasePages[page].map((report, index) => (
                            <tr className={`h-[50px] bg-white text-black text-opacity-60 font-inter text-center ${  report.status === "Rejected" ? "cursor-not-allowed"  : ""}`} key={index}>
                            <td>{`${startIndex + index <10 ? `0${startIndex + index+1}` : `${startIndex + index+1}`}`}</td>
                            <td>
                                <Link
                                  to={`${
                                    report?.status === "Draft"
                                      ? "/admin/purchase-orders/update-purchase-orders/draft-purchase-order"
                                      : report?.status === "Pending"
                                      ? "/admin/purchase-orders/update-purchase-orders/pending-purchase-order"
                                      : report?.status === "Approved"? "/admin/purchase-orders/update-purchase-orders/approved-purchase-order" : ""
                                  }`}
                                >
                                  <p
                                    className={` rounded-2xl py-1 w-[70%] mx-auto text-sm flex items-center justify-center ${
                                      report?.status === "Draft"
                                        ? "bg-blue-5 bg-opacity-30 text-blue-5"
                                        : report.status === "Pending"
                                        ? " bg-orange-5 bg-opacity-30 text-orange-5"
                                        : report.status === "Approved" ? "bg-green-5 bg-opacity-30 text-green-5" : report.status === "Rejected" ? "bg-red-25 bg-opacity-30 text-red-25" : ""
                                    }`}
                                  >
                                    {report?.status}
                                  </p>
                                </Link>
                              </td>
                            <td>{report?.location}</td>
                            <td>{report?.supplier}</td>
                            <td><p className='flex items-center gap-x-1 w-full justify-center '><FaIndianRupeeSign/>  {report?.items[0]?.price *
                                    report?.items[0]?.quantity}</p></td>
                            <td>{report.deliveryDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

