import React from "react";
import { SettingsSidebar } from "./SettingsSidebar";
import { Outlet } from "react-router-dom";

export const SettingsLayout = () => {
  return (
    <div className=" absolute  top-[6.5rem] left-[0px] w-screen bg-grey-25 min-h-screen">
      <div className="w-[calc(100vw-280px)] min-h-screen flex flex-col gap-y-6 py-10 bg-white">

        <div className="w-[90%] mx-auto bg-grey-25 bg-opacity-20 font-semibold  rounded-lg  flex items-center justify-between p-4 ">
          <p className="text-2xl font-inter">Settings</p>
          <div className="flex items-center gap-x-3">
            <button className="py-2 px-4 rounded-md font-semibold text-green-5 border-2">Cancel</button>
            <button className="py-3 px-4 rounded-md font-semibold text-white bg-green-5">Save Changes</button>
          </div>
        </div>
        <div className="flex items-start gap-0 justify-between bg-white my-10 w-[90%] mx-auto py-5 h-fit">
          <SettingsSidebar />
          <Outlet />
        </div>
      </div>
    </div>
  );
};
