//const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const REACT_APP_BASE_URL = "https://app.procuraflow.com/api";

// const BASE_URL = process.env.SERVER_BASE_URL;

export const authEndpoints = {
  SIGNUP_API : REACT_APP_BASE_URL + "/users/register",
  LOGIN_API : REACT_APP_BASE_URL + "/users/login",
  RESET_PASSWORD_TOKEN_API : REACT_APP_BASE_URL + "/users/forgotpassword",
  RESET_PASSWORD_API : REACT_APP_BASE_URL + "/users/resetpassword"
}

export const userEndpoints = {
  GETUSER_API : REACT_APP_BASE_URL + "/users/getuser",
  INVITE_USER_API : REACT_APP_BASE_URL + "/users/invite",
  REGISTER_USERS_API : REACT_APP_BASE_URL + "/users/registerusers",
  GET_ALL_USERS_API : REACT_APP_BASE_URL + "/users/getallusers"
 
}

export const purchaseEndpoints = {
  CREATE_PURCHASE_API : REACT_APP_BASE_URL + "/purchase/createpurchase",
  GET_ALL_PURCHASE_API : REACT_APP_BASE_URL + "/purchase",
  UPDATE_PURCHASE_STATUS : REACT_APP_BASE_URL + "/purchase/status",
  DELETE_PURCHASE_API : REACT_APP_BASE_URL + "/purchase",
  UPDATE_PURCHASE_API : REACT_APP_BASE_URL + "/purchase",
  CLONE_PURCHASE_API  : REACT_APP_BASE_URL + "/purchase",
}

export const productEndpoints = {
  CREATE_PRODUCT_API : REACT_APP_BASE_URL + "/products/createproduct",
  GET_PRODUCTS_API : REACT_APP_BASE_URL + "/products/"
}

export const locationEndpoints ={
  ADD_LOCATION_API : REACT_APP_BASE_URL + "/location/",
  LOCATION_OPERATION_API : REACT_APP_BASE_URL + "/location",
}

export const purchaseOrderEndpoints = {
  CREAT_PURCHASE_ORDER_API  : REACT_APP_BASE_URL + "/purchaseorder/",
  CREATE_ORDER_FROM_REQUEST_API  : REACT_APP_BASE_URL + "/purchaseorder/createfromrequest",
  GET_ALL_PURCHASE_ORDERS_API : REACT_APP_BASE_URL + "/purchaseorder" ,
  UPDATE_PURCHASE_ORDER_STATUS : REACT_APP_BASE_URL + "/purchaseorder/status",
  DELETE_PURCHASE_ORDER_API : REACT_APP_BASE_URL + "/purchaseorder",
  UPDATE_PURCHASE_ORDER_API : REACT_APP_BASE_URL + "/purchaseorder", 
  CLONE_PURCHASE_ORDER_API  : REACT_APP_BASE_URL + "/purchaseorder",
}
