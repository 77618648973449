import React from 'react'
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import SignupForm from '../components/core/Auth/SignupForm';


const Signup = () => {
  return (
    <div className='absolute top-0 left-0 w-screen min-h-screen flex justify-center items-center bg-grey-25  z-20   pb-10 pt-5' >
        
    <div className='min-h-[70%] min-w-[45%] flex flex-col gap-y-6 px-10 py-8 rounded-2xl shadow-md bg-white '>
        {/* heading  */}
        <Link to={"/"}>
          <div className='w-full flex justify-center items-center'>
                <div className=' flex  items-center mt-1 relative'>
                <FaCaretLeft className='text-4xl  text-green-5 font-semibold absolute top-0 right-5'/>
                <FaCaretRight className='text-4xl text-green-5 '/>
                </div>
                <p className='text-4xl font-extrabold text-green-5 tracking-[0.1em] '>Procura<span className='font-extralight'>Flow</span></p>
            </div>
          </Link>

        <SignupForm/>
          
        <Link to={"/login"}>
        <p className='text-lg text-grey-100 w-full text-center my-2 '>Already have an account ?  <span className=' text-green-5 font-semibold'> Sign in here </span> </p>
       
        </Link>
    </div>

</div>
  )
}

export default Signup