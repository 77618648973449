import React, { useEffect, useState } from "react";
import { BiRupee } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { IoIosLink } from "react-icons/io";
import { useParams } from "react-router-dom";
import { getPurchases } from "../../../../../services/operations/purchaseAPI";
import { UpdatePurchaseOrderModal } from "./UpdatePurchaseModal";
import { getPurchaseOrders, getPurchaseOrdersByUser } from "../../../../../services/operations/purchaseOrderAPI";
import { useSelector } from "react-redux";
import { DownloadPurchaseOrder } from "./DownloadPurchaseOrder/DownloadPurchaseOrder";


export const UpdatePurchaseOrders = () => {

    
  const [loading  ,setLoading]  = useState(false);
  let {status}  = useParams();
  status = status.split("-").at("0").charAt(0).toUpperCase() + status.split("-").at("0").slice(1);

  const[data, setData] = useState([]);
  const[filteredData , setFilteredData] = useState([]);
  const [updateModalData , setUpdateModalData] = useState(null);
  const [showPerviewModal , setShowPreviewModal] = useState(null);
  const[downloadType , setDownloadType] = useState("");
  const {user} = useSelector((state) => state.profile);
  
  const getAllPurchases = async () => {
   let  tempData = [];
   if(user.role === "admin"){
    tempData = await getPurchaseOrders();
   } else{
      tempData = await getPurchaseOrdersByUser(user._id);
   }
    let filteredPurchases = tempData.filter((item) => item.status === status);
    setUpdateModalData(filteredPurchases[0])
    setData(tempData);
    setFilteredData(filteredPurchases);
  };

  useEffect(() => {
    setLoading(true);
    getAllPurchases();
    setLoading(false);
  },[])
  
  return (
    <div className=" w-[calc(100vw-280px)] py-10 bg-grey-25 min-h-screen">
    {
      loading ? (<p>Loading...</p>) : filteredData.length !== 0 ? (
        <div className="w-[95%] mx-auto flex flex-col items-center gap-x-4 pl-4  ">
      
        <div className="w-full">
          <UpdatePurchaseOrderModal updateModalData = {updateModalData}  setUpdateModalData={setUpdateModalData} setFilteredData={setFilteredData} /> 
        </div>
  
          <div className="w-full flex justify-end pl-30 relative">
              {
                  status === "Approved" ? (
                  <div>
                         <p className="text-lg font-inter">Download Attachements</p>
                         <div className="flex items-center gap-x-4 mt-4">
                          <button className="flex flex-row-reverse  items-center gap-x-1 rounded-2xl shadow-lg bg-grey-5 px-5 py-1" onClick={() => {
                            setShowPreviewModal(true);
                            setDownloadType("PDF")
                          }}>
                              <FiDownload/>
                              <p>PDF</p>
                          </button>
  
                          <button className="flex flex-row-reverse items-center gap-x-1 rounded-2xl shadow-lg bg-grey-5 px-5 py-1" onClick={() => {
                            setShowPreviewModal(true);
                            setDownloadType("CSV")
                          }}>
                              <FiDownload/>
                              <p>CSV</p>
                          </button>
                          </div>
                  </div>) : 
                  (<div>
                      <p className="text-lg font-inter">Upload Attachements</p>
                      <button className="flex   items-center gap-x-1 rounded-2xl shadow-lg bg-grey-5 px-10 py-1 mt-4">
                              <IoIosLink />
                              <p>Upload Attachements</p>
                          </button>
                  </div>)
              }
                {showPerviewModal && (<DownloadPurchaseOrder updateModalData={updateModalData}  setShowPreviewModal={setShowPreviewModal} downloadType={downloadType} />)}
          </div>
  
          {/* table  */}
          <div className="mt-10 rounded-lg shadow-lg w-full overflow-hidden">
            <table className="w-full ">
              <thead className="bg-grey-75 h-[30px] font-inter text-opacity-50  text-black">
                  <tr><th>No.</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>Total</th>
                <th>Category</th>
                <th>Supplier</th></tr>
              </thead>
       
              <tbody className="bg-grey-5 text-center">
                  {
                      filteredData.map((item, index) => (
                          <tr key={item?._id}
                          onClick={() => setUpdateModalData(item)}
                          className=" h-[40px] font-inter text-black text-opacity-65 cursor-pointer">
                                <td>{index < 10 ? `0${index+1}` : `${index+1}`}</td>
                                <td>{item?.items[0]?.name}</td>
                                <td>{item?.items[0]?.quantity < 10 ? `0${item?.items[0]?.quantity}` : `${item?.items[0]?.quantity}`}</td>
                                <td><p className="flex items-center justify-center gap-x-0">
                                  <BiRupee />
                                  {item?.items[0]?.price}
                                  </p></td>
                                <td>
                                  <p className="flex items-center justify-center gap-x-0">
                                  <BiRupee />
                                  {`${item?.items[0]?.quantity * item?.items[0]?.price}`}
                                  </p>
                                </td>
                                <td>{item?.items[0]?.category}</td>
                                <td>{item?.supplier}</td>
  
                          </tr>
                      ))
                  }
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="my-32 text-2xl text-black text-center text-opacity-70 font-semibold w-full ">No Data Found</div>
      )
    }
    </div>
  );
};
