import React from "react";
import CreateFormTemplate from "../../../common/CreateFormTemplate";

export const CreatePurchaseOrder = () => {
  return (
    <div className=" absolute top-[1rem] left-[0px] w-screen  bg-grey-25 min-h-screen flex  justify-center pb-10">
      <CreateFormTemplate deliveryDate={true} supplier={true} location={true} department={true} sendPO={true} note={true} attachements={true} selectItems ={true} type={"PurchaseOrder"} paymentTerms={true}/>
    </div>
  );
};
