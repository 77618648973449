import React, { useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { CSVLink } from "react-csv";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineFileDownload } from "react-icons/md";

export const DownloadPurchase = ({ data }) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const location = useLocation();
  const type = location.pathname.split("/").at("-1").split("-").join("");



  return (
    <div className="flex items-center gap-x-2 relative">
      <SlOptionsVertical
        onClick={() => setShowDownloadModal((prev) => !prev)}
        className="cursor-pointer "
      />

      <div
        className={` absolute top-10 -right-1 min-w-[170px] bg-white  rounded-md shadow-md py-3 items-center  w-fit flex flex-col gap-3 ${
          showDownloadModal === true ? "block" : "hidden"
        }`}
      >
      
      <Link to={`${type === "purchaserequests" ? `/admin/purchase-requests/preview-purchase-requests/${type}` : 
                     type === "purchaseorders" ? `/admin/purchase-orders/preview-purchase-orders/${type}`: ""}`} className="w-full">
       <p className="text-black text-opacity-60 font-semibold cursor-pointer hover:bg-grey-25 w-full text-center py-1.5 rounded-md flex items-center gap-x-2 justify-center">
       <MdOutlineFileDownload className="text-lg mt-1"/>
          Download 
        </p>
       </Link>

  
       
      </div>
    </div>
  );
};
