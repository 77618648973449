import React from 'react'
// import * as Icons from "react-icons/vsc"
import * as Icons from "react-icons/fa"

import {NavLink , matchPath , useLocation} from "react-router-dom"
// import {useDispatch} from "react-redux"
const SidebarLink = ({ link, iconName })  =>{
    const Icon = Icons[iconName]
    const location = useLocation()
    // const dispatch = useDispatch()
  
    const matchRoute = (route) => {
      return matchPath({ path: route }, location.pathname)
    }
  
    function nothing(){
        
    }
    return (
      <NavLink
        to={link.path}
        onClick={() => nothing()}
        className={`relative px-8 py-3 text-lg font-medium hover:bg-grey-50 rounded-md ${
          matchRoute(link.path)
            ? "bg-green-5 text-white"
            : "bg-opacity-0 text-grey-5"
        } transition-all duration-200`}
      >
        <span
          className={`absolute left-0 top-0 h-full w-[0.15rem] bg-yellow-50 ${
            matchRoute(link.path) ? "opacity-100" : "opacity-0"
          }`}
        ></span>
        <div className="flex items-center gap-x-2">
          {/* Icon Goes Here */}
          <Icon className="text-lg" />
          <span>{link.name}</span>
        </div>
      </NavLink>
    )
  }

export default SidebarLink