import toast from "react-hot-toast"
import { apiConnector } from "../apiConnector";
import { purchaseOrderEndpoints } from "../apis";


const {
    CREAT_PURCHASE_ORDER_API,
    CREATE_ORDER_FROM_REQUEST_API,
    GET_ALL_PURCHASE_ORDERS_API,
    UPDATE_PURCHASE_ORDER_STATUS,
    DELETE_PURCHASE_ORDER_API,
    UPDATE_PURCHASE_ORDER_API,
    CLONE_PURCHASE_ORDER_API
} = purchaseOrderEndpoints



export const createPO = async(data , navigate) => {
const toastId = toast.loading("Loading...");
try{
    const response = await apiConnector("POST" , CREAT_PURCHASE_ORDER_API , data );

    if(!response.data.success) {
        throw new Error("Cannot create Purchase Order");
    }

    console.log("CREAT_PURCHASE_ORDER_API_REPSONSE - " , response);
     toast.success("Order created Successfully");
     navigate("/admin/purchase-orders");
}catch(error){
    console.log("CREAT_PURCHASE_ORDER_API_REPSONSE - " , error);
    toast.error("Cannot create Order")
}
toast.dismiss(toastId);
}


export const createPOFromPR = async(id , navigate) => {
    const toastId = toast.loading("Loading...");
    const FINAL_URL = CREATE_ORDER_FROM_REQUEST_API + `/${id}`
    try{
        const response = await apiConnector("POST" , FINAL_URL);
    
        if(!response.data.success) {
            throw new Error("Cannot create Purchase Order");
        }
    
        console.log("CREATE_ORDER_FROM_REQUEST_API_REPSONSE - " , response);
         toast.success("Order created Successfully");
         navigate("/admin/purchase-orders");
    }catch(error){
        console.log("CREATE_ORDER_FROM_REQUEST_API_REPSONSE - " , error);
        toast.error("Cannot create Order")
    }
    toast.dismiss(toastId);
    }


export const getPurchaseOrders = async() => {
    let result = [];
    try{
             const response = await apiConnector("GET" , GET_ALL_PURCHASE_ORDERS_API);

             if(!response.data.success) {
                throw new Error ("Cannot get Puchase Orders")
             }

            //  console.log("GET_ALL_PURCHASE_ORDERS_API_RESPONSE - " , response);
             result = response?.data?.purchaseOrders
    }catch(error){
        console.log("GET_ALL_PURCHASE_ORDERS_API_RESPONSE - " , error);
        toast.error("Cannot get Purchase Orders");
    }
    return result;
}

export const getPurchaseOrdersByUser = async(userId) => {
    let result = [];
    const GET_ALL_PURCHASE_ORDERS_BY_USER_API = GET_ALL_PURCHASE_ORDERS_API + `/${userId}`
    try{
             const response = await apiConnector("GET" , GET_ALL_PURCHASE_ORDERS_BY_USER_API);

             if(!response.data.success) {
                throw new Error ("Cannot get Puchase Orders")
             }

             console.log("GET_ALL_PURCHASE_ORDERS_API_RESPONSE - " , response);
             result = response?.data?.purchaseOrders
    }catch(error){
        console.log("GET_ALL_PURCHASE_ORDERS_API_RESPONSE - " , error);
        toast.error("Cannot get Purchase Orders");
    }
    return result;
}


export async function changePurchaseOrderStatus(status , id , navigate){
    const toastId = toast.loading("Loading...");
    const UPDATE_API = UPDATE_PURCHASE_ORDER_STATUS +"/"+ id
  //   console.log(UPDATE_API)
    let result= [];
  try{
    const response = await apiConnector("PUT" , UPDATE_API ,  {status} );

    if(!response.data.success){
           throw new Error ("Cannot Update Document")
    }

    console.log("CHANEG_STATUS_API RESPONSE - " , response);
    toast.success("Document Updated")
    result = response.data;
  //   navigate("/admin/purchase-requests")
  }catch(error){
      console.log("CHANEG_STATUS_API RESPONSE - ", error);
      toast.error("Unable to Update Document")
  }
   toast.dismiss(toastId);
   return result;
}


export async function deletePurchaseOrder(id){
    const toastId = toast.loading("Loading...");
    const DELETE_API = DELETE_PURCHASE_ORDER_API +"/"+ id
    // console.log(DELETE_API)
    let result =[];
  try{
    const response = await apiConnector("DELETE" , DELETE_API  );

    if(!response.data.success){
           throw new Error ("Cannot Delete Document")
    }

    console.log("DELETE_API RESPONSE - " , response);
    toast.success("Document deleted")
    result = response.data;
  }catch(error){
      console.log("DELETE_API RESPONSE - ", error);
      toast.error("Unable to Delete Document")
  }
   toast.dismiss(toastId);
  return result;
}

export async function updatePurchaseOrder(id , data){
    const toastId = toast.loading("Loading...");
    const UPDATE_API = UPDATE_PURCHASE_ORDER_API +"/"+ id
    // console.log(DELETE_API)
    let result =[];
  try{
    const response = await apiConnector("PUT" , UPDATE_API , data );

    if(!response.data.success){
           throw new Error ("Cannot update document")
    }

    console.log("UPDATE_API RESPONSE - " , response);
    toast.success("Document updated")
    result = response.data.data;
  }catch(error){
      console.log("Update_API RESPONSE - ", error);
      toast.error("Unable to update document")
  }
  toast.dismiss(toastId);
  return result;
}

export async function clonePurchaseOrder(id){
    const toastId = toast.loading("Loading...")
    const CLONE_API = CLONE_PURCHASE_ORDER_API + `/clone/${id}`;
    let result = [];
  try{
     const response = await apiConnector("POST", CLONE_API );
       
     if(!response.data.success){
      throw new Error("Cannot clone Request");
     }
  
     console.log("CLONE_PURCHASE_ORDER_API_RESPONSE - " , response);
     toast.success("Cloned successfully");
     result=response.data;
  }catch(error){
    console.log("CLONE_PURCHASE_ORDER_API_RESPONSE - " , error);  
    toast.error("Cannot clone Request");  
  }
  toast.dismiss(toastId)
  return result;
  }