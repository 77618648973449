import React from 'react'
import { Link } from 'react-router-dom'

export const OverDue = ({overdues, linkedto}) => {
  return (
    <Link to={linkedto}>
     <div className=" px-5 py-[11px] bg bg-white rounded-lg flex items-center justify-center gap-x-3 shadow-lg">
      <div className=" h-10 w-10 rounded-full flex justify-center items-center bg-grey-25">
        <p className="text-black text-lg">{overdues}</p>
      </div>
      <p className="text-lg ">OverDue</p>
    </div>
  </Link>
  )
}
