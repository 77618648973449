import {createSlice} from "@reduxjs/toolkit"


const initialState = {
   totalPurchases:[],
   purchasePages:[],
   tableData:[],
   filters:[],
}

const purchasesSlice = createSlice({
    name:"purchases",
    initialState:initialState,
    reducers: {
        setTotalPurchases(state , value){
            state.totalPurchases = value.payload;
        },
        setPurchasePages(state ,value){
          state.purchasePages = value.payload;
        },
        setTableData(state , value) {
            state.tableData = value.payload;
        },
        setFilters(state , value) {
            state.filters = value.payload;
        },
    },
});


export const {setTotalPurchases , setPurchasePages ,setTableData ,setFilters} =purchasesSlice.actions;
export default purchasesSlice.reducer;