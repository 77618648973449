import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../../../services/operations/userAPI';



const SignupForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

      const dispatch = useDispatch();
      const navigate = useNavigate();
    const [showPassword , setShowPassword] = useState(false)

    const submitLogin = async(data)=> {
        // console.log(data)
        const username = data.firstName + " " + data.lastName;
        console.log("username in signupform",username)
        const formdata = {
            name:username,
            email:data.email,
            phone:data.phone,
            organisation:data.organisation,
            password:data.password
        }
        dispatch(signUp(formdata, navigate))

    }
  return (
    <>
    <form onSubmit={handleSubmit(submitLogin)} className='w-full mt-10   ' >

         {/* firstName and lastname  */}
        <div className='w-full  flex items-center gap-x-5'>
         <label  className='w-full relative text-lg font-semibold text-black flex flex-col gap-y-3 '>
                <p>First Name <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type='text'
            id='firstName'
            name='firstName'
            placeholder="Enter Your First Name"
            className='w-[100%] rounded-lg h-[48px]  bg-white text-black outline  outline-[1px]  px-2 py-1 font-normal text-sm'
            {...register("firstName" , {
                required: {
                    value:true,
                    message:"Please Enter First Name"
                }
            })}
            />
            {errors.firstName && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.firstName.message}</span>
            )}

        </label>
 
        <label  className='w-full relative text-lg font-semibold text-black flex flex-col gap-y-3 '>
               <p>Last Name <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type='text'
            id='lastName'
            name='lastName'
            placeholder="Enter Your Last Name"
            className='w-[100%] rounded-lg h-[48px]  bg-white text-black outline outline-[1px]  px-2 py-1 font-normal text-sm appearance-none'
            {...register("lastName" , {
                required: {
                    value:true,
                    message:"Please Enter Last Name"
                }
            })}
            />
            {errors.lastName && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.lastName.message}</span>
            )}

        </label>
        
        </div>

         {/* email  */}
        <label  className='w-full mt-5 relative text-lg font-semibold text-black flex flex-col gap-y-3 '>
           <p>Email Address <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type='email'
            id='email'
            name='email'
            placeholder="Enter Your Email"
            className='w-[100%] rounded-lg h-[48px]  bg-white text-black outline outline-[1px]  px-2 py-1 font-normal text-sm appearance-none'
            {...register("email" ,{
                required:{value:true , message:"Please Enter Email"},
                pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
            } 
            )}
            />
            {errors.email && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.email.message}</span>
            )}

        </label>

          {/* phone no and organisation  */}
       <div className='w-full  flex items-center gap-x-5'>
        {/* phone no   */}
        <div className='flex flex-col w-full'>
             <label htmlFor='phone' className='w-full mt-5 relative text-lg font-semibold text-black flex flex-col gap-y-3 ' >
              <p>Phone Number <sup className='text-green-5 text-lg '>*</sup></p>
              <input
                         type='number'
                         name='phone'
                         id='phone'
                         placeholder='12345 6789'
                         
                         className='w-[100%] rounded-lg h-[48px]  bg-white text-black outline outline-[1px]  px-2 py-1 font-normal text-sm appearance-none'
                         {...register("phone" , 
                         {
                          required:{value:true , message:"Please Enter Phone Number"},
                          maxLength:{value:"10" , message:"Enter Valid Number"},
                          minLength:{value:"8", message:"Minimum 8 digits are required"}
                         })}
                      />
                      {
                           errors.phone && (
                            <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>
                           {errors.phone.message}
                          </span>
                                 )
                                  }
             </label>
            
        </div>

        <label  className=' mt-5 w-full relative text-lg font-semibold text-black flex flex-col gap-y-3 '>
           <p>Organistaion Name <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type='name'
            id='organisation'
            name='organisation'
            placeholder="Enter Your Organisation Name"
            className='w-[100%] rounded-lg h-[48px]  bg-white text-black outline outline-[1px]  px-2 py-1 font-normal text-sm appearance-none'
            {...register("organisation" , {
                required: {
                    value:true,
                    message:"Please Enter Organisation Name"
                }
            })}
            />
            {errors.organisation && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.organisation.message}</span>
            )}

        </label>
       </div>

         {/* password  */}
        <label  className='w-full relative text-lg font-semibold text-black flex flex-col gap-y-3 mt-5'>
           <p>Password <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type={`${showPassword === true ? "text" : "password"}`}
            id='password'
            name='password'
            className='w-[100%] rounded-lg h-[48px]  bg-white text-black outline outline-[1px]  px-2 py-1 font-normal text-sm appearance-none'
            {...register("password" , {
                required: {
                    value:true,
                    message:"Please Enter Your Password"
                },
                minLength:{value:6, message:"Minimum 6 characters are required"}
            })}
            />
            {errors.password && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.password.message}</span>
            )}
          {/* eyeicon  */}
          <span
           className='absolute top-[60%]  right-2 cursor-pointer text-grey-50 '
           onClick={() => {setShowPassword( (prev) => !prev)}}>
            {
                showPassword ? ( <IoIosEye className='text-2xl' />) : ( <IoIosEyeOff className='text-2xl' />)
            }
          </span>

        </label>

          {/* terms and conditions  */}
            <label className='w-full relative  flex items-center gap-x-1 flex-row-reverse justify-end mt-5'>
            <p className='text-black'>By continuing, you agree to our <span className='text-green-5 cursor-pointer'>terms of services.</span></p>
           <input type="checkbox" 
           id="agreed"
           name="agreed" 
           value="agreed"
           {...register("agreed" , {required:true})}
           className='accent-green-5 checked:'/>
           {
            errors.agreed && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>Please , must check to terms & conditions</span>
            )}
            </label>

        <button type='submit' className='w-full bg-green-5 text-white text-center rounded-lg  py-4 mt-8'>
            Signup
        </button>
         
    </form>
    </>
  )
}

export default SignupForm