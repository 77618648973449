import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const UpdateInvoice = () => {

    ////////////////////////////Dummy Invoices//////////////
  const invoices = [
    {
      _id: "65be096b12c161c4cb553063",
      createdAt: "2024-02-03T09:37:47.257Z",
      deliveryDate: "2024-02-29",
      department: "Marketing",
      items: [
        {
          _id: "65be095212c161c4cb553060",
          category: "Electronics",
          createdAt: "2024-02-03T09:37:22.940Z",
          description: "",
          name: "TV",
          price: "10000",
          quantity: "1",
          sku: "TVElHe1-10000",
          supplier: "Head Quaters",
          updatedAt: "2024-02-03T09:37:22.940Z",
          user: "65a7980a6cd70ff033b955ec",
        },
      ],
      location: "Back Office",
      note: "Need a TV for lobby",
      references: "amazon.in",
      status: "Draft",
      updatedAt: "2024-02-03T09:37:47.257Z",
      user: "65a7980a6cd70ff033b955ec",
    },
  ];
  /////////////////////////////////////////////////////////

    const {invoiceId}  =  useParams();
    console.log("Invoice ID -  ", invoiceId);
    const[invoicesData , setInvoicesData] = useState([]);
    const[loading , setLoading] = useState(false);

    useEffect(() => {
              setInvoicesData(invoices);
    } ,[])

  return (
    <div className='w-[calc(100vw-280px)] min-h-screen py-10'>
       {
        !loading ? (
            <div >
                 {
                    invoicesData.length === 0 ? (<p>No Data Found</p>) : (
                      <div className='w-[95%] mx-auto flex items-center justify-between'>
                             <div className='w-[65%] rounded-lg bg-white p-5 '>
                                  {/* r-1  */}
                                  <div className='flex items-center justify-between my-3 '>

                                       <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Status</p>
                                          <p className={` p-1 px-5 text-sm rounded-2xl ${invoicesData[0].status === "Draft" ? "bg-blue-5 bg-opacity-40 text-blue-5" : "bg-orange-5 bg-opacity-40 text-orange-5"}`}>{invoicesData[0].status}</p>
                                        </div>

                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Creation Date</p>
                                          <p className="text-sm">{invoicesData[0].createdAt.split("T").at("0")}</p>
                                        </div>

                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Purchase Order</p>
                                          <p className="text-sm">{invoicesData[0]._id}</p>
                                        </div>

                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Issue Date</p>
                                          <p className="text-sm">{invoicesData[0].createdAt.split("T").at("0")}</p>
                                        </div>

                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Due Date</p>
                                          <p className="text-sm">{invoicesData[0].createdAt.split("T").at("0")}</p>
                                        </div>
                                    </div>

                                     {/* r-2  */}
                                    <div className='my-8'>
                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Issuer</p>
                                          <p className="text-sm">{invoicesData[0].user}</p>
                                        </div>
                                    </div>

                                     {/* r-3  */}
                                     <div className='my-8'>
                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2'>Supplier</p>
                                          <p className="text-sm">{invoicesData[0].items[0].supplier}</p>
                                        </div>
                                    </div>

                                    
                                     {/* r-3  */}
                                     <div className='my-8'>
                                        <div className='text-black text-opacity-60'>
                                          <p className='text-lg mb-2 font-semibold text-opacity-80 text-black'>Due Payment</p>
                                          <p className="text-normal text-black text-opacity-70 font-semibold">{invoicesData[0].items[0].price}.00 <sup>INR</sup></p>
                                        </div>
                                    </div>
                                 
                             </div>

                             <div className='flex flex-col justify-center w-[30%] gap-3'>
                                <button className='w-[90%] rounded-2xl shadow-lg bg-white text-black font-semibold hover:bg-green-5 hover:text-white py-2 text-opacity-80'>Confirm</button>
                                <button className='w-[90%] rounded-2xl py-2 text-opacity-80 shadow-lg bg-white text-black font-semibold hover:bg-green-5 hover:text-white'>Edit Invoice</button>
                                <button className='w-[90%] rounded-2xl py-2 text-opacity-80 shadow-lg bg-white text-red-25 font-semibold hover:bg-red-25 hover:text-white'>Delete</button>
                             </div>
                      </div>
                    
                    )
                 }     
            </div>
        ) : (<p>Loading.....</p>)
       }
    </div>
  )
}
