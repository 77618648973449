
import React from "react";
import Create from "../admin_common/Create";
import { Approve } from "../admin_common/Approve";
import { SendToVendor } from "../admin_common/SendToVendor";
import { GetPurchaseOrders } from "./GetPurchaseOrders/GetPurchaseOrders";


export default function PurchaseRequests() {
 
  return (
    <div className=" w-[calc(100vw-280px)] min-h-screen bg-grey-25 py-10">
      <div className="w-[95%] mx-auto flex flex-col items-center gap-x-4  ">
        <div className="flex items-center justify-start w-full gap-x-5">
        <Approve
            approved={2}
            linkedto={"/admin/purchase-orders/update-purchase-orders/approved-purchase-order"}
          />
            <SendToVendor
            sendtovendors={4}
            linkedto={"/admin/purchase-orders/update-purchase-orders/approved-purchase-order"}
          />
         <Create
            linkto={"/admin/purchase-orders/create-purchase-order"}
          />
        </div>
           
           {/* table  */}
        <div className="w-full mt-10">
        <GetPurchaseOrders />
         {/* <GetPurchaseCopy /> */}
        </div>
      </div>
    </div>
  );
}

