import React from 'react'
import ReportLinks from "../../../../data/reportsPageLinks.json"
import { Link, NavLink } from 'react-router-dom'
import { AiOutlineFileAdd } from "react-icons/ai";
export const Reports = () => {
  return (
    <div className="w-[calc(100vw-280px)] min-h-screen flex flex-col gap-y-10 py-10">
         <div className=' grid grid-cols-3 gap-6 flex-wrap px-4 pb-8 boder-b border-b-black my-4'>
             {
                ReportLinks.map((link , index) => (
                    <div key={index}  className='bg-white p-3 rounded-xl shadow-lg border cursor-pointer border-grey-100 py-2 px-5 min-h-[200px] aspect-video'>
                        <Link to={link?.linkto}>
                            <div className='flex flex-col justify-between'>
                                <p className='my-4 font-semibold text-black text-opacity-90 text-2xl'>{link?.name}</p>

                                <div className='text-black text-opacity-65'>
                                    <p className='text-sm font-semibold'>Created by {link?.createdBy}</p>
                                    <p className='text-normal font-semibold'>Last Updated {link?.lastUpdated}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))
             }
         </div>

        <NavLink to={"/admin/reports/show-report"}>
        <div className='rounded-xl border-grey-50 border p-4 h-[180px] max-w-[350px] flex flex-col justify-between ml-3 bg-green-50'>
            <p className='my-4 font-semibold text-black text-opacity-90 text-2xl'> Create a Custom Report</p>
            <p className='flex justify-end pr-3'><AiOutlineFileAdd className='text-6xl text-right font-extralight' /></p>
         </div>
        </NavLink>
    </div>
  )
}
