import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { EmailModal } from "./EmailModal";
import { ImportModal } from "./ImportModal";
import { getAllUsers } from "../../../../../services/operations/userAPI";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useForm } from "react-hook-form";

export const UserManagement = () => {
  ///////////////Dummy Data//////////////////
  const DummyData = [
    {
      name: "Jacob Jones",
      location: "Backoffice",
      roles: "Branch Manager",
      status: "Active",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [searchQueryParam, setSearchQueryParam] = useState('');
  

  const getAllUser = async () => {
    const response = await getAllUsers();
    //  convert into chunk
    const chunkedArray = chunkArray(response, 5);
    setTotalPage(chunkedArray.length);
    setStartIndex(pageNo * 5);
    setValue("number" , pageNo+1)
    // console.log(chunkedArray, pageNo, totalPage);
    setTableData(chunkedArray[pageNo]);
  };

  // Function to chunk the array into arrays of size n
  function chunkArray(array, size) {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    // console.log("chunkedArray", chunkedArr);
    return chunkedArr;
  }

  useEffect(() => {
    setLoading(true);
    getAllUser();
    setLoading(false);
  }, [userAdded, pageNo, totalPage]);

  const prevPage = () => {
    if (pageNo !== 0) {
      setPageNo(pageNo - 1);
    } else {
      // console.log("m");
      return;
    }
  };

  const nextPage = () => {
    if (pageNo < totalPage - 1) {
      setPageNo(pageNo + 1);
    } else {
      // console.log("o");
      return;
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchPage();
    }
  };

  const searchPage = () => {
    let desiredPage = Number(searchQueryParam);
    if (desiredPage <= totalPage && desiredPage > 0) {
      setPageNo(desiredPage - 1);
      // console.log("updated" , page)
    } else {
      //  console.log(desiredPage)
    }
  };

  const {
    register,
    setValue
  } = useForm();

  

  return (
    <div className="w-[75%] h-fit ">
      <div className="flex items-center gap-x-5 justify-end px-5">
        <div className="relative w-[30%] flex justify-end">
          <button
            className="font-semibold text-black text-lg flex items-center gap-x-1 "
            onClick={() => setShowEmailModal((prev) => !prev)}
          >
            <AiOutlinePlusCircle />
            Invite User
          </button>

          <EmailModal
            showEmailModal={showEmailModal}
            loading={loading}
            setUserAdded={setUserAdded}
          />
        </div>

        <div className="relative w-[30%] flex justify-center ">
          <button
            className="font-semibold text-black text-lg flex items-center gap-x-1 "
            onClick={() => setShowImportModal((prev) => !prev)}
          >
            <AiOutlinePlusCircle />
            Import Users
          </button>

          <ImportModal
            showImportModal={showImportModal}
            loading={loading}
            setUserAdded={setUserAdded}
          />
        </div>
      </div>

      <div className="w-full overflow-hidden rounded-t-lg z-0 my-5 min-h-[310px] ">
        {loading ? (
          <div className="w-full font-semibold text-black text-opacity-60 text-lg text-center py-8 flex justify-center items-center ">
            Loading...
          </div>
        ) : tableData.length === 0 ? (
          <div className="w-full font-semibold text-black text-opacity-60 text-lg text-center my-16 min-h-[200px] bg-grey-25 bg-opacity-30 rounded-md  flex justify-center items-center">
            <p>No Data Found</p>
          </div>
        ) : (
            <>
          <table className="w-full shadow-lg pb-3 ">
            <thead className="h-[50px] bg-grey-100 bg-opacity-30 text-black text-opacity-60 font-semibold font-inter  ">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Roles</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((user, index) => (
                <tr
                  key={index}
                  className="bg-white h-[50px] font-inter text-black text-opacity-60 text-center"
                >
                  <td>{`${
                    startIndex + index < 9
                      ? `0${startIndex + index + 1}`
                      : `${startIndex + index + 1}`
                  }`}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="capitalize ">{user.role}</td>
                  <td>
                    <p
                      className={`py-1 text-sm rounded-2xl capitalize ${
                        user.userStatus === "active"
                          ? "text-green-5 bg-green-5 bg-opacity-20"
                          : "text-orange-5 bg-orange-5 bg-opacity-20 "
                      }`}
                    >
                      {user.userStatus.split("_").join(" ")}
                    </p>
                  </td>
                  <td>
                    <Link className="w-full flex justify-center hover:text-opacity-100 hover:text-black">
                      <FaRegEdit />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="w-full overflow-hidden rounded-lg z-0 my-7 flex justify-center ">
        <button
          className=" p-4 rounded-md shadow-md text-grey-50 bg-grey-100 bg-opacity-30 hover:text-black"
          onClick={prevPage}
        >
          {" "}
          <MdKeyboardArrowLeft />
        </button>
        <div className="flex items-center mx-4">
        <input type="number" id="number" name="number" {...register("number" ,{})} className="appearance-none bg-white w-[35px] remove-arrow outline-none border-none border-transparent focus:border-transparent focus:ring-0" onChange={(event) => setSearchQueryParam(event.target.value)} onKeyDown={handleKeyPress}/>
          {/* <p>{pageNo + 1}</p> */}
          <p>of</p>
          <p className="ml-3">{totalPage}</p>
        </div>
        <button
          className=" p-4 rounded-md shadow-md text-grey-50 bg-grey-100 bg-opacity-30 hover:text-black"
          onClick={nextPage}
        >
          {" "}
          <MdKeyboardArrowRight />
        </button>
      </div>
          </>
        )}
      </div>

    </div>
  );
};
