
import Sidebar from '../components/core/Dashboard/Sidebar'
import { Outlet } from 'react-router-dom'

const Admin = () => {
  return (
    <div className="relative flex min-h-[calc(100vh-6.5rem)] h-full">
    <Sidebar />
    <div className="min-h-[calc(100vh-6.5rem)] h-full flex-1 overflow-auto">
      <div className="mx-auto w-11/12 max-w-[1000px] py-10">
        <Outlet />
      </div>
    </div>
  </div>
  )
}

export default Admin