import React, { useEffect, useState } from "react";
import { getPurchases } from "../../../../../services/operations/purchaseAPI";
import { LuIndianRupee } from "react-icons/lu";
import { Link } from "react-router-dom";
import { MdError } from "react-icons/md";
import { FilterData } from "./FilterData";
import { Pagination } from "../../admin_common/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setPurchasePages,
  setTableData,
  setTotalPurchases,
} from "../../../../../slices/purchasesSlice";
import { setPage, setTotalPages } from "../../../../../slices/paginationSlice";

export const GetPurchase = () => {
  const { totalPurchases, purchasePages, tableData, filters } = useSelector(
    (state) => state.purchases
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { page, totalPages } = useSelector((state) => state.pagination);
  const [startIndex, setStartIndex] = useState(0);
  // console.log(tableData) ;

  // whenever filters array changes update the data
  useEffect(() => {
    setLoading(true);
    //  getAllPurchases();
    getFilteredPurchases();
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    setStartIndex(page * 5);
  }, [page]);

  const getFilteredPurchases = async () => {
    setLoading(true);
    const tempData = await getPurchases();
    // update  the state
    dispatch(setTotalPurchases(tempData));
    // console.log("Total Purchases - ", totalPurchases);

    if (filters.length === 0) {
      // console.log("Inside first case")
      const chunkedArray = chunkArray(tempData, 5);
      dispatch(setPurchasePages(chunkedArray));
      // console.log("purchasePages - " , purchasePages);

      dispatch(setPage(0));
      dispatch(setTotalPages(chunkedArray.length));
      // console.log(page , totalPages);
      setLoading(false);
      return;
    }

    // apply filters on the total puchases
    const filteredPurchases = applyMultipleFilters(totalPurchases, filters);
    //  console.log("Filterd Purchases - ", filteredPurchases);

    // convert them in page format
    const chunkedArray = chunkArray(filteredPurchases, 5);
    dispatch(setPurchasePages(chunkedArray));
    //  console.log("purchasePages - " , purchasePages);

    //  update tableData  and update page no and total pages
    dispatch(setPage(0));
    dispatch(setTotalPages(chunkedArray.length));
    //  console.log(page , totalPages);
    setLoading(false);
  };

  const applyMultipleFilters = (data, filtersArray) => {
    if (filtersArray.length === 0) {
      return data; // No filters, return original data
    }

    // Filter based on each filter independently using logical OR (||)
    const filteredData = data.filter((item) => {
      return filtersArray.some((filter) => {
        switch (filter.type) {
          case "status":
            return item.status === filter.value;
          case "location":
            return item.location === filter.value;
          case "department":
            return item.department === filter.value;
          case "deliveryDate":
            const deliveryDate = new Date(item.deliveryDate);
            const value1 = new Date(filter.value.value1);
            const value2 = new Date(filter.value.value2);
            return deliveryDate >= value1 && deliveryDate <= value2;
             // console.log(deliveryDate , value1 , value2 , index , deliveryDate >= value1 && deliveryDate <= value2 );
          // Add more cases for additional filter types if needed
          default:
            return false; // Default to false for unknown filter type
        }
      });
    });

    // If there is only one type of filter, return filtered data directly
    if (filtersArray.every((filter) => filter.type === filters[0].type)) {
      return filteredData;
    }

    // Filter the results that satisfy both conditions using logical AND (&&)
    const finalFilteredData = filteredData.filter((item) => {
      return filtersArray.every((filter) => {
        switch (filter.type) {
          case "status":
            return item.status === filter.value;
          case "location":
            return item.location === filter.value;
          case "department":
            return item.department === filter.value;
          case "deliveryDate":
            const deliveryDate = new Date(item.deliveryDate);
            const value1 = new Date(filter.value.value1);
            const value2 = new Date(filter.value.value2);
            return deliveryDate >= value1 && deliveryDate <= value2;
          // Add more cases for additional filter types if needed
          default:
            return false; // Default to false for unknown filter type
        }
      });
    });

    return finalFilteredData;
  };

  // Function to chunk the array into arrays of size n
  function chunkArray(array, size) {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    // console.log("chunkedArray", chunkedArr);
    return chunkedArr;
  }

  return (
    <div>
      <div>
        {loading === true ? (
          <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75">
            Loading...
          </p>
        ) : (
          <>
            <FilterData />
            {purchasePages.length === 0 ? (
              <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75 gap-x-2 mt-10">
                <MdError className="text-2xl  text-opacity-50" />
                No Purchase Requests Found
              </p>
            ) : (
              <>
                <div>
                  {/* //filter component  */}
                  {/* table  */}
                  <div className="w-full min-h-[320px] rounded-lg overflow-hidden mt-10">
                    <table className="w-full rounded-lg">
                      <thead className="text-grey-50 text-opacity-85 bg-grey-75  shadow-lg h-[35px] py-4 text-center">
                        <tr className=" font-[200]">
                          <th>No.</th>
                          <th>Item Name</th>
                          <th>Requester</th>
                          <th>Quantity</th>
                          <th>Status</th>
                          <th>Created On</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody className="bg-grey-5 text-center">
                        {purchasePages[page].map((item, index) => (
                          <tr
                            key={index}
                            className={`h-[55px] font-inter font-extralight text-opacity-75 text-black ${item.status === "Rejected" ? " cursor-not-allowed text-opacity-40":""}`}
                          >
                            <td>{`${
                              startIndex + index < 9
                                ? `0${startIndex + index + 1}`
                                : `${startIndex + index + 1}`
                            }`}</td>
                            <td>{item?.items[0]?.name}</td>
                            <td className={`group relative ${item.status === "Rejected" ? "cursor-not-allowed  " : " cursor-pointer"}`}>
                              {item?.user?.name}
                              <p className="hidden absolute top-4 bg-grey-25 py-2 rounded-md  transition-all duration-200 text-sm w-full left-0 group-hover:block ">
                                {item?.user?.email}
                              </p>
                            </td>
                            <td>{`${
                              item?.items[0]?.quantity < 10
                                ? `0${item?.items[0]?.quantity}`
                                : `${item?.items[0]?.quantity}`
                            }`}</td>
                            <td>
                              <Link
                              className={`${item.status === "Rejected" ? "cursor-not-allowed " : ""}`}
                                to={`${
                                  item?.status === "Draft"
                                    ? `/admin/purchase-requests/update-purchase-requests/draft-purchase-request/${item._id}`
                                    : item?.status === "Pending"
                                    ? `/admin/purchase-requests/update-purchase-requests/pending-purchase-request/${item._id}`
                                    : item?.status === "Completed" ?
                                    `/admin/purchase-requests/update-purchase-requests/completed-purchase-request/${item._id}` : ""
                                }`}
                              >
                                <p
                                  className={` rounded-2xl text-sm py-1 w-[80%] mx-auto  flex items-center justify-center ${
                                    item?.status === "Draft"
                                      ? "bg-blue-5 bg-opacity-30 text-blue-5"
                                      : item.status === "Pending"
                                      ? " bg-orange-5 bg-opacity-30 text-orange-5"
                                      : item.status === "Completed" ?  "bg-blue-5 bg-opacity-30 text-blue-5" :
                                      item.status === "Rejected" ?  "bg-red-25 bg-opacity-30 text-red-25" : ""
                                  }`}
                                >
                                  {item?.status}
                                </p>
                              </Link>
                            </td>
                            <td>{item?.createdAt.split("T").at("0")}</td>
                            <td>
                              <p className="flex items-center gap-x-1 justify-center">
                                <LuIndianRupee />
                                {item?.items[0]?.price *
                                  item?.items[0]?.quantity}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
