import { useState } from "react";
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdVerified } from "react-icons/md";
import { useDispatch } from "react-redux";
import { getResetPasswordToken } from "../services/operations/userAPI";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [sentEmail, setSentEmail] = useState(null);
  function submitLogin(data) {
         dispatch(getResetPasswordToken(data.email , setSentEmail))
  }
  return (
    <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center bg-grey-25  z-20">
      {!sentEmail &&(
        <div className="min-h-[70%] min-w-[40%] flex flex-col gap-y-6 px-6 py-8 rounded-2xl bg-white shadow-md">
          {/* heading  */}
          <Link to={"/"}>
          <div className='w-full flex justify-center items-center'>
                <div className=' flex  items-center mt-1 relative'>
                <FaCaretLeft className='text-4xl  text-green-5 font-semibold absolute top-0 right-5'/>
                <FaCaretRight className='text-4xl text-green-5 '/>
                </div>
                <p className='text-4xl font-extrabold text-green-5 tracking-[0.1em] '>Procura<span className='font-extralight'>Flow</span></p>
            </div>
          </Link>

          <div className="w-full text-center mt-6">
            <p className="text-3xl font-semibold text-black">
              Forgot Password ?
            </p>
            <p className="text-grey-50 text-lg">
              Enter your email address to get the
              <br /> password reset link
            </p>
          </div>

          <form onSubmit={handleSubmit(submitLogin)} className="w-full mt-7 ">
            <label className=" w-full relative text-lg font-semibold text-black flex flex-col gap-y-3 ">
              Email Address
              <input
                type="email"
                id="email"
                name="email"
                placeholder="hello@example.com"
                className="w-[100%] rounded-lg h-[48px]  bg-white text-black outline outline-[1px] px-2 py-1 font-normal text-sm"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please Enter Your Email",
                  },
                })}
              />
              {errors.email && (
                <span className="absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-sm px-2 py-1 rounded-md">
                  {errors.email.message}
                </span>
              )}
            </label>

            <button
              type="submit"
              className="w-full bg-green-5 text-white text-center rounded-lg  py-4 mt-8"
            >
              Reset Password
            </button>
          </form>

          <Link to={"/login"}>
            <p className="text-lg text-grey-50  text-center my-2 font-semibold hover:text-green-5">
              {" "}
              Back to login{" "}
            </p>
          </Link>
        </div>
      )}
      {sentEmail !== null && (
        <div className="min-h-[70%] min-w-[40%] flex flex-col justify-center items-center gap-y-6 px-6 py-8 rounded-2xl bg-white shadow-md">
            {/* heading  */}
            <Link to={"/"}>
          <div className='w-full flex justify-center items-center'>
                <div className=' flex  items-center mt-1 relative'>
                <FaCaretLeft className='text-4xl  text-green-5 font-semibold absolute top-0 right-5'/>
                <FaCaretRight className='text-4xl text-green-5 '/>
                </div>
                <p className='text-4xl font-extrabold text-green-5 tracking-[0.1em] '>Procura<span className='font-extralight'>Flow</span></p>
            </div>
          </Link>
          
          <h2 className="flex items-center gap-x-3 text-3xl text-black font-semibold  ">
            <MdVerified className="text-green-5 mt-1 font-inter" />
            Reset Email Sent Successfully
          </h2>
          <p className="text-lg text-black font-inter">
            Please check your Email to Reset password
          </p>

          <Link to={"/login"}>
            <p className="text-lg text-grey-50  text-center my-2 font-semibold hover:text-green-5">
              {" "}
              Back to login{" "}
            </p>
          </Link>
        </div>
      )}
      
    </div>
  );
};

export default ForgotPassword;
