import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPurchases } from "../../../../../services/operations/purchaseAPI";

export const UpdateQuotationModal = ({ updateModalData }) => {
  let { status } = useParams();
  status =
    status.split("-").at("0").charAt(0).toUpperCase() +
    status.split("-").at("0").slice(1);
  return (
    <div className="min-h-[50vh] w-full flex items-start gap-x-10 ">
      <div className="w-[60%] min-h-[45vh] bg-white rounded-lg p-8 shadow-lg">
        <div className=" w-[90%] mx-auto  flex items-center justify-between">
          <div>
            <p className="text-black font-inter text-opacity-60 ml-1 ">
              Status
            </p>
            <p
              className={` rounded-2xl  py-0.5 flex items-center justify-center px-3 mt-1 text-sm ${
                updateModalData?.status === "Draft"
                  ? "bg-blue-5 bg-opacity-30 text-blue-5"
                  : updateModalData?.status === "Pending"
                  ? " bg-orange-5 bg-opacity-30 text-orange-5"
                  : "bg-blue-5 bg-opacity-30 text-blue-5"
              }`}
            >
              {updateModalData?.status}
            </p>
          </div>

          <div>
            <p className="text-black font-inter text-opacity-60 ">
              Creation Date
            </p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.createdAt.split("T").at("0")}
            </p>
          </div>

          <div>
            <p className="text-black font-inter text-opacity-60 ">
              Delivery Date
            </p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.deliveryDate.split("T").at("0")}
            </p>
          </div>
        </div>

        <div className="w-[90%] mx-auto flex items-center justify-between mt-5">
          <div>
            <p className="text-black font-inter text-opacity-60 ">Requester</p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.user}
            </p>
          </div>

          <div>
            <p className="text-black font-inter text-opacity-60 ">Location</p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.location}
            </p>
          </div>
        </div>

        <div className=" w-[90%] mx-auto flex items-center justify-between mt-5">
          <div>
            <p className="text-black font-inter text-opacity-60 ">Total</p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.items[0]?.price *
                updateModalData?.items[0]?.quantity}
              .00 <sup>INR</sup>
            </p>
          </div>
        </div>
      </div>

      <div className="w-[30%]  h-[45vh]  ">
        {status === "Draft" && (
          <div className="h-full flex flex-col gap-y-5 justify-center">
            <button className="w-full py-1 rounded-2xl bg-grey-75 text-white hover:bg-green-10 font-inter shadow-md transition-all duration-200">
              Confirm
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-red-25 hover:bg-red-25 hover:text-white font-inter shadow-md">
              Delete
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Edit Request for Quotation
            </button>
          </div>
        )}

        {status === "Pending" && (
          <div className="h-full flex flex-col gap-y-5 justify-center">
            <button className="w-full py-1 rounded-2xl bg-white text-black hover:bg-green-10 font-inter shadow-md transition-all duration-200">
              Approve
            </button>

            <button className="w-full py-1 rounded-2xl   bg-red-25 text-white font-inter shadow-md">
              Reject
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Send for Revision
            </button>

            <button className="w-full py-1 rounded-2xl   bg-blue-55 text-white font-inter shadow-md">
              Revise
            </button>
            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Clone
            </button>
            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Edit Requisition
            </button>
          </div>
        )}

        {status ===  "Completed" && (
          <div className="h-full flex flex-col gap-y-5 justify-center">
            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Revise
            </button>

            <button className="w-full py-1 rounded-2xl   bg-blue-55 text-white font-inter shadow-md">
              Complete
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Resend to Supplier
            </button>

            <button className="w-full py-1 rounded-2xl text-red-25 bg-white   hover:bg-red-25 hover:text-white font-inter shadow-md">
              Cancel
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Edit Request for Quotation
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
