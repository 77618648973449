import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmationModal } from "../../../../common/ConfirmationModal";
import { EditRequesition } from "../../admin_common/EditRequesition";
import {
  changePurchaseOrderStatus,
  clonePurchaseOrder,
  deletePurchaseOrder,
  getPurchaseOrders,
  getPurchaseOrdersByUser,
} from "../../../../../services/operations/purchaseOrderAPI";
import { useSelector } from "react-redux";
import { EditPurchaseOrder } from "../EditPurchaseOrder/EditPurchaseOrder";

export const UpdatePurchaseOrderModal = ({
  updateModalData,
  setUpdateModalData,
  setFilteredData,
}) => {
  let { status } = useParams();
  status =
    status.split("-").at("0").charAt(0).toUpperCase() +
    status.split("-").at("0").slice(1);

  const [confirmationModal, setConfirmationModal] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const { user } = useSelector((state) => state.profile);

  const navigate = useNavigate();

  const updateStatus = async (updateStatus) => {
    if (status === "Draft") {
      const status = "Pending";
      const response = await changePurchaseOrderStatus(
        status,
        updateModalData._id,
        navigate
      );
      if (response) {
        updatePurchases();
      }
      setConfirmationModal(null);
    } else if (status === "Pending") {
      const status = "Approved";
      const response = await changePurchaseOrderStatus(
        status,
        updateModalData._id,
        navigate
      );
      if (response) {
        updatePurchases();
      }
      setConfirmationModal(null);
    } else if (updateStatus === "Reject") {
      const status = "Rejected";
      const response = await changePurchaseOrderStatus(
        status,
        updateModalData._id,
        navigate
      );
      if (response) {
        updatePurchases();
      }
      setConfirmationModal(null);
    }
  };

  const updatePurchases = async () => {
    let tempData = [];
    if (user.role === "admin") {
      tempData = await getPurchaseOrders();
    } else {
      tempData = await getPurchaseOrdersByUser(user._id);
    }
    let filteredPurchases = tempData.filter((item) => item.status === status);
    // const purchaseToUpdate =  filteredPurchases.filter((item) => item._id === item_id);
    // console.log(purchaseToUpdate);
    // console.log(filteredPurchases)
    setUpdateModalData(filteredPurchases[0]);
    setFilteredData(filteredPurchases);
  };

  const removePurchase = async () => {
    const response = await deletePurchaseOrder(updateModalData._id);
    console.log(response);
    if (response) {
      updatePurchases();
    }
    setConfirmationModal(null);
  };
  const cloneRequest = async () => {
    // console.log(updateModalData._id)
    const response = await clonePurchaseOrder(updateModalData._id);

    if (response) {
      updatePurchases();
    }
  };

  const rejectRequest = async () => {
    const status = "Rejected";
    const response = await changePurchaseOrderStatus(status, updateModalData._id, navigate);
    if (response) {
      updatePurchases();
    }
    setConfirmationModal(null);
  };

  const openModal = (type) => {
    if (type === "Confirm") {
      setConfirmationModal({
        heading: "Update Document",
        description: "Are you sure you want to confirm this Document ? ",
        btn1Text: "Confirm",
        btn2Text: "No",
        btn1Handler: updateStatus,
        btn2Handler: () => {
          setConfirmationModal(null);
        },
      });
    } else if (type === "Delete") {
      setConfirmationModal({
        heading: "Delete Document",
        description: "Are you sure you want to delete this Document ? ",
        btn1Text: "Delete",
        btn2Text: "No",
        btn1Handler: removePurchase,
        btn2Handler: () => {
          setConfirmationModal(null);
        },
      });
    } else if (type === "Edit") {
      setEditModal(true);
    } else if (type === "Approve") {
      setConfirmationModal({
        heading: "Approve Document",
        description: "Are you sure you want to approve this Document ? ",
        btn1Text: "Approve",
        btn2Text: "No",
        btn1Handler: updateStatus,
        btn2Handler: () => {
          setConfirmationModal(null);
        },
      });
    } else if ("Reject") {
      setConfirmationModal({
        heading: "Reject Document",
        description: "Are you sure you want to reject this Document ? ",
        btn1Text: "Reject",
        btn2Text: "No",
        btn1Handler: () => rejectRequest(),
        btn2Handler: () => {
          setConfirmationModal(null);
        },
      });
    }
  };

  return (
    <div className="min-h-[50vh] w-full flex items-start gap-x-10 ">
      <div className="w-[60%] min-h-[45vh] bg-white rounded-lg p-8 shadow-lg">
        <div className=" w-[90%] mx-auto  flex items-center justify-between">
          <div>
            <p className="text-black font-inter text-opacity-60 ml-1 ">
              Status
            </p>
            <p
              className={` rounded-2xl  py-0.5 flex items-center justify-center px-3 mt-1 text-sm ${
                updateModalData?.status === "Draft"
                  ? "bg-blue-5 bg-opacity-30 text-blue-5"
                  : updateModalData?.status === "Pending"
                  ? " bg-orange-5 bg-opacity-30 text-orange-5"
                  : updateModalData?.status === "Approved"? "bg-green-5 bg-opacity-30 text-green-5" : ""
              }`}
            >
              {updateModalData?.status}
            </p>
          </div>

          <div>
            <p className="text-black font-inter text-opacity-60 ">
              Creation Date
            </p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.createdAt.split("T").at("0")}
            </p>
          </div>

          <div>
            <p className="text-black font-inter text-opacity-60 ">
              Delivery Date
            </p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.deliveryDate.split("T").at("0")}
            </p>
          </div>
        </div>

        <div className="w-[90%] mx-auto flex items-center justify-between mt-5">
          <div>
            <p className="text-black font-inter text-opacity-60 ">Requester</p>
            <p className="text-sm text-black text-opacity-45 mt-1 flex flex-col gap-y-1 ">
              <span> {updateModalData?.user?.name}</span>
              <span> {updateModalData?.user?.email}</span>
            </p>
          </div>

          <div>
            <p className="text-black font-inter text-opacity-60 ">Location</p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.location}
            </p>
          </div>
        </div>

        <div className=" w-[90%] mx-auto flex items-center justify-between mt-5">
          <div>
            <p className="text-black font-inter text-opacity-60 ">Total</p>
            <p className="text-sm text-black text-opacity-45 mt-1">
              {updateModalData?.items[0]?.price *
                updateModalData?.items[0]?.quantity}
              .00 <sup>INR</sup>
            </p>
          </div>
        </div>
      </div>

      <div className="w-[30%]  h-[45vh]  ">
        {status === "Draft" && (
          <div className="h-full flex flex-col gap-y-5 justify-center">
            <button
              className="w-full py-1 rounded-2xl bg-grey-100 text-white hover:bg-green-10 font-inter shadow-md transition-all duration-200"
              onClick={() => openModal("Confirm")}
            >
              Confirm
            </button>

            <button
              className="w-full py-1 rounded-2xl bg-white text-red-25 hover:bg-red-25 hover:text-white font-inter shadow-md"
              onClick={() => openModal("Delete")}
            >
              Delete
            </button>

            <button
              className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md"
              onClick={() => openModal("Edit")}
            >
              Edit Request for Quotation
            </button>
          </div>
        )}

        {status === "Pending" && (
          <div className="h-full flex flex-col gap-y-5 justify-center">
            <button
              className="w-full py-1 rounded-2xl bg-white text-black hover:bg-green-10 font-inter shadow-md transition-all duration-200"
              onClick={() => openModal("Approve")}
            >
              Approve
            </button>

            <button className="w-full py-1 rounded-2xl   bg-red-25 text-white font-inter shadow-md"
            onClick={() => openModal("Reject")}
            >
              Reject
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md">
              Send for Revision
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md"
            onClick={() => cloneRequest()}>
              Clone
            </button>
            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md"
             onClick={() => openModal("Edit")}
            >
              Edit Purchase Order
            </button>
          </div>
        )}

        {status === "Approved" && (
          <div className="h-full flex flex-col gap-y-5 justify-center">
          
            <button className="w-full py-1 rounded-2xl bg-green-10 text-white font-inter shadow-md">
              Create Invoice
            </button>

            <button className="w-full py-1 rounded-2xl bg-green-10 text-white font-inter shadow-md">
              Create Reciept
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md" onClick={() => cloneRequest()}>
              Clone
            </button>

            <button className="w-full py-1 rounded-2xl bg-white text-black font-inter shadow-md" onClick={() => openModal("Edit")}>
              Edit Purchase Order
            </button>

            <button className="w-full py-1 rounded-2xl  bg-blue-55 text-white font-inter shadow-md">
              Resend To Supplier
            </button>

            <button className="w-full py-1 rounded-2xl text-red-25 bg-white   hover:bg-red-25 hover:text-white font-inter shadow-md" onClick={() => openModal("Reject")}>
              Cancel
            </button>
          </div>
        )}
      </div>

      {confirmationModal && (
        <ConfirmationModal confirmationModal={confirmationModal} />
      )}
      {editModal && (
        <EditPurchaseOrder
          updateModalData={updateModalData}
          setEditModal={setEditModal}
          setUpdateModalData={setUpdateModalData}
        />
      )}
    </div>
  );
};
