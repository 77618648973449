import toast from "react-hot-toast"
import { apiConnector } from "../apiConnector"
import { purchaseEndpoints } from "../apis"

const{
    CREATE_PURCHASE_API ,
    GET_ALL_PURCHASE_API,
    UPDATE_PURCHASE_STATUS,
    DELETE_PURCHASE_API,
    UPDATE_PURCHASE_API,
    CLONE_PURCHASE_API
} = purchaseEndpoints;


export  function createPurchase(
    formData , navigate
){

    return async(dispatch) => {
        const toastId = toast.loading("Loading...")
        try{
            const response  = await apiConnector("POST" ,CREATE_PURCHASE_API , formData) 

            console.log("CREATE_PURCHASE_API_REPSONSE - " , response )

            if(!response.status === 201){
                throw new Error("Cannot create Purchase Request")
            }
            toast.success("Created Purchase Request")
              
        }catch(error){
            console.log(error)
            toast.error("Cannot Create Purchase Reequest")
        }
        toast.dismiss(toastId)
        navigate("/admin/purchase-requests")
    }
}

export async function getPurchases(id) {
    
        let result = [];
        try{
            
            const response  = await apiConnector("GET" , GET_ALL_PURCHASE_API ) 

            // console.log("GET_ALL_PURCHASE_API_REPSONSE - " , response )

            if(!response.status === 201){
                throw new Error("Cannot get Purchase Requests")
            }
            // toast.success("Created Purchase Request")
              result = response.data
        }catch(error){
            console.log(error)
            toast.error("Cannot get Purchase Request")
        }
           return result;

}

export async function changeStatus(status , id , navigate){
      const toastId = toast.loading("Loading...");
      const UPDATE_API = UPDATE_PURCHASE_STATUS +"/"+ id
    //   console.log(UPDATE_API)
      let result= [];
    try{
      const response = await apiConnector("PUT" , UPDATE_API ,  {status} );

      if(!response.data.success){
             throw new Error ("Cannot Update Document")
      }

      console.log("CHANEG_STATUS_API RESPONSE - " , response);
      toast.success("Document Updated")
      result = response.data;
    //   navigate("/admin/purchase-requests")
    }catch(error){
        console.log("CHANEG_STATUS_API RESPONSE - ", error);
        toast.error("Unable to Update Document")
    }
     toast.dismiss(toastId);
     return result;
}

export async function deletePurchase(id){
    const toastId = toast.loading("Loading...");
    const DELETE_API = DELETE_PURCHASE_API +"/"+ id
    console.log(DELETE_API)
    let result =[];
  try{
    const response = await apiConnector("DELETE" , DELETE_API  );

    if(!response.data.success){
           throw new Error ("Cannot Delete Document")
    }

    console.log("DELETE_API RESPONSE - " , response);
    toast.success("Document deleted")
    result = response.data;
  }catch(error){
      console.log("DELETE_API RESPONSE - ", error);
      toast.error("Unable to Delete Document")
  }
   toast.dismiss(toastId);
  return result;
}

export async function updatePurchase(id , data){
    const toastId = toast.loading("Loading...");
    const UPDATE_API = UPDATE_PURCHASE_API +"/"+ id
    // console.log(DELETE_API)
    let result =[];
  try{
    const response = await apiConnector("PUT" , UPDATE_API , data );

    if(!response.data.success){
           throw new Error ("Cannot update document")
    }

    console.log("UPDATE_API RESPONSE - " , response);
    toast.success("Document updated")
    result = response.data.data;
  }catch(error){
      console.log("Update_API RESPONSE - ", error);
      toast.error("Unable to update document")
  }
  toast.dismiss(toastId);
  return result;
}

export async function clonePurchase(id){
  const toastId = toast.loading("Loading...")
  const CLONE_API = CLONE_PURCHASE_API + `/clone/${id}`;
  let result = [];
try{
   const response = await apiConnector("POST", CLONE_API );
     
   if(!response.data.success){
    throw new Error("Cannot clone Request");
   }

   console.log("CLONE_PURCHASE_API_RESPONSE - " , response);
   toast.success("Cloned successfully");
   result=response.data;
}catch(error){
  console.log("CLONE_PURCHASE_API_RESPONSE - " , error);  
  toast.error("Cannot clone Request");  
}
toast.dismiss(toastId)
return result;
}
