import React, { useEffect, useRef, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";

export const DownloadPurchaseOrder= ({
  setShowPreviewModal,
  downloadType,
  updateModalData,
}) => {
  const pdfRef = useRef();
  const [data, setData] = useState([]);
  useEffect(() => {
    const tempData = [];
    tempData.push(updateModalData)
    setData(tempData);
  }, []);
  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("PO.pdf");
    });
  };

  function mapObjectsToList(data) {
    const mappedList = [];

    data.forEach((item, index) => {
      const mappedItem = {
        status: item?.status,
        creationDate: item?.createdAt.split("T").at("0"),
        deliveryDate: item?.deliveryDate.split("T").at("0"),
        requesterName: item?.user?.name,
        requesterEmail: item?.user?.email,
        location: item?.location,
        department: item?.department,
        supplier: item?.supplier,
        itemName: item?.items[0]?.name,
        itemCategory: item?.items[0]?.category,
        itemPrice: item?.items[0]?.price,
        itemQuantity: item?.items[0]?.quantity,
        itemSupplier: item?.items[0]?.supplier,
        totalPrice: item?.items[0]?.price * item?.items[0]?.quantity,
      };

      mappedList.push(mappedItem);
    });

    return mappedList;
  }

  const mappedList = mapObjectsToList(data);
  //   console.log(mappedList)

  const headers = [
    { label: "Status", key: "status" },
    { label: "Creation Date", key: "creationDate" },
    { label: "Delivery Date", key: "deliveryDate" },
    { label: "Requester Name", key: "requesterName" },
    { label: "Requester Email", key: "requesterEmail" },
    { label: "Location", key: "location" },
    { label: "Department", key: "department" },
    { label: "Supplier", key: "supplier" },
    { label: "Item Name", key: "itemName" },
    { label: "Item Category", key: "itemCategory" },
    { label: "Item Price", key: "itemPrice" },
    { label: "Item Quantity", key: "itemQuantity" },
    { label: "Item Supplier", key: "itemSupplier" },
    { label: "Total Price", key: "totalPrice" },
  ];

  const csvLink = {
    filename: "PO.csv",
    headers: headers,
    data: mappedList,
  };
  return (
    <div className="w-full h-[700px] bg-grey-25 bg-opacity-40  absolute -top-[500%] left-0 flex justify-center">
      <div className="bg-white w-[70%] rounded-md h-full shadow-lg py-4">
        <div className="w-[90%] mx-auto flex justify-between items-center border-b pb-5">
          <div>
            <p className="text-xl font-semibold text-black text-opacity-80">
              Preview Request
            </p>
          </div>
          <div
            className="p-2 rounded-full bg-grey-25 text-lg cursor-pointer hover:bg-grey-75 "
            onClick={() => setShowPreviewModal(null)}
          >
            <RxCross1 />
          </div>
        </div>

        <div
          className="w-[90%] mx-auto mt-5 pb-5 grid grid-cols-2 gap-x-5 gap-y-3"
          ref={pdfRef}
        >
          {/* <div className="col-span-2 flex justify-center -translate-x-[10%] my-8">
            <div className="w-[30%] flex justify-start items-center" >
              <div className=" flex  items-center mt-1 relative">
                <FaCaretLeft className="text-4xl  text-green-5 font-semibold absolute top-0 right-5" />
                <FaCaretRight className="text-4xl text-green-5 " />
              </div>
              <p className="text-4xl font-extrabold text-green-5 tracking-[0.1em] ">
                Procura<span className="font-extralight">Flow</span>
              </p>
            </div>
          </div> */}

          <div className="pl-5">
            <p className="  font-semibold ">
               Status :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.status}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Creation Date :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.createdAt.split("T").at("0")}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Delivery Date :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.deliveryDate.split("T").at("0")}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Requester Name :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.user?.name}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Requester Email :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.user?.email}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Location :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.location}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Department :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.department}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Supplier :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.supplier}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Item Name :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.items[0]?.name}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Item Category :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.items[0]?.category}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Item Price :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.items[0]?.price} Rs.
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Item Quantity :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.items[0]?.quantity}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Item Supplier :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.items[0]?.supplier}
              </span>
            </p>
          </div>

          <div className="pl-5">
            <p className="  font-semibold">
              Total Price :{" "}
              <span className="text-sm font-extralight">
                {updateModalData?.items[0]?.price *
                  updateModalData?.items[0]?.quantity}{" "}
                Rs.
              </span>
            </p>
          </div>
        </div>

        <div className="w-[90%] mx-auto flex justify-center my-16">
          {downloadType === "PDF" && (
            <button
              className="-translate-x-[20%] px-6 py-2 rounded-lg bg-green-5 text-white"
              onClick={downloadPDF}
            >
              Download as PDF
            </button>
          )}
          {downloadType === "CSV" && (
            <CSVLink {...csvLink} className="">
              {" "}
              <p className="text-opacity-60 font-semibold cursor-pointer px-4 bg-green-5 text-white  text-center py-1.5 rounded-md">
                Download as CSV
              </p>
            </CSVLink>
          )}
        </div>
      </div>
    </div>
  );
};
