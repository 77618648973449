import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { locationEndpoints } from "../apis";

const {
    ADD_LOCATION_API,
    LOCATION_OPERATION_API
} = locationEndpoints

export async function addLocation(data){
    const toastId = toast.loading("Loading...");
    let result =false;
  try{
     const response = await apiConnector("POST", ADD_LOCATION_API , data );
       
     if(!response.data.success){
      throw new Error("Cannot add Location");
     }
  
     console.log("ADD_LOCATION_API_REPSONSE - " , response);
     toast.success("Location added");
      result = response.data.success
  }catch(error){
    console.log("ADD_LOCATION_API_REPSONSE - " , error);  
    toast.error("Cannot add location");  
  }
  toast.dismiss(toastId);
  return result;
  }

  export async function getLocations(){
  
    const GET_LOCATIONS_API = LOCATION_OPERATION_API + "/"
    let result =[];
  try{
     const response = await apiConnector("GET", GET_LOCATIONS_API);
       
     if(!response.data.success){
      throw new Error("Cannot get Locations");
     }
  
     console.log( "GET_LOCATIONS_API_REPSONSE - " , response);
     result = response.data.locations
  }catch(error){
    console.log(" GET_LOCATIONS_API_REPSONSE - " , error);  
    toast.error("Cannot get Locations");  
  }

  return result;
  }

  export async function editLocation(data , id){
    const toastId = toast.loading("Loading...");
    const UPDATE_LOCATION_API = LOCATION_OPERATION_API + `/${id}`;
    let result =false;
  try{
     const response = await apiConnector("PUT", UPDATE_LOCATION_API , data );
       
     if(!response.data.success){
      throw new Error("Cannot update Location");
     }
  
     console.log("UPDATE_LOCATION_API_REPSONSE - " , response);
     toast.success("Location updated");
      result = response.data.success
  }catch(error){
    console.log("UPDATE_LOCATION_API_REPSONSE - " , error);  
    toast.error("Cannot update location");  
  }
  toast.dismiss(toastId);
  return result;
  }

  export async function removeLocation(id){
    const toastId = toast.loading("Loading...");
    const DELETE_LOCATION_API = LOCATION_OPERATION_API + `/${id}`;
    let result =false;
  try{
     const response = await apiConnector("DELETE" , DELETE_LOCATION_API  );
       
     if(!response.data.success){
      throw new Error("Cannot delete Location");
     }
  
     console.log("DELETE_LOCATION_API_REPSONSE - " , response);
     toast.success("Location deleted");
      result = response.data.success
  }catch(error){
    console.log("DELETE_LOCATION_API_REPSONSE - " , error);  
    toast.error("Cannot delete location");  
  }
  toast.dismiss(toastId);
  return result;
  }
  