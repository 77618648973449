import React from 'react'

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from  'chart.js'

import { Bar } from 'react-chartjs-2'
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
)
export const TotalSpentBySupplier = () => {
  const data = {
    labels: ['Main House' , 'Amazon' , 'Flipkart' , 'IndiaMart'],
    datasets : [
      {
        label:'Main House',
        data:[19000 , 8000 , 2000 , 4000],
        backgroundColor:'#7759F3',
        boderColor:'black',
        borderWidth:1,
        barPercentage:0.6,
        categoryPercentage:0.9,
        borderRadius:5,

      },
      
    ],
    
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10000, // Increment by 8k (8000)
          callback: function (value, index, values) {
            return value === 0 ? '0 INR' : value / 1000 + 'k INR'; // Convert values to 'Xk INR'
          },
        },
      },
    },
  };
  return (
    <div>
        <p className='text-black font-inter my-3'>By Supplier</p>

        <p className='text-black text-lg'>Total</p>
        <p className='text-black text-lg my-1'>32,984.12 <sup>INR</sup></p>

       <div className='my-6'>
         <Bar
             data={data}
             options={options}/>; 
       </div>
    </div>
  )
}
