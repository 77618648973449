import React, { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FilterData } from "../PurchaseRequests/GetPurchase/FilterData";

export const Suppliers = () => {
  ////////////////Dummy Data//////////////////////
  const dummyData = [
    {
      name: "Amazon",
      contactPerson: "Anil Sharma",
      supplierID: "PFS0009",
      detils: "Specializes in electronics components",
      status: "Active",
    },
  ];
  const [tableData, setTableData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setTableData(dummyData);
    //    console.log(tableData)
  }, []);
  return (
    <div className="w-[calc(100vw-280px)] ">
      <div className="w-[full] flex justify-end pr-20 my-24 gap-x-5">
        <button className="bg-white  shadow-md rounded-2xl py-2 px-5 font-semibold">
          <Link to={"/admin/suppliers/create-supplier"}>Add Supplier</Link>
        </button>
        <button className="bg-white shadow-md rounded-2xl py-2 px-5 font-semibold">
          <Link to={"/admin/suppliers/import-suppliers"}>Import Suppliers</Link>
        </button>
      </div>

      {tableData !== null ? (
        <div className=" w-full flex flex-col gap-y-8">
          {/* filter component  */}
          <div className="w-[90%] mx-auto">
            <FilterData
              data={tableData}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
            />
          </div>
          {/* table  */}
          <div className="w-[90%] mx-auto overflow-hidden rounded-xl shadow-lg">
            <table className="w-full">
              <thead className="rounded-t-xl h-[40px] px-5 bg-grey-75 text-black text-opacity-[70%] font-inter">
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Contact Person</th>
                  <th>Supplier ID</th>
                  <th>Order Details</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((supplier, index) => (
                  <tr
                    key={index}
                    className="h-[40px] text-center text-black text-opacity-70 "
                  >
                    <td>{`${
                      index < 10 ? `0${index + 1}` : `${index + 1}`
                    }`}</td>
                    <td>{supplier?.name}</td>
                    <td>{supplier?.contactPerson}</td>
                    <td>{supplier?.supplierID}</td>
                    <td>{supplier?.detils}</td>
                    <td>
                      <p
                        className={`  text-sm rounded-2xl py-1  ${
                          supplier?.status === "Active"
                            ? " bg-green-25 bg-opacity-20 text-green-25"
                            : ""
                        }`}
                      >
                        {supplier?.status}
                      </p>
                    </td>
                    <td>
                      <p className="flex justify-center items-center cursor-pointer hover:text-grey-50">
                        <FaRegEdit />
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p className="w-full text-center text-2xl text-black text-opacity-60 flex items-center justify-center gap-x-3">
          <BiErrorCircle />
          No Data Found
        </p>
      )}
    </div>
  );
};
