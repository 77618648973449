import React from 'react'
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import LoginForm from '../components/core/Auth/LoginForm';


const Login = () => {
  return (
    <div className='absolute top-0 left-0 w-screen min-h-screen h-auto flex justify-center items-center bg-grey-25  z-20 py-10' >
        
        <div className='min-h-[70%] min-w-[40%] flex flex-col gap-y-6 px-6 py-8 bg-white rounded-2xl shadow-md '>
            {/* heading  */}
          <Link to={"/"}>
          <div className='w-full flex justify-center items-center'>
                <div className=' flex  items-center mt-1 relative'>
                <FaCaretLeft className='text-4xl  text-green-5 font-semibold absolute top-0 right-5'/>
                <FaCaretRight className='text-4xl text-green-5 '/>
                </div>
                <p className='text-4xl font-extrabold text-green-5 tracking-[0.1em] '>Procura<span className='font-extralight'>Flow</span></p>
            </div>
          </Link>

            <LoginForm/>
              
            <Link to={"/signup"}>
            <p className='text-lg text-green-5  text-center my-2 font-semibold'> Create an account </p>
            </Link>
        </div>

    </div>
  )
}

export default Login