import { ROLES } from "../utils/constants";
export const sidebarLinks = [
  {
    id: 1,
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: "FaHome",
  },
  {
    id: 2,
    name: "Purchase Requests",
    path: "/admin/purchase-requests",
    type: ROLES.ADMIN,
    icon: "FaFileExport",
  },
  {
    id: 3,
    name: "Quotation Requests",
    path: "/admin/quotation-requests",
    type: ROLES.ADMIN,
    icon: "FaFileAlt",
  },
  {
    id: 4,
    name: "Purchase Orders",
    path: "/admin/purchase-orders",
    type: ROLES.ADMIN,
    icon: "FaBusinessTime",
  },
  {
    id: 5,
    name: "Invoices",
    path: "/admin/invoices",
    type: ROLES.ADMIN,
    icon: "FaReceipt",
  },
  {
    id: 6,
    name: "Expenses",
    path: "/admin/expenses",
    type: ROLES.ADMIN,
    icon: "FaFileInvoiceDollar",
  },
  {
    id: 7,
    name: "Inventory",
    path: "/admin/inventory",
    type: ROLES.ADMIN,
    icon: "FaStore",
  },
  {
    id: 8,
    name: "Reports",
    path: "/admin/reports",
    type: ROLES.ADMIN,
    icon: "FaFileUpload",
  },
  {
    id: 9,
    name: "Suppliers",
    path: "/admin/suppliers",
    type: ROLES.ADMIN,
    icon: "FaCaravan",
  },
];
