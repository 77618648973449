import {combineReducers} from "@reduxjs/toolkit"
import profileReducer from "../slices/profileSlice";
import authReducer from "../slices/authSlice";
import paginationReducer from "../slices/paginationSlice";
import purchasesReducer from "../slices/purchasesSlice";

const rootReducer = combineReducers({
    auth:authReducer,
    profile:profileReducer,
    pagination: paginationReducer,
    purchases:purchasesReducer

})

export default rootReducer