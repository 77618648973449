import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setPage} from "../../../../../slices/paginationSlice";
import { useForm } from "react-hook-form";


export const Pagination = () => {


  const { page, totalPages } = useSelector((state) => state.pagination);
  const {purchasePages} = useSelector((state) => state.purchases);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();


  const {
    register,
    setValue
  } = useForm();

  useEffect(() => {
    setValue("number" , page+1)
  },[page])
 
  const nextPage = () => {
    if (page + 1 > purchasePages.length - 1) {
      return;
    } else {
      dispatch(setPage(page + 1));
      // console.log(page);
    }
  };
  const prevPage = () => {
    if (page -1 < 0) {
      return;
    } else {
      dispatch(setPage(page - 1));
      // console.log(page);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        searchPage();
    }
};

  const searchPage =() => {
    let desiredPage = Number(searchQuery);
    if( desiredPage <= purchasePages.length && desiredPage >0){
      dispatch(setPage(desiredPage - 1));
      // console.log("updated" , page)
    }else{
    //  console.log(desiredPage)
    }
  
  }
  return (
    <div className="w-full my-5 flex items-center justify-center ">
      <div className="flex items-center justify-center  gap-x-5 -translate-x-[15%]">
        <button
          className="bg-white p-4 rounded-md shadow-md text-grey-50 hover:bg-grey-100 hover:bg-opacity-30 hover:text-black"
          onClick={prevPage}
        >
          {" "}
          <MdKeyboardArrowLeft />
        </button>
         <div className="flex items-center text-lg  ">
          <input type="number" id="number" name="number" {...register("number" ,{})} className="appearance-none bg-grey-25 w-[35px] remove-arrow outline-none border-none text-lg border-transparent focus:border-transparent focus:ring-0" onChange={(event) => setSearchQuery(event.target.value)} onKeyDown={handleKeyPress}/>
          <p className="">of  </p>
          <p className="ml-3"> {totalPages}</p>
         </div>
        <button
          className="bg-white p-4 rounded-md shadow-md text-grey-50 hover:bg-grey-100 hover:bg-opacity-30 hover:text-black"
          onClick={nextPage}
        >
          <MdKeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};
