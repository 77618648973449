import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../slices/purchasesSlice";
const Pending = () => {
  const {totalPurchases , filters} = useSelector((state) => state.purchases);
  const totalPendingRequest = totalPurchases.filter((item) => item.status === "Pending");
   const dispatch = useDispatch();

   const isFilterPresent = (filterArray) => {
    for (let i=0 ; i<filters.length ; i++){
      if(filters[i].type !== filterArray[i].type && filters[i].value !== filterArray[i].value){
        return false;
      }
    }
    return true;
   }
  const applyPendingFilter = () => {
    const value ="Pending"
    const filterArray = [{type:"status" , value}];
    if(filters.length === 1 && isFilterPresent(filterArray) ){
      dispatch(setFilters([]));
    }else{
      // set this filter 
      dispatch(setFilters(filterArray));
    }
      

  }
  return (

     <div className=" px-5 py-[11px] bg bg-white rounded-lg flex items-center justify-center gap-x-3 shadow-lg cursor-pointer" onClick={applyPendingFilter}>
      <div className=" h-10 w-10 rounded-full flex justify-center items-center bg-grey-25">
        <p className="text-black text-lg">{totalPendingRequest.length}</p>
      </div>
      <p className="text-lg ">Pending</p>
    </div>
 
  );
};

export default Pending;
