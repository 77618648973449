import "./App.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import OpenRoute from "./components/core/Auth/OpenRoute";
import Admin from "./pages/Admin";
import PurchaseRequests from "./components/core/Admin/PurchaseRequests";
import Navbar from "./components/common/Navbar";
import CreatePurchaseRequisition from "./components/core/Admin/PurchaseRequests/CreatePurchaseRequisition";
import Home from "./pages/Home";
import Blank from "./components/common/Blank";
import QuotationRequests from "./components/core/Admin/QuotationRequests";
import CreateQuotationRequisition from "./components/core/Admin/QuotationRequests/CreateQuotationRequisition";
import Error from "./pages/Error";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";

import Settings from "./components/core/Admin/Settings";
import { useSelector } from "react-redux";
import { ROLES } from "./utils/constants";
import { ResetPassword } from "./pages/ResetPassword";
import { CommonMessage } from "./pages/CommonMessage";
import { UpdatePurchaseRequest } from "./components/core/Admin/PurchaseRequests/UpdatePurchaseRequest/UpdatePurchaseRequest";
import { Suppliers } from "./components/core/Admin/Suppliers/Suppliers";
import { AddSupplier } from "./components/core/Admin/Suppliers/AddSupplier";
import { Inventory } from "./components/core/Admin/Inventory/Inventory";
import { AddStockBalance } from "./components/core/Admin/Inventory/AddStockBalance";
import { ImportSuppliers } from "./components/core/Admin/Suppliers/ImportSuppliers";
import { Invoices } from "./components/core/Admin/Invoices/Invoices";
import { Expenses } from "./components/core/Admin/Expenses/Expenses";
import { UpdateInvoice } from "./components/core/Admin/Invoices/UpdateInvoice";
import { CreateInvoice } from "./components/core/Admin/Invoices/CreateInvoice";
import { UpdateExpense } from "./components/core/Admin/Expenses/UpdateExpense";
import { CreateExpense } from "./components/core/Admin/Expenses/CreateExpense";
import { Reports } from "./components/core/Admin/Reports/Reports";
import { ShowReport } from "./components/core/Admin/Reports/ShowReport";
import { RunReport } from "./components/core/Admin/Reports/RunReport";
import { UpdateQuotationRequests } from "./components/core/Admin/QuotationRequests/UpdateQuotationRequests/UpdateQuotationRequests";
import { UpdatePurchaseOrders } from "./components/core/Admin/PurchaseOrders/UpdatePurchaseOrders/UpdatePurchaseOrders";
import { CreatePurchaseOrder } from "./components/core/Admin/PurchaseOrders/CreatePurchaseOrder";
import PurchaseOrders from "./components/core/Admin/PurchaseOrders";
import { SettingsLayout } from "./components/core/Admin/Settings/Settings_Common/SettingsLayout";
import { DocumentFields } from "./components/core/Admin/Settings/DocumentFields/DocumentFields";
import { AccountSettings } from "./components/core/Admin/Settings/AccountSettings/AccountSettings";
import { LocationSettings } from "./components/core/Admin/Settings/LocationSettings/LocationSettings";
import { UserManagement } from "./components/core/Admin/Settings/UserManagement/UserManagement";
import { AdminDahboard } from "./components/core/Admin/AdminDashboard/AdminDahboard";
import { DownloadAsPDF } from "./components/core/Admin/PurchaseRequests/GetPurchase/DownloadPurchaseRequests/DownloadAsPDF";
import { DownloadPurchaseOrders } from "./components/core/Admin/PurchaseOrders/GetPurchaseOrders/DownloadOrders/DownloadPurchaseOrders";


function App() {
  const { user } = useSelector((state) => state.profile);

  return (
    <div className="w-screen min-h-screen flex  flex-row-reverse relative overflow-x-hidden main-container body">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <OpenRoute>
              <Home />
            </OpenRoute>
          }
        />

        <Route
          path="/login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />

        <Route
          path="/signup"
          element={
            <OpenRoute>
              <Signup />
            </OpenRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <OpenRoute>
              <ForgotPassword />
            </OpenRoute>
          }
        />

        <Route
          path="/resetpassword/:id"
          element={
            <OpenRoute>
              <ResetPassword />
            </OpenRoute>
          }
        />

        <Route
          path="/common-message/:param1/:param2/:param3"
          element={
            <OpenRoute>
              <CommonMessage />
            </OpenRoute>
          }
        />

        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        >
          <Route path="/admin/dashboard" element={<Blank/>} >
            <Route index element={<AdminDahboard/>} />
          </Route>
          <Route path="/admin/settings" element={<Settings />} />

          {user?.role === ROLES.ADMIN && (
            <>
              {/* PR Routes  */}
              <Route path="/admin/purchase-requests" element={<Blank />}>
                <Route index element={<PurchaseRequests />} />
                <Route
                  path="/admin/purchase-requests/create-purchase-requisition"
                  element={<CreatePurchaseRequisition />}
                />

                <Route
                  path="/admin/purchase-requests/update-purchase-requests/:status/:item_id"
                  element={<UpdatePurchaseRequest/>}
                />

                 <Route
                    path="/admin/purchase-requests/preview-purchase-requests/purchaserequests"
                    element={<DownloadAsPDF/>} />

              </Route>
            </>
          )}

          {/* QR Routes  */}
          <Route path="/admin/quotation-requests" element={<Blank />}>
            <Route index element={<QuotationRequests />} />
            <Route
              path="/admin/quotation-requests/create-quotation-requisition"
              element={<CreateQuotationRequisition />}
            />

            <Route
              path="/admin/quotation-requests/update-quotation-requests/:status"
              element={<UpdateQuotationRequests />}
            />
          </Route>


             {/* PO Routes  */}
          <Route path="/admin/purchase-orders" element={<Blank />}>
            <Route index element={<PurchaseOrders />} />
            <Route
              path="/admin/purchase-orders/create-purchase-order"
              element={<CreatePurchaseOrder />}
            />

            <Route
              path="/admin/purchase-orders/update-purchase-orders/:status"
              element={<UpdatePurchaseOrders />}
            />

            <Route
                    path="/admin/purchase-orders/preview-purchase-orders/purchaseorders"
                    element={<DownloadPurchaseOrders/>} />
          </Route>



           {/* Ivoices  Routes  */}
          <Route path="/admin/invoices" element={<Blank/>} >
            <Route index element={<Invoices />} /> 
            <Route path="/admin/invoices/update-invoice/:invoiceId"  element={<UpdateInvoice/>}  />   
            <Route path="/admin/invoices/create-invoice"  element={<CreateInvoice/>}  />
          </Route> 

            {/* Expenses  Routes  */}
            <Route path="/admin/expenses" element={<Blank/>} >
            <Route index element={<Expenses />} />     
            <Route path="/admin/expenses/update-expense/:expenseId"  element={<UpdateExpense/>}  />   
            <Route path="/admin/expenses/create-expense"  element={<CreateExpense/>}  />
          </Route> 

           {/* Inventory  Routes  */}
           <Route path="/admin/inventory" element={<Blank/>} >
            <Route index element={<Inventory />} />
            <Route path="/admin/inventory/add-stock-balance" element={<AddStockBalance/>} />            
          </Route>

           {/* Reports  Routes  */}
           <Route path="/admin/reports" element={<Blank/>} >
            <Route index element={<Reports />} />     
            <Route path="/admin/reports/show-report" element={<ShowReport/>} />       
            <Route path="/admin/reports/run-report" element={<RunReport/>} />      
          </Route>

          {/* Supplier Routes  */}
          <Route path="/admin/suppliers" element={<Blank/>} >
            <Route index element={<Suppliers />} />
            <Route path="/admin/suppliers/create-supplier" element={<AddSupplier/>} />
            <Route path="/admin/suppliers/import-suppliers" element={<ImportSuppliers/>} />
          </Route>

          {/* Settings Route  */}
           <Route path="/admin/settings" element={<SettingsLayout/>} >
             <Route index element={<Settings/>} />
             <Route path="/admin/settings/document-fields" element={<DocumentFields/>} />
             <Route path="/admin/settings/account-settings" element={<AccountSettings/>} />
             <Route path="/admin/settings/location-management" element={<LocationSettings/>} />
             <Route path="/admin/settings/user-management" element={<UserManagement/>} />
           </Route>
         
        </Route>

        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
