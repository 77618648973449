import React from 'react'
import { useForm } from 'react-hook-form'
import { BsArrowRight } from 'react-icons/bs'
import { FaArrowRight, FaChevronRight } from 'react-icons/fa'

export const DocumentFields = () => {

    const {
        register,
        handleSubmit,
        formState:{errors},
        setValue,
        getValues
    } = useForm()
  return (
    <div className='w-[75%] h-fit '>
          <form className='w-[50%] mx-auto flex flex-col gap-y-0 ' onSubmit={handleSubmit(() => {})}>
           <label className='lable-style'>
            Name
            <input
             type='text' 
             id='name'
             name='name'
             placeholder='Please Enter your Name'
             {...register("name" , {required:true})}
             className='input-style'/>
             {
                errors.name && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Name</span>)
             }
            </label> 

            <label className='lable-style'>
            Description
            <input
             type='text' 
             id='description'
             name='description'
             placeholder='Please Enter Description'
             {...register("description" , {required:true})}
             className='input-style'/>
             {
                errors.description && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Description</span>)
             }
            </label> 

            <label className='lable-style'>
              <p className='flex items-center '>  Type <sup>*</sup></p>
            <select 
            name="type"
             id="type"
             defaultValue={""}
             className='input-style bg-white'
             {...register("type" , {required:true})}
            >
                <option value="" ></option>
                <option>Type-1</option>
                <option>Type-2</option>
            </select>
             {
                errors.type && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Type</span>)
             }
            </label> 

            <label className='lable-style'>
            Scope
            <div className='text-black font-normal'>
                <div className='flex items-center gap-x-1'><input type='checkbox'/> Purchase Requests</div>
                <div className='flex items-center gap-x-1'><input type='checkbox'/>Request for Proposals</div>
                <div className='flex items-center gap-x-1'><input type='checkbox'/> Purchase Orders</div>
                <div className='flex items-center gap-x-1'><input type='checkbox'/> All</div>
            </div>
            </label>

            <div className='flex justify-end'>
                 <button className='bg-green-5 p-2 text-sm rounded-md text-white flex items-center gap-x-1'>
                    <p>Create</p>
                    <FaChevronRight />
                 </button>
            </div> 
        </form>
    </div>
  )
}
