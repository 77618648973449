import React, { useState } from "react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { AddItemModal } from "./AddItemModal";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { setLoading } from "../../slices/profileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPurchase } from "../../services/operations/purchaseAPI";
import { getAllItems } from "../../services/operations/productAPI";
import { IoAddOutline } from "react-icons/io5";
import { FaCross } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { createPO } from "../../services/operations/purchaseOrderAPI";

const CreateFormTemplate = ({
  deliveryDate,
  location,
  department,
  references,
  addItems,
  attachements,
  note,
  supplier,
  sendPO,
  issueDate,
  dueDate,
  expenseID,
  selectItems,
  type,
  paymentTerms,
}) => {
  ///////////////////////Dummy Data////////////////////////////////
  const locations = [
    "Head Quaters",
    "Back Office",
    "WareHouse",
    "Branch Office",
  ];

  const departments = [
    "Marketing",
    "Administration",
    "Human Resources",
    "Sales",
  ];
  /////////////////////////////////////////////////////////////////

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Attachments
  const [selectedFile, setSelectedFile] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [items, setItems] = useState();
  const [showItemsModal, setShowItemsModal] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showAddItemsModal, setShowAddItemsModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleUploadClick = () => {
  //   //  e.stopPropogation()
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (e) => {
  //   // Handle the selected file here
  //   e.preventDefault();
  //   setSelectedFile(e.target.files[0]);
  //   // console.log('Selected File:', selectedFile);
  // };

  const addAttachment = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onRemove = (item) => {
    setItemsList(() => itemsList.filter((listItem) => listItem !== item));
  };

  useEffect(() => {
    // console.log("This is itemsList",itemsList)
    setItems(itemsList);
  }, [itemsList]);

  useEffect(() => {
    getItemsList();
  }, []);

  const getItemsList = async () => {
    const purchaseItems = await getAllItems();
    if (purchaseItems.length !== 0) {
      setAllItems(purchaseItems);
    }
  };

  const createPurchaseOrder = async (data, navigate) => {
    const response = await createPO(data, navigate);
  };
  ///Final Submit
  function submitCreateFormData(data) {
    if (type === "PurchaseRequest") {
      if (items.length === 0) {
        toast.error("Please add atleast one item");
        return;
      }
    }
    //  if(!selectedFile){
    //   toast.error("Please add Attachements")
    //   return;
    //  }

    //add values to formData
    switch (type) {
      case "PurchaseRequest":
        data.items = items;
        break;
      case "PurchaseOrder":
        if (selectedItems.length === 0) {
          toast.error("Please select item");
        } else {
          data.items = selectedItems.map((item) => ({
            _id: item._id,
          }));
        }
        break;
    }
    data.attachements = selectedFile;
    console.log(data);

    //  backend call
    setLoading(true);
    switch (type) {
      case "PurchaseRequest":
        dispatch(createPurchase(data, navigate));
        setLoading(false);
        break;
      case "PurchaseOrder":
        createPurchaseOrder(data, navigate);
        break;
    }
  }

  return (
    <div className=" w-[44rem] max-h-[1000px] bg-white mt-6 px-8 rounded-md translate-x-[-20%] relative">
      <form onSubmit={handleSubmit(submitCreateFormData)}>
        <div className="py-4 flex flex-wrap gap-y-5 items-center gap-x-10 ">
          {deliveryDate && (
            <>
              {/* delivery date  */}
              <label className="flex flex-col gap-y-3 font-semibold text-xl text-grey-50 relative">
                {type === "PurchaseRequest" ? (
                  <>Delivery Date</>
                ) : type === "PurchaseOrder" ? (
                  <p className="flex items-center">
                    {" "}
                    Delivery Date <sup className="text-red-25">*</sup>
                  </p>
                ) : (
                  <p></p>
                )}
                <input
                  type="date"
                  name="deliveryDate"
                  id="deliveryDate"
                  className="w-[18rem] h-[48px]  bg-white text-black  rounded-md  px-2 py-1 font-normal text-sm"
                  {...register("deliveryDate", {
                    required: {
                      value: false,
                      message: "Please enter your Delivery Date",
                    },
                  })}
                />
                {errors.deliveryDate && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    {errors.deliveryDate.message}
                  </span>
                )}
              </label>
            </>
          )}

          {issueDate && (
            <>
              {/*issueDate  */}
              <label className="flex flex-col gap-y-3 font-semibold text-xl text-grey-50 relative">
                Issue Date
                <input
                  type="date"
                  name="issueDate"
                  id="issueDate"
                  className="w-[18rem] h-[48px]  bg-white text-black  rounded-md px-2 py-1 font-normal text-sm"
                  {...register("issueDate", {
                    required: {
                      value: true,
                      message: "Please enter your Issue Date",
                    },
                    max: {
                      value: new Date().toISOString().split("T")[0],
                      message: "Issue  Date cannot be in future",
                    },
                  })}
                />
                {errors.issueDate && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    {errors.issueDate.message}
                  </span>
                )}
              </label>
            </>
          )}

          {dueDate && (
            <>
              {/*dueDate  */}
              <label className="flex flex-col gap-y-3 font-semibold text-xl text-grey-50 relative">
                Due Date
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  className="w-[18rem] h-[48px]  bg-white  text-black  rounded-md  px-2 py-1 font-normal text-sm"
                  {...register("dueDate", {
                    required: {
                      value: true,
                      message: "Please enter your Due Date",
                    },
                    max: {
                      value: new Date().toISOString().split("T")[0],
                      message: "Due  Date cannot be in future",
                    },
                  })}
                />
                {errors.dueDate && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    {errors.dueDate.message}
                  </span>
                )}
              </label>
            </>
          )}

          {location && (
            <>
              {/* Location  */}

              <div className="flex flex-col gap-y-3 relative ">
                <label
                  htmlFor="location"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50 "
                >
                  <p className="flex items-center">
                    {" "}
                    Location<sup className="text-red-25">*</sup>
                  </p>
                </label>

                <select
                  type="text"
                  name="location"
                  id="location"
                  className="w-[18rem] h-[48px]  bg-white  text-black  rounded-md px-2 py-1 "
                  {...register("location", { required: true })}
                >
                  <option value="">Choose a Location</option>
                  {locations.map((ele, i) => {
                    return (
                      <option
                        key={i}
                        value={ele}
                        className="bg-white hover:text-green-5"
                      >
                        {ele}
                      </option>
                    );
                  })}
                </select>
                {errors.location && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    Please enter your Location.
                  </span>
                )}
              </div>
            </>
          )}

          {supplier && (
            <>
              {/* supplier  */}

              <div className="flex flex-col gap-y-3 relative ">
                <label
                  htmlFor="supplier"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                  <p className="flex items-center">
                    Supplier <sup className="text-red-25">*</sup>
                  </p>
                </label>

                <select
                  type="text"
                  name="supplier"
                  id="supplier"
                  className="w-[18rem] h-[48px]  bg-white  text-black  rounded-md px-2 py-1 "
                  {...register("supplier", { required: true })}
                  // defaultValue={user?.additionalDetails?.gender}
                >
                  <option value="">Choose a Supplier</option>
                  {locations.map((ele, i) => {
                    return (
                      <option
                        key={i}
                        value={ele}
                        className="bg-white hover:text-green-5"
                      >
                        {ele}
                      </option>
                    );
                  })}
                </select>
                {errors.supplier && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    Please enter your Supplier.
                  </span>
                )}
              </div>
            </>
          )}

          {department && (
            <>
              {/* Department  */}

              <div className="flex flex-col gap-y-3 relative">
                <label
                  htmlFor="department"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                  <p className="flex items-center">
                    {" "}
                    Department<sup className="text-red-25">*</sup>
                  </p>
                </label>

                <select
                  type="text"
                  name="department"
                  id="department"
                  className="w-[18rem] h-[48px]  bg-white  text-black  rounded-md  px-2 py-1 "
                  {...register("department", { required: true })}
                  // defaultValue={user?.additionalDetails?.gender}
                >
                  <option value="">Choose a Department</option>
                  {departments.map((ele, i) => {
                    return (
                      <option
                        key={i}
                        value={ele}
                        className="bg-white hover:text-green-5"
                      >
                        {ele}
                      </option>
                    );
                  })}
                </select>
                {errors.department && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    Please enter your Department.
                  </span>
                )}
              </div>
            </>
          )}

          {sendPO && (
            <>
              {/* sendPO  */}

              <div className="flex flex-col gap-y-3 relative ">
                <label
                  htmlFor="sendPO"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                  {/* <p className="flex items-center "> Send PO to Supplier <sup className="text-red-25">*</sup></p> */}
                  Send PO to Supplier
                </label>

                <select
                  type="text"
                  name="sendPO"
                  id="sendPO"
                  className="w-[18rem] h-[48px]  bg-white text-black   rounded-md px-2 py-1 "
                  {...register("sendPO", { required: false })}
                  // defaultValue={user?.additionalDetails?.gender}
                >
                  <option value="">Choose a PO</option>
                  {locations.map((ele, i) => {
                    return (
                      <option
                        key={i}
                        value={ele}
                        className="bg-white hover:text-green-5"
                      >
                        {ele}
                      </option>
                    );
                  })}
                </select>
                {errors.sendPO && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    Please enter your Supplier PO.
                  </span>
                )}
              </div>
            </>
          )}

          {references && (
            <>
              {/* Reference  */}
              <label className="flex flex-col gap-y-3 font-semibold text-xl text-grey-50 relative">
                Reference
                <input
                  type="text"
                  name="references"
                  id="references"
                  className="w-[37rem] h-[48px]  bg-white  text-black  rounded-md outline outline-grey-100 outline-[1px] px-2 py-1 font-normal text-sm"
                  {...register("references", {
                    required: {
                      value: false,
                      message: "Please enter your Reference",
                    },
                  })}
                />
                {errors.references && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    {errors.references.message}
                  </span>
                )}
              </label>
            </>
          )}

          {addItems && (
            <>
              <div
                className={`flex flex-col gap-y-2 ${
                  itemsList.length !== 0 ? "w-full" : " w-[18rem]"
                }`}
              >
                <label
                  htmlFor="addItems"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                  <p className="flex items-center">
                    Items<sup className="text-red-25">*</sup>
                  </p>
                </label>

                <div>
                  {itemsList.length === 0 ? (
                    <div
                      className="flex items-center gap-x-2 cursor-pointer"
                      id="addItems"
                      onClick={() => setShowItemsModal(true)}
                    >
                      <MdOutlineAddCircleOutline className="text-lg" />
                      <p className="text-lg font-semibold text-richblack-5">
                        Add Item
                      </p>
                    </div>
                  ) : (
                    <div className=" w-[100%] mx-auto rounded-lg outline outline-2 outline-grey-25">
                      <table className="w-full p-4 border-spacing-y-10 ">
                        <thead className="bg-grey-25 rounded-lg ">
                          <tr className="text-center text-grey-50 text-sm">
                            <th>No.</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Amount</th>
                            <th>Total</th>
                            <th>Category</th>
                            <th>Supplier</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className=" text-center bg-white py-4 space-y-8">
                          {items.map((item, index) => (
                            <tr
                              key={index}
                              className="mt-10 text-sm border border-grey-25 h-[50px]"
                            >
                              <td>0{`${index + 1}`}</td>
                              <td>{item?.name}</td>
                              <td>
                                {item.quantity < 10 ? (
                                  <span>0{item?.quantity}</span>
                                ) : (
                                  <span>{item?.quantity}</span>
                                )}
                              </td>
                              <td>{item?.price}</td>
                              <td>{`${item?.quantity * item?.price}`}</td>
                              <td>{item?.category}</td>
                              <td>{item?.supplier}</td>
                              <td>
                                {
                                  <button
                                    onClick={() => onRemove(item)}
                                    className="text-red-5 underline"
                                  >
                                    Remove
                                  </button>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <button
                        className="text-black text-sm flex items-center gap-x-1 mt-0  border-t-2 border-t-grey-25 w-full py-2 pl-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowItemsModal(true);
                        }}
                      >
                        <MdOutlineAddCircleOutline />
                        Add Items
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {selectItems && (
            <>
              <div
                className={`flex flex-col gap-y-2 ${
                  allItems.length !== 0 ? "w-full" : " w-[18rem]"
                }`}
              >
                <label
                  htmlFor="selectedItem"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                  <p className="flex items-center">
                    Items<sup className="text-red-25">*</sup>
                  </p>
                </label>

                <div className="w-full px-3 py-1 rounded-lg min-h-[48px] h-fit text-black border border-black border-opacity-60 flex justify-center flex-col relative">
                  {/* preview list of items  */}
                  <div
                    className={`flex items-center gap-x-2 my-3 border-b pb-2 overflow-x-scroll ${
                      selectedItems.length !== 0 ? "block" : "hidden"
                    }`}
                  >
                    {selectedItems.length !== 0 && (
                      <>
                        {selectedItems.map((item, index) => (
                          <p
                            className="bg-grey-25 p-2 text-xs flex items-center gap-x-1 rounded-md"
                            key={index}
                          >
                            {item?.name}
                            <div
                              onClick={() =>
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (thisItem) => thisItem?._id !== item?._id
                                  )
                                )
                              }
                              className="cursor-pointer p-1 rounded-full hover:bg-grey-75 "
                            >
                              <RxCross1 />
                            </div>
                          </p>
                        ))}
                      </>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    {selectedItems.length === 0 ? (
                      <p>No Items added</p>
                    ) : (
                      <p>Add more Items</p>
                    )}
                    <div className="text-lg px-4 border-l-2 font-semibold text-grey-50  ">
                      <IoAddOutline
                        className="cursor-pointer "
                        onClick={() => setShowAddItemsModal((prev) => !prev)}
                      />
                    </div>
                  </div>
                  {/* select modal  */}
                  <div
                    className={` h-[300px] shadow-lg overflow-y-scroll  px-5 w-full absolute -bottom-5 left-0 z-[30] bg-white  ${
                      showAddItemsModal === true ? "block" : "hidden"
                    }`}
                  >
                    <div className="flex  flex-col gap-y-3 my-3">
                      {allItems.map((item) => (
                        <p
                          key={item?._id}
                          className="w-full hover:bg-grey-25 cursor-pointer"
                          onClick={() => {
                            setSelectedItems((prev) => [...prev, item]);
                            setShowAddItemsModal(false);
                          }}
                        >
                          {item?.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {attachements && (
            <>
              <div className="flex flex-col gap-y-2 w-[18rem]">
                <label
                  htmlFor="attachements"
                  className="flex flex-col  font-semibold text-xl text-grey-50"
                >
                  Attachments
                </label>
                <input
                  type="file"
                  onChange={(e) => addAttachment(e)}
                  className="bg-white rounded-lg w-full p-3 px-4 "
                  placeholder="Select"
                  accept=".jpg, .jpeg, .png, .webp, .pdf, .csv"
                />
              </div>
            </>
          )}

          {expenseID && (
            <>
              {/* expenseID  */}
              <label className="flex flex-col gap-y-3 font-semibold text-xl text-grey-50 relative">
                Expense ID
                <input
                  type="text"
                  name="expenseID"
                  id="expenseID"
                  className="w-[37rem] h-[48px]  bg-white  text-black  rounded-md  px-2 py-1 font-normal text-sm"
                  {...register("expenseID", {
                    required: {
                      value: true,
                      message: "Please enter your expenseID",
                    },
                  })}
                  //   defaultValue={user?.additionalDetails?.dateOfBirth}
                />
                {errors.expenseID && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    {errors.expenseID.message}
                  </span>
                )}
              </label>
            </>
          )}

          {paymentTerms && (
            <>
              <div className="flex flex-col gap-y-2 w-[37rem] relative">
                <label
                  htmlFor="paymentTerm"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                <p className="flex items-center">Payment Term <sup className="text-red-25">*</sup>
                </p>
                <sup className="font-extralight  italic text-black text-opacity-60 text-xs  ">Select the amount you want to Pay in advance ,and pay rest of the amount after 60days.  </sup>
                </label>

                <div className="flex items-center justify-between ">
               <label className="flex items-center gap-x-2">
               <input type="radio" name="paymentTerm" value="15" className="accent-green-5 checked:bg-green-5 checked:outline-none " />15%
               </label>

               <label  className="flex items-center gap-x-2" >
               <input type="radio" name="paymentTerm" value="21"  className="accent-green-5 checked:bg-green-5 checked:outline-none "/>21%
               </label>

               <label  className="flex items-center gap-x-2" >
               <input type="radio" name="paymentTerm" value="30"  className="accent-green-5 checked:bg-green-5 checked:outline-none "/>30%
               </label>

               <label  className="flex items-center gap-x-2" >
               <input type="radio" name="paymentTerm" value="45"  className="accent-green-5 checked:bg-green-5 checked:outline-none "/>45%
               </label>
                </div>

              </div>
            </>
          )}

          {note && (
            <>
              <div className="flex flex-col gap-y-2 w-[37rem] relative">
                <label
                  htmlFor="note"
                  className="flex flex-col gap-y-2 font-semibold text-xl text-grey-50"
                >
                  Note
                </label>

                <textarea
                  rows="6"
                  cols="20"
                  name="note"
                  id="note"
                  className="  text-black  rounded-md  px-2 py-4 w-[20rem]"
                  placeholder="Write Your Note....."
                  {...register("note", {
                    required: {
                      value: false,
                      message: "Please enter your Note",
                    },
                  })}
                />
                {errors.note && (
                  <span className="-mt-1 text-[12px] text-red-5 absolute -bottom-6 ">
                    {errors.note.message}
                  </span>
                )}
              </div>
            </>
          )}

          <button
            type="submit"
            className="px-8 py-3 bg-green-5 text-white rounded-md mt-6"
          >
            Create
          </button>
        </div>
      </form>
      {showItemsModal && (
        <AddItemModal
          setShowItemsModal={setShowItemsModal}
          itemsList={itemsList}
          setItemsList={setItemsList}
        />
      )}
    </div>
  );
};

export default CreateFormTemplate;
