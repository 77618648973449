import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { createProduct } from "../../services/operations/productAPI";
export const AddItemModal = ({
  itemsList,
  setItemsList,
  setShowItemsModal,
}) => {
  ////////////Dummy Data /////////////
  const locations = [
    "Head Quaters",
    "Back Office",
    "WareHouse",
    "Branch Office",
  ];

  ////////////////////////////////
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const generateSKU = (data) => {
    // SKU format will be itemName + itemQuantity + itemPrice + itemCategory + itemSupplier
    // Extracting the first two characters from each property
    const itemNamePrefix = data.itemName.substring(0, 2);
    const itemCategoryPrefix = data.itemCategory.substring(0, 2);
    const itemSupplierPrefix = data.itemSupplier.substring(0, 2);

    // Extracting numerical values and converting to strings
    const itemQuantityStr = String(data.itemQuantity);
    const itemPriceStr = String(data.itemPrice);

    // Concatenating the prefixes and values to create SKU
    const SKU =
      itemNamePrefix +
      itemCategoryPrefix +
      itemSupplierPrefix +
      itemQuantityStr +
      "-"+
      itemPriceStr;

    return SKU;
  };
  const onAdd = async(data) => {
    // console.log(data);
     //create data for backend
     const newData = {};
     newData.name = data.itemName;
     newData.sku = generateSKU(data);
     newData.category = data.itemCategory;
     newData.quantity = data.itemQuantity;
     newData.supplier = data.itemSupplier;
     newData.price = data.itemPrice;
     newData.description ="";
 
     setLoading(true);
     //backend call
     const response = await dispatch(createProduct(newData))
     setLoading(false);

    if (itemsList.length === 0) {
      let tempItemList = [];
      tempItemList.push(response);
      setItemsList(tempItemList);
    } else {
      setItemsList([...itemsList, response]);
    }
   
    setShowItemsModal(null);
  };

  return (
    <div className="w-full h-full rounded-lg bg-grey-100 bg-opacity-40 absolute top-0 left-0 flex justify-center items-center ">
      <div className="w-[90%] bg-white rounded-lg border-2 border-grey-100 pb-4">
        <div className="w-full justify-between items-center p-2 border-b text-grey-100 flex">
          <p className="font-semibold">Add Item</p>
          <button onClick={() => setShowItemsModal(null)} disabled={loading}>
            <RxCross1 className="text-grey-100" />
          </button>
        </div>
        <form onSubmit={handleSubmit(onAdd)}>
          <div className="w-full flex items-center p-4 gap-x-[5%]  ">
            <label className="flex flex-col gap-y-2 relative w-[45%]">
              Item Name
              <input
                id="itemName"
                placeholder="Enter ItemName"
                className=" bg-white text-black outline outline-1 outline-grey-100 rounded-md text-sm p-2"
                {...register("itemName", { required: true })}
              />
              {errors.itemName && (
                <span className="absolute -bottom-4 left-1 text-xs text-red-5">
                  Please Enter Item Name{" "}
                </span>
              )}
            </label>

            <label className="flex flex-col gap-y-2 relative w-[45%]">
              Item Quantity
              <input
                type="number"
                id="itemQuantity"
                placeholder="Enter Item Quantity"
                className=" bg-white text-black outline outline-1 outline-grey-100 rounded-md text-sm p-2 appearance-none"
                {...register("itemQuantity", { required: true })}
              />
              {errors.itemQuantity && (
                <span className="absolute -bottom-4 left-1 text-xs text-red-5">
                  Please Enter Item Name{" "}
                </span>
              )}
            </label>
          </div>

          <div className="w-full flex items-center p-4 gap-x-[5%]  ">
            <label className="flex flex-col gap-y-2 relative w-[45%]">
              Item Price
              <input
                type="number"
                id="itemPrice"
                placeholder="Enter Item Price"
                className=" bg-white text-black outline outline-1 outline-grey-100 rounded-md text-sm p-2 appearance-none"
                {...register("itemPrice", { required: true })}
              />
              {errors.itemPrice && (
                <span className="absolute -bottom-4 left-1 text-xs text-red-5">
                  Please Enter Item Name{" "}
                </span>
              )}
            </label>

            <div className="flex flex-col gap-y-2 relative w-[45%] text-black">
              <label htmlFor="supplier" className="flex flex-col gap-y-2">
                Supplier
              </label>

              <select
                type="text"
                name="itemSupplier"
                id="itemSupplier"
                className=" bg-white text-black outline outline-1 outline-grey-100 rounded-md text-sm p-2"
                {...register("itemSupplier", { required: true })}
                // defaultValue={user?.additionalDetails?.gender}
              >
                <option value="">Choose a Supplier</option>
                {locations.map((ele, i) => {
                  return (
                    <option
                      key={i}
                      value={ele}
                      className="bg-white hover:text-green-5"
                    >
                      {ele}
                    </option>
                  );
                })}
              </select>
              {errors.itemSupplier && (
                <span className="-mt-1 text-xs text-red-5 absolute -bottom-6 ">
                  Please enter your Supplier.
                </span>
              )}
            </div>
          </div>

          <div className="w-full flex items-center p-4 gap-x-[5%]  ">
            <label className="flex flex-col gap-y-2 relative w-[45%]">
              Item Category
              <input
                id="itemCategory"
                placeholder="Enter Item category"
                className=" bg-white text-black outline outline-1 outline-grey-100 rounded-md text-sm p-2"
                {...register("itemCategory", { required: true })}
              />
              {errors.itemCategory && (
                <span className="absolute -bottom-4 left-1 text-xs text-red-5">
                  Please Enter Item Name{" "}
                </span>
              )}
            </label>
          </div>

          <button
            type="submit"
            className="mt-3 ml-4 bg-green-5 text-white rounded-md p-2 text-[14px]"
          >
            Add Item
          </button>
        </form>
      </div>
    </div>
  );
};
