import React from "react";
import { Outlet } from "react-router-dom";


const Blank = () => {

  return (
    <div className=" w-[calc(screen-280px)] absolute  top-[6.5rem] left-[0px]  bg-grey-25 min-h-screen h-fit">
      <Outlet />
    </div>
  );
};

export default Blank;
