import React from 'react'
import { useParams } from 'react-router-dom'
import * as Icons from "react-icons/md"
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

export const CommonMessage = ({heading , message}) => {
    const { param1, param2 ,param3 } = useParams();
    let paramOne = param1.replace(":" , "").replaceAll("-"," ")
    let paramTwo = param2.replace(":" , "").replaceAll("-"," ")
    let paramThree = param3.replace(":" , "").replaceAll("-","")
    const Icon = Icons[paramThree]
  return (
    <div className="absolute top-0 left-0 w-screen h-screen flex justify-center items-center bg-grey-25  z-20">
        <div className="min-h-[70%] min-w-[40%] flex flex-col justify-center items-center gap-y-6 px-6 py-8 rounded-2xl bg-white shadow-md">

        <Link to={"/"}>
          <div className='w-full flex justify-center items-center'>
                <div className=' flex  items-center mt-1 relative'>
                <FaCaretLeft className='text-4xl  text-green-5 font-semibold absolute top-0 right-5'/>
                <FaCaretRight className='text-4xl text-green-5 '/>
                </div>
                <p className='text-4xl font-extrabold text-green-5 tracking-[0.1em] '>Procura<span className='font-extralight'>Flow</span></p>
            </div>
          </Link>
            <div className='flex items-center gap-x-3'>
            <Icon className="text-green-5  text-3xl" />
            <h2 className=" text-3xl text-black font-semibold capitalize font-inter  ">{paramOne}</h2>
           
            </div>
            <p className="text-lg text-black font-inter capitalize">{paramTwo}</p>
            
        </div>
    </div>
  )
}
