import React from 'react'
import { TotalSpentChart } from './TotalSpentChart'
import { TotalSpentBySupplier } from './TotalSpentBySupplier'

export const AdminDahboard = () => {
  return (
    <div className="w-[calc(100vw-280px)] min-h-screen py-10 ">
    

    <div className='w-[90%] mx-auto flex items-start gap-x-5 min-h-[500px]'>
       <div className='w-[45%] p-5 bg-white rounded-md min-h-[480px]'>
        <TotalSpentChart/>
       </div>

       <div className='w-[45%] p-5 bg-white rounded-md min-h-[480px]'>
        <TotalSpentBySupplier/>
       </div>
    </div>
    </div>
  )
}
