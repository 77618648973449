import React from 'react'
import { useForm } from 'react-hook-form'


export default function Settings() {
    const {
        register,
        handleSubmit,
        formState:{errors},
        setValue,
        getValues
    } = useForm()
  return (
     <div className='w-[75%] h-fit '>

        <form className='w-[50%] mx-auto flex flex-col gap-y-0 ' onSubmit={handleSubmit(() => {})}>
           <label className='lable-style'>
            Name
            <input
             type='text' 
             id='name'
             name='name'
             placeholder='Please Enter your Name'
             {...register("name" , {required:true})}
             className='input-style'/>
             {
                errors.name && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Name</span>)
             }
            </label> 

            <label className='lable-style'>
            Email
            <input
             type='email' 
             id='email'
             name='email'
             placeholder='Please Enter your Email'
             {...register("email" , {required:true})}
             className='input-style'/>
             {
                errors.email && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Name</span>)
             }
            </label> 

            <label className='lable-style'>
            Legal Name
            <input
             type='text' 
             id='companyName'
             name='companyName'
             placeholder='Please Enter Company Name'
             {...register("companyName" , {required:true})}
             className='input-style'/>
             {
                errors.companyName && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter  Legal Name</span>)
             }
            </label> 

            <label className='lable-style'>
            Phone
           <div className='bg-white px-16 rounded-md border-2 border-grey-75 relative'>
           <input
             type='number' 
             id='number'
             name='number'
             placeholder='Please Enter your number'
             {...register("number" , {required:true})}
             className=' text-black  placeholder:text-grey-75 p-2 focus:outline-none remove-arrow outline-none border-none  font-normal '/>

              <select className='country-code text-black ' defaultValue={"+91"}>
                    <option value={"+91"} className='px-10'>+91</option>
                </select>
           </div>
         
             {
                errors.number && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Number</span>)
             }
            </label> 

            <label className='lable-style'>
            Business Registration Number
            <input
             type='text' 
             id='companyGST'
             name='companyGST'
             placeholder='Please Enter GST'
             {...register("companyGST" , {required:true})}
             className='input-style'/>
             {
                errors.companyGST && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter GST</span>)
             }
            </label> 

            <label className='lable-style'>
            Legal Address
            <textarea
             type='text' 
             id='companyAddress'
             name='companyAddress'
             placeholder='Please Enter your Address'
             {...register("companyAddress" , {required:true})}
             className='input-style'/>
             {
                errors.companyAddress && (<span className='absolute -bottom-5 left-1 text-xs text-red-5'>Please Enter Address</span>)
             }
            </label> 
           
        </form>
     </div>
    
  )
}
