import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../slices/purchasesSlice";

export const Approve = ({approved , linkedto}) => {
  const {totalPurchases , filters} = useSelector((state) => state.purchases);
  const totalApprovedRequest = totalPurchases.filter((item) => item.status === "Approved");
   const dispatch = useDispatch();

   const isFilterPresent = (filterArray) => {
    for (let i=0 ; i<filters.length ; i++){
      if(filters[i].type !== filterArray[i].type && filters[i].value !== filterArray[i].value){
        return false;
      }
    }
    return true;
   }
  const applyApprovedFilter = () => {
    const value ="Approved"
    const filterArray = [{type:"status" , value}];
    if(filters.length === 1 && isFilterPresent(filterArray) ){
      dispatch(setFilters([]));
    }else{
      // set this filter 
      dispatch(setFilters(filterArray));
    }
      

  }
  return (
    <Link>
     <div className=" px-5 py-[11px] bg bg-white rounded-lg flex items-center justify-center gap-x-3 shadow-lg"
      onClick={applyApprovedFilter}>
      <div className=" h-10 w-10 rounded-full flex justify-center items-center bg-grey-25">
        <p className="text-black text-lg">{totalApprovedRequest.length}</p>
      </div>
      <p className="text-lg ">Approved</p>
    </div>
  </Link>
  )
}