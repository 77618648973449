import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { RxCross1 } from 'react-icons/rx';
import { addLocation, editLocation, removeLocation } from '../../../../../services/operations/locationAPI';
import toast from 'react-hot-toast';
import { RiDeleteBin6Line } from "react-icons/ri";


export const LocationModal = ({setShowLocationModal, mode , location , setPurchaseUpdated}) => {
    const {
        register,
        handleSubmit ,
        formState:{errors},
        setValue , 
        getValues
    } = useForm();

    const addLocationFunc = async(data) => {
        if(mode === "Create"){
        const response = await addLocation({name:data.locationName , address:data.locationAddress});
        if(response === true){
             setValue("locationName" , "");
             setValue("locationAddress" , "");
             setShowLocationModal(false);
            setPurchaseUpdated((prev) => !prev);
        }
        }else if(mode === "Edit"){
            if(isAddressUpdated()){
                const response = await editLocation({name:data.locationName , address : data.locationAddress} , location._id);

                if(response){
                    setValue("locationName" , "");
                    setValue("locationAddress" , "");
                    setPurchaseUpdated((prev) => !prev);
                    setShowLocationModal(false);
                }
            }else{
                toast.error("Nothing Updated")
            }
        }
    }

    useEffect(() => {
        if(mode === "Edit") {
            setValue("locationName" , location.name);
            setValue("locationAddress" , location.address);
        }
    },[]);

    const isAddressUpdated = () => {
        const currentValues = getValues();
        if(currentValues.locationName !== location.name || currentValues.locationAddress !== location.address  ){
            return true;
        }else{
            return false;
        }
    }

    const deleteLocation = async() => {
             const response = await removeLocation(location._id);

             if(response){
                setValue("locationName" , "");
                setValue("locationAddress" , "");
                setShowLocationModal(false);
               setPurchaseUpdated((prev) => !prev);
             }
    }
  return (
    <div className='absolute -top-10 left-0 min-h-[400px] h-fit z-30 w-full flex justify-center '>
        <div className='bg-grey-25 rounded-md min-w-[50%] min-h-[400px] p-5'>
             <div className='w-[90%] mx-auto flex justify-between items-center'>
             {
                mode === "Create" ? (<p className='text-xl font-semibold font-inter text-black text-opacity-90'>Add Location</p>) : mode === "Edit" ? (<p  className='text-xl font-semibold font-inter text-black text-opacity-90'>Edit Location</p>) : (<p  className='text-xl font-semibold font-inter text-black text-opacity-90'>View Location</p>)
              } 

           <div onClick={() => setShowLocationModal(false)} className='p-2 rounded-full hover:bg-grey-75'>
                <RxCross1 className='text-xl cursor-pointer'/>
              </div>
             </div>

              <div className='w-[100%] mx-auto my-5 p-2 py-4 rounded-md bg-grey-75'>
                <form onSubmit={handleSubmit(addLocationFunc)} className='gap-y-5 flex flex-col'>
                        <label className='flex flex-col gap-y-2 font-semibold text-black  text-opacity-70  relative'>
                           <p className='flex items-center gap-x-1'> Location Name <sup className='text-red-5'>*</sup></p>
                            <input
                            id='locationName'
                            name='locationName'
                            placeholder='Enter Name'
                            {
                                ...register("locationName" , {required:true})
                            }
                            className='p-3 rounded-lg text-sm text-black placeholder:text-grey-75 font-normal'
                            />
                            {
                                errors.locationName && (<span className='text-xs text-red-5 absolute -bottom-5 left-1 font-extralight'>
                                    Please Enter Location Name
                                </span>)
                            }
                        </label>

                        <label className='flex flex-col gap-y-2 font-semibold text-black  text-opacity-70  relative'>
                           <p className='flex items-center gap-x-1'> Location Address <sup className='text-red-5'>*</sup></p>
                            <input
                            id='locationAddress'
                            name='locationAddress'
                            placeholder='Enter Address'
                            {
                                ...register("locationAddress" , {required:true})
                            }
                            className='p-3 rounded-lg text-sm text-black placeholder:text-grey-75 font-normal'
                            />
                            {
                                errors.locationName && (<span className='text-xs text-red-5 absolute -bottom-5 left-1 font-extralight'>
                                    Please Enter Location Adress
                                </span>)
                            }
                        </label>
                       <div className='mt-3 w-[90%] mx-auto flex justify-end'>
                        {
                            mode === "Create" ? (<button className='bg-green-5 text-white text-sm rounded-lg px-5 py-2'>Add Location</button>) : mode === "Edit" ? (<button className='bg-green-5 text-white text-sm rounded-lg px-5 py-2'>Edit Location</button>) : (<p></p>)
                        }
                       </div>
                </form>
              </div>

              {
                mode === "Edit" && (<div className='w-[100%] mx-auto flex mt-10 justify-between items-center p-2 rounded-md bg-grey-75'>
                       <p className='text-red-5 font-inter font-semibold '>Remove this Location</p>   
                       <button className='text-lg cursor-pointer text-red-5 p-2 rounded-full hover:bg-grey-100' onClick={deleteLocation}><RiDeleteBin6Line /></button>    
                </div>)
              }
        </div>
    </div>
  )
}

