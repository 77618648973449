import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { inviteUSer } from '../../../../../services/operations/userAPI';

export const EmailModal = ({showEmailModal , loading , setUserAdded}) => {
    const {register , handleSubmit , setValue} = useForm();

    const inviteUser = async(data) => {
       if(data.email === ""){
        return;
       }else{
        const response =  await inviteUSer(data.email);
            setValue('email' , "")
            setUserAdded((prev) => !prev);
       }
          
    }
  return (
    <div className={` absolute top-10 right-0 w-full bg-grey-25 rounded-md shadow-lg h-fit p-7 z-10  ${showEmailModal === true ? "block" : "hidden"}`}>
               <form onSubmit={handleSubmit(inviteUser)}>
                    <label className='w-full flex flex-col gap-y-3 text-black  font-semibold font-inter text-opacity-60 text-sm  '>
                        User Email
                        <input
                           type='email'
                           name='email'
                           id='email'
                           placeholder='hello@gmail.com'
                           className=' p-2 text-xs  text-black focus:outline-none border border-grey-100 font-normal rounded-md  '
                           {...register("email" , {})}
                        />
                    </label>
                   <div className='w-full flex justify-end'>
                   <button type='submit' className='mt-4 text-white bg-green-5 rounded-md shadow-lg px-3 py-1.5 font-semibold text-xs' disabled={loading}>
                        Invite
                    </button>
                   </div>
               </form>
    </div>
  )
}
