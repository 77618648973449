import React, { useEffect, useState } from "react";
import Create from "../admin_common/Create";
import { FilterData } from "../PurchaseRequests/GetPurchase/FilterData";
import { LuIndianRupee } from "react-icons/lu";
import { Link } from "react-router-dom";
import { Approve } from "../admin_common/Approve";
import { OverDue } from "../admin_common/OverDue";

export const Expenses = () => {
  ////////////////////////////Dummy Expenses//////////////
  const invoices = [
    {
      _id: "65be096b12c161c4cb553063",
      createdAt: "2024-02-03T09:37:47.257Z",
      deliveryDate: "2024-02-29",
      department: "Marketing",
      items: [
        {
          _id: "65be095212c161c4cb553060",
          category: "Electronics",
          createdAt: "2024-02-03T09:37:22.940Z",
          description: "",
          name: "TV",
          price: "10000",
          quantity: "1",
          sku: "TVElHe1-10000",
          supplier: "Head Quaters",
          updatedAt: "2024-02-03T09:37:22.940Z",
          user: "65a7980a6cd70ff033b955ec",
        },
      ],
      location: "Back Office",
      note: "Need a TV for lobby",
      references: "amazon.in",
      status: "Draft",
      updatedAt: "2024-02-03T09:37:47.257Z",
      user: "65a7980a6cd70ff033b955ec",
    },
  ];
  /////////////////////////////////////////////////////////
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLaoding] = useState(false);
  const  expenseId = filteredData._id;

  useEffect(() => {
    setFilteredData(invoices);
    setData(invoices);
  }, []);
  return (
    <div className="w-[calc(100vw-280px)] min-h-screen flex flex-col gap-y-10 py-10">
      <div className="flex items-center gap-x-5 w-[95%] mx-auto">
        <Approve approved={"3"} linkedto={`/admin/expenses/update-expense/${expenseId}`}/>
        <OverDue overdues={"4"} linkedto={`/admin/expenses/update-expense/${expenseId}`} />
        <Create linkto={"/admin/expenses/create-expense"} />
      </div>

      {loading === false ? (
        <div className="w-[95%] mx-auto">
          {!filteredData.length ? (
            <p>No Data Found</p>
          ) : (
            <div className="flex flex-col gap-y-10">
              {/* filter component  */}
              <div>
                <FilterData
                  data={data}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                />
              </div>

              {/* data table  */}
              <div>
                <table className="w-full rounded-lg">
                  <thead className="text-grey-50 text-opacity-85 bg-grey-75 h-[35px] py-4 text-center">
                    <tr className=" font-[200]">
                      <th>No.</th>
                      <th>Item Name</th>
                      <th>Request ID</th>
                      <th>Quantity</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody className="bg-grey-5 text-center">
                    {filteredData.map((item, index) => (
                      <tr
                        key={index}
                        className="h-[55px] font-inter font-extralight text-opacity-50 text-black"
                      >
                        <td>{`${
                          index < 10 ? `0${index + 1}` : `${index + 1}`
                        }`}</td>
                        <td>{item?.items[0]?.name}</td>
                        <td>{item?._id}</td>
                        <td>{item?.items[0]?.quantity}</td>
                        <td onClick={() => {}}>
                          <Link
                            to={`${
                              item?.status === "Draft"
                                ? `/admin/expenses/update-expense/${item?._id}`
                                : item?.status === "ToBePaid"
                                ? `/admin/expenses/update-expense/${item?._id}`
                                : `/admin/expenses/update-expense/${item?._id}`
                            }`}
                          >
                            <p
                              className={` rounded-2xl  text-sm py-1 flex items-center justify-center ${
                                item?.status === "Draft"
                                  ? "bg-blue-5 bg-opacity-30 text-blue-5"
                                  : item.status === "Pending"
                                  ? " bg-orange-5 bg-opacity-30 text-orange-5"
                                  : "bg-blue-5 bg-opacity-30 text-blue-5"
                              }`}
                            >
                              {item?.status}
                            </p>
                          </Link>
                        </td>
                        <td>{item?.createdAt.split("T").at("0")}</td>
                        <td>
                          <p className="flex items-center gap-x-1 justify-center">
                            <LuIndianRupee />
                            {item?.items[0]?.price}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p className="text-center">Loading....</p>
      )}
    </div>
  );
};

