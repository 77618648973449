import React from "react";
import { Link } from "react-router-dom";

const Create = ({linkto}) => {
  return (
    <Link to={linkto}>
      <button className=" px-10 py-[17px] bg-green-5 text-white rounded-lg text-lg shadow-lg ">
        Create
      </button>
    </Link>
  );
};

export default Create;
