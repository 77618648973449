import React, { useEffect } from 'react'
import { sidebarLinks } from "../../../data/sidebarLinks";
import SidebarLink from "./SidebarLink";
import { useDispatch, useSelector } from "react-redux";
import { userEndpoints } from "../../../services/apis";
import { apiConnector } from '../../../services/apiConnector';
import { setUser } from '../../../slices/profileSlice';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const user = useSelector((state) => state.profile);
 
  const dispatch = useDispatch();
  const {GETUSER_API} = userEndpoints;
  const getUser = async () => {
    const response = await apiConnector("GET" , GETUSER_API , {user})
    // console.log("GETUSER_API_RESPONSE -" ,response)
    return response;
  };
  useEffect(() => {
    const fetchData = async() => {
      // console.log("before" ,user)
      const response =  await getUser()
      // console.log("response " , response)
      dispatch(setUser(response?.data))
      
      // console.log(after , user)
    }
    fetchData();
  },[]);
  return (
    <>
      <div className="flex min-h-[100vh] h-full min-w-[280px] flex-col border-r-[1px]  bg-richblack-5 py-10 fixed top-0 left-0">
        <div className=" my-4 flex justify-center items-center mb-8">
          <Link to="/">
          <p className="text-2xl font-extrabold text-white tracking-[0.1em] font-inter">
            Procura<span className="font-extralight">Flow</span>
          </p>
          </Link>
        </div>
        <div className="flex flex-col">
          {sidebarLinks.map((link) => {
            if (link.type && user?.user?.role !== link.type) return null;
            return (
              <SidebarLink key={link.id} link={link} iconName={link.icon} />
            );
          })}
        </div>
        <div className="mx-auto mt-6 mb-6 h-[1px] w-10/12 bg-richblack-700" />
        <div className="flex flex-col">
          <SidebarLink
            link={{ name: "Settings", path: "/admin/settings" }}
            iconName="FaExchangeAlt"
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
