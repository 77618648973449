import { setLoading } from "../../slices/authSlice";
import { authEndpoints, userEndpoints } from "../apis";
import toast from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { setToken } from "../../slices/authSlice";
import {setUser} from "../../slices/profileSlice"

const {
SIGNUP_API,
LOGIN_API,
RESET_PASSWORD_TOKEN_API,
RESET_PASSWORD_API
} = authEndpoints

const {
  INVITE_USER_API,
  REGISTER_USERS_API,
  GET_ALL_USERS_API
} = userEndpoints


export function signUp(
    formdata,
    navigate
  ) {
    return async (dispatch) => {
      const toastId = toast.loading("Loading...");
      dispatch(setLoading(true));
      try {
        
        const response = await apiConnector("POST", SIGNUP_API, {
          name:formdata.name,
          email:formdata.email,
          password:formdata.password,
          phone:formdata.phone,
          organisation:formdata.organisation
        });
  
        console.log("PRINTING SIGNUP RESPONSE : ", response);
      
        if (!response.data.success) {
          throw new Error(response.data.message);
        }
  
        toast.success("Singned Up");
        dispatch(setToken(response?.data?.user?.token))
        dispatch(setUser(response?.data?.user))
        localStorage.setItem("token", JSON.stringify(response?.data?.user?.token))
        localStorage.setItem("user", JSON.stringify(response?.data?.user))
         navigate("/admin/dashboard");
      } catch (error) {
        console.log("SIGNUP API ERROR............", error);
        toast.error("Signup Failed");
        navigate("/signup");
      }
      toast.dismiss(toastId);
      dispatch(setLoading(false));
    };
  }

  export function logIn(
    data,
    navigate
  ) {
    return async (dispatch) => {
      const toastId = toast.loading("Loading...");
      dispatch(setLoading(true));
      try {
        
        const response = await apiConnector("POST", LOGIN_API , {
          email:data.email,
          password:data.password,
        });
  
        console.log("PRINTING LOGIN RESPONSE : ", response);
      
        if (!response.data.success) {
          throw new Error(response.data.message);
        }
  
        toast.success("Logged In");
        dispatch(setToken(response?.data?.user?.token))
        dispatch(setUser(response?.data?.user))
        localStorage.setItem("token", JSON.stringify(response?.data?.user?.token))
        localStorage.setItem("user", JSON.stringify(response?.data?.user))
         navigate("/admin/dashboard");
      } catch (error) {
        console.log("LOGIN API ERROR............", error);
        toast.error("Login Failed");
        navigate("/login");
      }
      toast.dismiss(toastId);
      dispatch(setLoading(false));
    };
  }

  export async function inviteUSer(email){
    const toastId = toast.loading("Loading...")
    try{
      const response = await apiConnector("POST" , INVITE_USER_API , {email} );

      if(!response.data.success){
        throw new Error("Could not invite User")
      }

      toast.success("User invited successfully")
      console.log("INVITE_USER_API_RESPONSE - " , response);
    }catch(error){
           console.log("INVITE_USER_API_RESPONSE - " , error);
           toast.error("Could not invite user");
    }
    toast.dismiss(toastId);
  }

  export async function addMultipltUsers(users){
    const toastId = toast.loading("Loading...")
    try{
      console.log("data - ",users)
      const response = await apiConnector("POST" , REGISTER_USERS_API , {users} );
  
      if(!response.data.success){
        throw new Error("Could not add users")
      }

      toast.success("Users added successfully")
      console.log("REGISTER_USERS_API_RESPONSE - " , response);
    }catch(error){
           console.log("REGISTER_USERS_API_RESPONSE - " , error);
           toast.error("Could not add users");
    }
    toast.dismiss(toastId);
  }

  export async function getAllUsers(){
    // const toastId = toast.loading("Loading");
    let result =[];
    try{
        const response = await apiConnector("GET" , GET_ALL_USERS_API  );
        if(!response.data.success){
          throw new Error("Cannot get users");
        }

        console.log("GET_ALL_USERS_API_RESPONSE - " , response);
        result = response.data.users;
    }catch(error){
      console.log("GET_ALL_USERS_API_RESPONSE - " , error);
      toast.error("Cannot get users")
    }
    // toast.dismiss(toastId);
    return result;
  }

  export function logout(navigate) {
    return (dispatch) => {
      dispatch(setToken(null))
      dispatch(setUser(null))
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      toast.success("Logged Out")
      navigate("/")
    }
  }

  export function getResetPasswordToken(email , setSentEmail) {
       return async(dispatch) => {
           dispatch(setLoading(true)) 
           try{
 
            const response = await apiConnector("POST" , RESET_PASSWORD_TOKEN_API , {
              email
            })
            console.log("RESET_PASSWORD_TOKEN_API_RESPONSE - " , response)

            if(!response.data.success){
              throw new Error("Cannot reset Password ,Try Again")
            }

            toast.success("Reset Email Sent")
            setSentEmail(true)
           }catch(error){
                console.log("RESET_PASSWORD_TOKEN_API_RESPONSE -" , error)
                toast.error("Failed to sent email for reseting password")
           }
           dispatch(setLoading(false));

       }
  }

  export function resetPassword(password, confirmPassword ,resetToken , navigate){
    return async(dispatch) => {
      dispatch(setLoading(true))
      try{
          // const URL = `${RESET_PASSWORD_API}${resetToken}`;
          // console.log(URL)
          const response = await apiConnector("PUT" , RESET_PASSWORD_API , 
          {password , confirmPassword , resetToken})

          console.log("RESET_PASSWORD_API_RESPONSE - " , response )

          if(!response.data.success){
            throw new Error ("Cannot Reset Password")
          }

          toast.success("Password Reset Successfully")
          navigate("/common-message/:reset-password-successfully/:please-login-again/:MdVerified")
      }catch(error){
       console.log("RESET_PASSWORD_API_RESPONSE - " , error)
       toast.error("Cannot reset password")

      }
      dispatch(setLoading(false))
    }
  }