import React from 'react'
import { Link } from 'react-router-dom'
const Home = () => {
  return (
    <div className=' absolute top-[6.5rem] left-[0px] w-screen  bg-grey-25 min-h-screen flex  justify-center pb-10 '>
      <Link to={"/admin/dashboard"}><p className='bg-green-5 text-white px-3 py-2 rounded-lg mt-4'>Go to Admin Panel</p></Link>

      
    </div>
  )
}

export default Home