import React, { useEffect, useState } from "react";
import { UpdateModal } from "./UpdateModal";
import { useSelector } from "react-redux";
import { BiRupee } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { IoIosLink } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { getPurchases } from "../../../../../services/operations/purchaseAPI";
import { DownloadRequestModal } from "./DownloadStatus/DownloadRequestModal";



export const UpdatePurchaseRequest = ({confirmationModal , setConfirmationModal}) => {
  // getting status and item_id to updata 
  let {status , item_id}  = useParams();
  status = status.split("-").at("0").charAt(0).toUpperCase() + status.split("-").at("0").slice(1);

  const [loading  ,setLoading]  = useState(false);
  const[data, setData] = useState([]);
  const[filteredData , setFilteredData] = useState([]);
  const [updateModalData , setUpdateModalData] = useState([]);
  const [showPerviewModal , setShowPreviewModal] = useState(null);
  const[downloadType , setDownloadType] = useState("");
  
  
  const getAllPurchases = async () => {
    const tempData = await getPurchases();
    let filteredPurchases = tempData.filter((item) => item.status === status);
    const purchaseToUpdate =  filteredPurchases.filter((item) => item._id === item_id);
    // console.log(purchaseToUpdate);
    setUpdateModalData(purchaseToUpdate[0])
    setData(tempData);
    setFilteredData(filteredPurchases);
  };

  useEffect(() => {
    setLoading(true);
    getAllPurchases();
    setLoading(false);
  },[])
  
  return (
    <div className=" w-[calc(100vw-280px)] h-fit bg-grey-25 min-h-screen relative">
    <div className="z-10">
    {
      loading ? (<p>Loading...</p>) : filteredData.length !== 0 ? (
        <div className=" w-[calc(100vw-280px)] mx-auto flex flex-col items-center gap-x-4   ">
      
        <div className="w-full">
          <UpdateModal updateModalData = {updateModalData} setFilteredData={setFilteredData} setUpdateModalData={setUpdateModalData}/> 
        </div>
  
          <div className="w-[95%] mx-auto flex justify-end pl-30 relative">
              {
                  status === "Completed" ? (
                  <div>
                         <p className="text-lg font-inter">Download Attachements</p>
                         <div className="flex items-center gap-x-4 mt-4">
                          <button className="flex flex-row-reverse  items-center gap-x-1 rounded-2xl shadow-lg bg-grey-5 px-5 py-1" onClick={() => {
                            setShowPreviewModal(true);
                            setDownloadType("PDF")
                          }}>
                              <FiDownload/>
                              <p>PDF</p>
                          </button>
  
                          <button className="flex flex-row-reverse items-center gap-x-1 rounded-2xl shadow-lg bg-grey-5 px-5 py-1" 
                          onClick={() => {
                            setShowPreviewModal(true);
                            setDownloadType("CSV")
                          }}
                          >
                              <FiDownload/>
                              <p>CSV</p>
                          </button>
                          </div>
                  </div>) : 
                  (<div>
                      <p className="text-lg font-inter">Upload Attachments</p>
                      <button className="flex   items-center gap-x-1 rounded-2xl shadow-lg bg-grey-5 px-10 py-1 mt-4">
                              <IoIosLink />
                              <p>Upload Attachments</p>
                          </button>
                  </div>)
              }
                   {showPerviewModal && (<DownloadRequestModal updateModalData={updateModalData}  setShowPreviewModal={setShowPreviewModal} downloadType={downloadType} />)}
          </div>
  
          {/* table  */}
          <div className="mt-10 rounded-lg w-full  overflow-hidden py-3">
            <table className="w-[95%] mx-auto shadow-md ">
              <thead className="bg-grey-75 h-[30px] font-inter text-opacity-50  text-black">
                  <tr><th>No.</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>Total</th>
                <th>Category</th>
                <th>Supplier</th></tr>
              </thead>
       
              <tbody className="bg-grey-5 text-center">
                  {
                      filteredData.map((item, index) => (
                          <tr key={item?._id}
                          onClick={() => setUpdateModalData(item)}
                          className=" h-[40px] font-inter text-black text-opacity-65 cursor-pointer">
                                <td>{index < 10 ? `0${index+1}` : `${index+1}`}</td>
                                <td>{item?.items[0]?.name}</td>
                                <td>{item?.items[0]?.quantity < 10 ? `0${item?.items[0]?.quantity}` : `${item?.items[0]?.quantity}`}</td>
                                <td><p className="flex items-center justify-center gap-x-0">
                                  <BiRupee />
                                  {item?.items[0]?.price}
                                  </p></td>
                                <td>
                                  <p className="flex items-center justify-center gap-x-0">
                                  <BiRupee />
                                  {`${item?.items[0]?.quantity * item?.items[0]?.price}`}
                                  </p>
                                </td>
                                <td>{item?.items[0]?.category}</td>
                                <td>{item?.items[0]?.supplier}</td>
  
                          </tr>
                      ))
                  }
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className=" text-2xl text-black flex justify-center items-center text-opacity-70 font-semibold w-[calc(100vw-280px)]  bg-grey-25 min-h-[calc(100vh-6.5rem)] ">No Data Found</div>
      )
    }
    </div>
    </div>
  );
};
