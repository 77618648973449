import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Papa from "papaparse"
import { addMultipltUsers } from "../../../../../services/operations/userAPI";
export const ImportModal = ({ showImportModal  , loading , setUserAdded}) => {
  const { register, handleSubmit, setValue } = useForm();
  const [selectedFile , setSelectedFile] = useState([]);

  const addAttachment = (e) => {
    const file = e.target.files[0];
    let users;
      //parse CSV data and update thse selectedFile state
    Papa.parse(file , {
        header:true,
        complete:(results) => {
          setSelectedFile(results.data);
        }
    });

  
  }
  const importUsers = async () => {
     if(selectedFile.length === 0){
        return;
     }else{
      setSelectedFile(selectedFile.pop());
       await addMultipltUsers(selectedFile);
       setUserAdded((prev) => !prev);
     }
  };
  return (
    <div
      className={` absolute top-10 right-0 w-full bg-grey-25 rounded-md shadow-lg h-fit p-5 z-10  ${
        showImportModal === true ? "block" : "hidden"
      }`}
    >
      <form onSubmit={handleSubmit(importUsers)}>
        <label className="w-full flex flex-col gap-y-3 text-black  font-semibold font-inter text-opacity-60 text-sm  ">
          Select File
          <input
            type="file"
            onChange={(e) => addAttachment(e)}
            className="bg-white rounded-lg w-full p-1 text-xs"
            placeholder="Select"
            accept=".csv , .xlsx"
          />
        </label>
        <div className="w-full flex justify-end">
          <button
            type="submit"
            className="mt-4 text-white bg-green-5 rounded-md shadow-lg px-3 py-1.5 font-semibold text-xs"
            disabled={loading}
          >
            Import 
          </button>
        </div>
      </form>
    </div>
  );
};
