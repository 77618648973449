import React, { useEffect, useRef, useState } from "react";
import { getPurchases } from "../../../../../../services/operations/purchaseAPI";
import { MdError } from "react-icons/md";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import { getPurchaseOrders } from "../../../../../../services/operations/purchaseOrderAPI";

export const DownloadPurchaseOrders = () => {
  const pdfRef = useRef();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllPurchases = async () => {
    const tempData = await getPurchaseOrders();
    setData(tempData);
    // setFilteredData(tempData);
    // console.log(data);
    return tempData;
  };

  useEffect(() => {
    // getPurchases
    setLoading(true);
    getAllPurchases();
    setLoading(false);
  }, []);

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("PO.pdf");
    });
  };

  function mapObjectsToList(data) {
    const mappedList = [];

    data.forEach((item, index) => {
      const mappedItem = {
        itemNo: index + 1,
        itemStatus: item.status,
        location: item.location,
        supplier: item.supplier,
        price: item?.items[0]?.price * item?.items[0]?.quantity,
        deliveryDate: item.deliveryDate,
      };

      mappedList.push(mappedItem);
    });

    return mappedList;
  }

  const mappedList = mapObjectsToList(data);
  //   console.log(mappedList)

  const headers = [
    { label: "Item No.", key: "itemNo" },
    { label: "Item Status", key: "itemStatus" },
    { label: "Location", key: "location" },
    { label: "Supplier", key: "supplier" },
    { label: "Price", key: "price" },
    { label: "Delivery Date", key: "deliveryDate" },
  ];

  const csvLink = {
    filename: "PO.csv",
    headers: headers,
    data: mappedList,
  };
  return (
    <div className=" w-[calc(100vw-280px)] min-h-screen bg-grey-25 py-10">
      <div className="w-[90%] mx-auto flex justify-end items-center gap-x-3">
        <CSVLink {...csvLink} className="">
          {" "}
          <p className="text-black text-opacity-60 font-semibold cursor-pointer bg-white px-4 hover:bg-green-5 hover:text-white  text-center py-1.5 rounded-md">
            Download as CSV
          </p>
        </CSVLink>

        <p
          className="text-black bg-white text-opacity-60  font-semibold cursor-pointer px-4 hover:bg-green-5  text-center py-1.5 rounded-md hover:text-white "
          onClick={downloadPDF}
        >
          Download as PDF
        </p>
      </div>

      <div className="w-full" ref={pdfRef}>
        <div className="w-[90%] mx-auto">
          <div>
            {loading === true ? (
              <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75">
                Loading...
              </p>
            ) : (
              <>
                {data.length === 0 ? (
                  <p className="w-full bg-grey-5 shadow-lg rounded-lg flex justify-center items-center min-h-[200px] text-black font-inter text-opacity-75 gap-x-2 mt-10">
                    <MdError className="text-2xl  text-opacity-50" />
                    No Purchase Orders Found
                  </p>
                ) : (
                  <>
                    <div>
                      {/* table  */}
                      <div className="w-full min-h-[320px] rounded-lg overflow-hidden mt-10">
                        <table className="w-full rounded-lg">
                          <thead className="text-grey-50 text-opacity-85 bg-grey-75  shadow-lg h-[45px]  py-10 text-center">
                            <tr className=" font-[200]">
                              <th>No.</th>
                              <th>Status</th>
                              <th>Location</th>
                              <th>Supplier</th>
                              <th>Gross {"(Rs.)"}</th>
                              <th>Delivery Date</th>
                            </tr>
                          </thead>
                          <tbody className="bg-grey-5 text-center">
                            {data.map((item, index) => (
                              <tr
                                key={index}
                                className="h-[55px] font-inter font-extralight text-opacity-75 text-black"
                              >
                                <td>{`${
                                  index < 9 ? `0${index + 1}` : `${index + 1}`
                                }`}</td>
                                <td>
                                  <p
                                    className={` bg-none text-sm py-1 w-[80%] mx-auto${
                                      item?.status === "Draft"
                                        ? " text-blue-5"
                                        : item.status === "Pending"
                                        ? "  text-orange-5"
                                        : " text-blue-5"
                                    }`}
                                  >
                                    {item?.status}
                                  </p>
                                </td>
                                <td className="group relative cursor-pointer">
                                  {item.location}
                                </td>
                                <td>{item?.supplier}</td>
                                <td>
                                  {item?.items[0]?.price *
                                    item?.items[0]?.quantity}
                                </td>
                                <td>{item?.deliveryDate}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
