import React from 'react'
import SettingsLinks from "../../../../../data/settingsLinks.json"
import { Link, useLocation } from 'react-router-dom'

export const SettingsSidebar = () => {
  const currentLocation = useLocation();
  // console.log(currentLocation)
  return (
    <div className='w-[25%] flex flex-col gap-y-3 p-3'>
      {
        SettingsLinks.map((link , index) => (
          <Link  key={index} to={link.path}>
             <div className={`w-[95%] mx-auto rounded-md p-2 font-semibold cursor-pointer ${link?.path === currentLocation.pathname ? "bg-green-5 bg-opacity-20 text-green-5 " : " text-black text-opacity-60"}`}> 
             {link?.name}
          </div>
          </Link>
        ) )
      }
    </div>
  )
}
