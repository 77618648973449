import { productEndpoints } from "../apis"
import toast from "react-hot-toast"
import { apiConnector } from "../apiConnector"
const {
    CREATE_PRODUCT_API,
    GET_PRODUCTS_API

} = productEndpoints


export  function createProduct(
    data 
){

    return async(dispatch) => {
        const toastId = toast.loading("Loading...")
        let result = {};
        try{
            const response  = await apiConnector("POST" ,CREATE_PRODUCT_API , data) 

            console.log("CREATE_PRODUCT_API_REPSONSE - " , response )

            if(!response.status === 201){
                throw new Error("Cannot Add Item")
            }
            toast.success("Item Added")
             result  = response.data 
        }catch(error){
            console.log(error)
            toast.error("Cannot Create Item")
        }
        toast.dismiss(toastId)
        // console.log("Result - " , result)
        return result
    }
}

export async function getAllItems() {
    let result = []
 try{
     const response = await apiConnector("GET" , GET_PRODUCTS_API );

     if(!response.data.success){
        throw new Error ("Cannot Get Products")
     }

     console.log("GET_PRODUCTS_API_RESPONSE - " , response);
     result = response?.data?.products
 }catch(error){
    console.log("GET_PRODUCTS_API_RESPONSE - " , error);      
    toast.error("Cannot get Items");  
 }
 return result;
}