import React, { useEffect } from 'react'
import { set, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { updatePurchase } from '../../../../services/operations/purchaseAPI';

export const EditRequesition = ({setEditModal , updateModalData , setUpdateModalData}) => {
   
     ///////////////////////Dummy Data////////////////////////////////
  const locations = [
    "Head Quaters",
    "Back Office",
    "WareHouse",
    "Branch Office",
  ];

  const departments = [
    "Marketing",
    "Administration",
    "Human Resources",
    "Sales",
  ];
  /////////////////////////////////////////////////////////////////
    const {
        register , 
        handleSubmit,
        formState:{errors},
        setValue,
        getValues
    } = useForm();

    useEffect(() => {
          // console.log(updateModalData) 
        setValue("deliveryDate", updateModalData.deliveryDate);
        setValue("location" , updateModalData.location);
        setValue("department" , updateModalData.department);
        setValue("note" , updateModalData.note);
        setValue("references" , updateModalData.references);
    },[])
    
    // check if any changes made to the form 
    const isFormUpdated = () => {
        const currentValues = getValues();
        if(currentValues.deliveryDate !== updateModalData.deliveryDate ||
            currentValues.location !== updateModalData.location ||
            currentValues.department !== updateModalData.department ||
            currentValues.references !== updateModalData.references ||
            currentValues.note !== updateModalData.note
           )
       
                return true;
         else
                  return false;
      }

    //   then edit 
    const editDocument = (data) => {
        if(isFormUpdated()){
            // form is updated call backend 
            console.log(data)
            updateDocument(data);
        }else{
            toast.error("No updates in document")
        }
    }

    // then update 
    const updateDocument =  async (data)=> {
         const response = await updatePurchase(updateModalData._id , data);
        //  console.log(response);
         if(response){
         setUpdateModalData(response);
         setEditModal(null);
         }
    }
  return (
    <div className=' w-[calc(100vw-280px)] h-[100vh] z-[10] bg-black bg-opacity-[1%] absolute -top-10 -left-5 flex items-start pt-[190px] justify-center'>

        <div className='bg-white min-h-[30vh] min-w-[40vw] px-5 pt-5 flex flex-col justify-between rounded-md shadow-lg -translate-y-[25%]  -translate-x-[15%]'>
            <form onSubmit={handleSubmit(editDocument)}>
                <div className='grid grid-cols-2 gap-5'>
                    <label className='lable-style-2'>
                        <p>Delivery Date <sup>*</sup></p>
                        <input 
                        type="date"
                        id='deliveryDate'
                        name='deliveryDate'
                        className='input-style'
                        {...register("deliveryDate" , {required:true})}
                         />
                         {
                            errors.deliveryDate && (<span className='error-style'>Please Enter Delivery Date</span>)
                         }
                    </label>

                    <label className='lable-style-2'>
                        <p>Location <sup>*</sup></p>
                        <select 
                        id='location'
                        name='location'
                        className='input-style'
                        {...register("location" , {required:true})}
                        >
                           {
                              locations.map((location , index) => (
                                <option key={index}>{location}</option>
                              ))
                           }
                         </select>
                         {
                            errors.location && (<span className='error-style'>Please Enter Delivery Date</span>)
                         }
                    </label>

                    <label className='lable-style-2'>
                        <p>References <sup>*</sup></p>
                        <input 
                        type="text"
                        id='references'
                        name='references'
                        className='input-style'
                        {...register("references" , {required:true})}
                         />
                         {
                            errors.references && (<span className='error-style'>Please Enter Reference</span>)
                         }
                    </label>

                    <label className='lable-style-2'>
                        <p>Departments <sup>*</sup></p>
                        <select 
                        id='department'
                        name='department'
                        className='input-style'
                        {...register("department" , {required:true})}
                        >
                           {
                              departments.map((department , index) => (
                                <option key={index}>{department}</option>
                              ))
                           }
                         </select>
                         {
                            errors.department && (<span className='error-style'>Please Enter Delivery Date</span>)
                         }
                    </label>

                    <label className='lable-style-2'>
                        <p>Note <sup>*</sup></p>
                        <textarea 
                        type="date"
                        id='note'
                        name='note'
                        className='input-style min-h-[250px] w-[150%]'
                        {...register("note" ,{})}
                         />
                         {
                            errors.note && (<span className='error-style'>Please Enter Note</span>)
                         }
                    </label>
                </div>

                <div className='my-6 flex items-center gap-x-5 justify-center'>
                    <button className='text-white font-semibold font-inter bg-blue-25 py-1.5 text-sm px-8  rounded-2xl  ' type='submit'>Save </button>
                    <button className='text-red-25 font-semibold font-inter border border-red-25 py-1.5 text-sm px-8  rounded-2xl  ' onClick={() => setEditModal(null)} >Cancel</button>
                </div>
            </form>
        </div>
    </div>
  )
}
