import React from 'react'

export const ConfirmationModal = ({confirmationModal}) => {
    const {heading , description , btn1Text , btn2Text , btn1Handler , btn2Handler} = confirmationModal;
   
  return (
 <div className=' w-[calc(100vw-280px)] h-[100vh] z-[10] bg-black bg-opacity-[1%] absolute -top-10 -left-5 flex items-center justify-center'>
     <div className='bg-white min-h-[30vh] min-w-[30vw] px-5 pt-5 flex flex-col justify-between rounded-md shadow-lg -translate-y-[25%]  -translate-x-[15%]'>
          <div>
               {
                heading && (<p className='text-xl font-semibold font-inter text-blue-55'>{heading}</p>)
               } 

               <p className='text-lg mt-3 font-semibold font-inter text-blue-25'>{description}</p>
          </div>  

          <div className='flex py-5 items-center gap-x-5 justify-center border-t-2 border-t-black border-opacity-20 '>
            <button onClick={() => btn1Handler()} className='bg-blue-25 font-inter text-white px-4 py-1.5 rounded-2xl'>{btn1Text}</button>
            <button onClick={() => btn2Handler()} className='border border-red-25 font-inter text-red-25  py-1 px-6 rounded-2xl'>{btn2Text}</button>
          </div>  
     </div>
 </div>
  )
}
