import React from 'react'
import { Link } from 'react-router-dom'
export const SendToVendor = ({sendtovendors , linkedto}) => {
  return (
    <Link to={linkedto}>
    <div className=" px-6 py-[11px] bg bg-white rounded-lg flex items-center justify-center gap-x-3 shadow-lg ">
      <div className=" h-10 w-10 rounded-full flex justify-center items-center bg-grey-25">
        <p className="text-black text-lg">{sendtovendors}</p>
      </div>
      <p className="text-lg ">Send To Vendor</p>
    </div>
  </Link>
  )
}
