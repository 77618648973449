import React from "react";
import { Link } from "react-router-dom";

export const InventoryModal = ({ inventoryModal }) => {
  return (
    <div className="w-full flex items-start justify-between">
      <div className="w-[60%]">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[180px] h-[90px]">
          <p className="text-xl font-semibold font-inter text-grey-50 text-opacity-85">
            Gross Total
          </p>
          <p className="mt-1 pl-1 font-semibold text-lg">
            {inventoryModal?.itemQuantity * inventoryModal?.itemPrice}.00{" "}
            <sup className="font-semibold">INR</sup>
          </p>
        </div>
      </div>

      <div className="w-[30%] flex flex-col gap-y-3">
        <button className=" w-full py-1 rounded-2xl shadow-lg font-semibold bg-white">
          <Link to={"/admin/inventory/add-stock-balance"}>
            Add Stock Balance
          </Link>
        </button>
        <button className=" w-full py-1 rounded-2xl shadow-lg font-semibold bg-white">
          Update Stock Balance
        </button>
        <button className=" w-full py-1 rounded-2xl shadow-lg font-semibold bg-white">
          Download Stock Balance
        </button>
      </div>
    </div>
  );
};
