import {createSlice} from "@reduxjs/toolkit"


const initialState = {
  page:0,
  totalPages:0,
}

const paginationSlice = createSlice({
    name:"pagination",
    initialState:initialState,
    reducers: {
        setPage(state , value){
            state.page = value.payload;
        },
        setTotalPages(state ,value){
          state.totalPages = value.payload;
        },
    },
});


export const {setPage , setTotalPages} =paginationSlice.actions;
export default paginationSlice.reducer;