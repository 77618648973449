import React, {useState } from "react";
import { FaFilter } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { CgSearch } from "react-icons/cg";
import { DownloadPurchase } from "./DownloadPurchaseRequests/DownloadPurchase";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../../../../slices/purchasesSlice";
import { useForm } from "react-hook-form";

export const FilterData = () => {
  //update this code
  const [showDeliveryDate, setShowDeliveryDate] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showRequester, setShowRequester] = useState(false);
  const [showSupplier, setShowSupplier] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);

  const { filters, totalPurchases } = useSelector((state) => state.purchases);
  const dispatch = useDispatch();

  //update the filters array
  const applyFilter = (e, data, remove) => {
    if (data) {
      // console.log("Data Present - " , data);
      const value = {
        value1: data.fromDate,
        value2: data.toDate,
      };
      const filterType = "deliveryDate";
      // console.log("value - " , value);
      //  add or remove filter from filters array
      const newFilters = (prevFilters) => {
        const updatedFilters = remove
          ? prevFilters.filter(
              (filter) =>
                !(filter.type === filterType)
            )
          : [...prevFilters, { type: filterType, value }];
        return updatedFilters;
      };
      const filterArray = newFilters(filters);
      dispatch(setFilters([...filterArray]));
      // console.log(filters);
      return;
    } else {
      const value = e.target.value;
      const filterType = e.target.id.split("-").at("0");
      // console.log(filterType)

      //  add or remove filter from filters array
      const newFilters = (prevFilters) => {
        const updatedFilters = e.target.checked
          ? [...prevFilters, { type: filterType, value }]
          : prevFilters.filter(
              (filter) =>
                !(filter.type === filterType && filter.value === value)
            );
        return updatedFilters;
      };
      const filterArray = newFilters(filters);
      dispatch(setFilters([...filterArray]));
      // console.log("Filters inside Filter - " , filters);
    }
  };

  const { register, handleSubmit, getValues, setValue } = useForm();

  const applyDateFilter = (data) => {
    if (data.fromDate === "" || data.toDate === "") {
      return;
    } else {
      let remove = false;
      applyFilter({}, data, remove);
    }
  };

  const removeDateFilter = (e) => {
    e.preventDefault();
    const currentValues = getValues();
    if (currentValues.fromDate === "" || currentValues.toDate === "") {
      return;
    } else {
      let remove = true;
      applyFilter({}, currentValues, remove);
       console.log(currentValues.fromDate )
     setValue("fromDate", "");
     setValue("toDate", "");
    }
  };
  return (
    <div className="w-full rounded-lg shadow-lg bg-grey-5 flex items-center justify-between p-2">
      <p className="font-semibold text-lg font-inter text-blue-55 px-4 tracking-wider  ">
        Filters
      </p>
      {/* <div className='flex items-center'>
           
             <div className='flex items-center gap-x-1 bg-blue-25 text-white font-inter p-2 rounded-l-3xl  text-[13px]'>
                <FaFilter />
                <p>Filter</p>
                <IoIosArrowDown />
             </div>
            
             <div className='relative'>
             <input  className=' bg-white  text-black font-inter p-2 rounded-r-3xl  text-[13px] '
             placeholder='Search Here'/>
             <CgSearch  className='absolute top-2.5 right-3 text-black text-opacity-50 z-10 '/>
             </div>
             
          </div>  */}

      <div className="flex items-center gap-x-2">
        <div className=" rounded-md border-2 border-grey-25 px-2  cursor-pointer relative">
          <div
            onClick={() => setShowDeliveryDate((prev) => !prev)}
            className="flex items-center gap-x-3 h-full py-1 "
          >
            <p>Delivery Date</p>
            <IoIosArrowDown />
          </div>

          {/* dropdown  */}
          <div
            className={`absolute top-12 -left-[150%]  bg-white rounded-md z-10  shadow-sm text-sm w-[250%] gap-y-2 flex flex-col items-center py-1 ${
              showDeliveryDate === true
                ? "block"
                : "hidden -z-10 transition-all duration-700"
            }`}
          >
            <form onSubmit={handleSubmit(applyDateFilter)}>
              <div className="flex items-start gap-x-5">
                <div className="py-2">
                  <p className="text-sm font-semibold text-black  text-opacity-60 mb-1">
                    From:
                  </p>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    {...register("fromDate", {})}
                    className="rounded-md text-xs"
                  />
                </div>
                <div className="py-2">
                  <p className="text-sm font-semibold text-black  text-opacity-60 mb-1">
                    To:
                  </p>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    {...register("toDate", {})}
                    className="rounded-md text-xs"
                  />
                </div>
              </div>

              <div className="flex justify-end w-[100%]">
                <button
                  className="cursor-pointer rounded-md text-xs text-red-25 underline py-2 px-3 "
                  onClick={(e) => removeDateFilter(e)}
                >
                  Remove
                </button>
                <button
                  type="submit"
                  className="bg-green-5 cursor-pointer rounded-md text-xs text-white py-2 px-3 "
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* status filter  */}
        <div className="rounded-md border-2 border-grey-25 px-2  cursor-pointer relative">
          <div
            onClick={() => setShowStatus((prev) => !prev)}
            className="flex items-center gap-x-3 h-full py-1"
          >
            <p>Status</p>
            <IoIosArrowDown />
          </div>

          {/* dropdown  */}
          <div
            className={`absolute top-12 left-0  bg-white rounded-md z-10 p-2 shadow-sm text-sm w-[120%] gap-y-2 flex flex-col ${
              showStatus === true
                ? "block"
                : "hidden -z-10 transition-all duration-700"
            }`}
          >
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Pending
              <input
                type="checkbox"
                className="accent-green-5 "
                id="status-pending"
                value="Pending"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Draft
              <input
                type="checkbox"
                className="accent-green-5"
                id="status-draft"
                value="Draft"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Completed
              <input
                type="checkbox"
                className="accent-green-5"
                id="status-completed"
                value="Completed"
                onChange={(e) => applyFilter(e)}
              />
            </label>
          </div>
        </div>

        {/* <div className='flex items-center gap-x-3 rounded-md border-2 border-grey-25 px-2 py-1 cursor-pointer'>
                <p>Type</p>
                <IoIosArrowDown/>
               </div> */}

        {/* location filter  */}
        <div className=" rounded-md border-2 border-grey-25 px-2  cursor-pointer relative">
          <div
            onClick={() => setShowLocation((prev) => !prev)}
            className="flex items-center gap-x-3 h-full py-1  "
          >
            <p>Location</p>
            <IoIosArrowDown />
          </div>

          {/* dropdown  */}
          <div
            className={`absolute top-12 left-0  bg-white rounded-md z-10 p-2 shadow-sm text-sm w-[120%] gap-y-2 flex flex-col ${
              showLocation === true
                ? "block"
                : "hidden -z-10 transition-all duration-700"
            }`}
          >
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end ">
              Head Quators
              <input
                type="checkbox"
                className="accent-green-5"
                id="location-headQuators"
                value="Head Quaters"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Back Office
              <input
                type="checkbox"
                className="accent-green-5"
                id="location-backOffice"
                value="Back Office"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              WareHouse
              <input
                type="checkbox"
                className="accent-green-5"
                id="location-wareHouse"
                value="WareHouse"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Branch Office
              <input
                type="checkbox"
                className="accent-green-5"
                id="location-branchOffice"
                value="Branch Office"
                onChange={(e) => applyFilter(e)}
              />
            </label>
          </div>
        </div>

        <div className="flex items-center gap-x-3 rounded-md border-2 border-grey-25 px-2 py-1 cursor-pointer">
          <p>Requester</p>
          <IoIosArrowDown />
        </div>

        {/* <div className='flex items-center gap-x-3 rounded-md border-2 border-grey-25 px-2 py-1 cursor-pointer'>
                <p>Supplier</p>
                <IoIosArrowDown/>
               </div> */}

        {/* location filter  */}
        <div className=" rounded-md border-2 border-grey-25 px-2 cursor-pointer relative">
          <div
            onClick={() => setShowDepartment((prev) => !prev)}
            className="flex items-center gap-x-3 h-full py-1 "
          >
            <p>Department</p>
            <IoIosArrowDown />
          </div>

          {/* dropdown  */}
          <div
            className={`absolute top-12 left-0  bg-white rounded-md z-10 p-2 shadow-sm text-sm w-[120%] gap-y-2 flex flex-col ${
              showDepartment === true
                ? "block"
                : "hidden -z-10 transition-all duration-700"
            }`}
          >
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Marketing
              <input
                type="checkbox"
                className="accent-green-5"
                id="department-marketing"
                value="Marketing"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Administration
              <input
                type="checkbox"
                className="accent-green-5"
                id="department-administration"
                value="Administration"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Human Resources
              <input
                type="checkbox"
                className="accent-green-5"
                id="department-humanresources"
                value="Human Resources"
                onChange={(e) => applyFilter(e)}
              />
            </label>
            <label className="flex flex-row-reverse items-center gap-x-2 text-opacity-80 justify-end">
              Sales
              <input
                type="checkbox"
                className="accent-green-5"
                id="department-sales"
                value="Sales"
                onChange={(e) => applyFilter(e)}
              />
            </label>
          </div>
        </div>
      </div>

      <DownloadPurchase data={totalPurchases} />
    </div>
  );
};
