import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReportFilters from "../../../../data/reportFilters.json"
import { Link } from "react-router-dom";

export const ShowReport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [filtersArray , setFiltersArray] = useState([]);
  return (
    <div className="w-[calc(100vw-280px)]  min-h-screen flex justify-center py-10 ">
      <div className="h-[80%] w-full bg-white  p-x-5 py-10">
        <form onSubmit={handleSubmit(() => {})} className="flex flex-col w-full gap-y-10">
          <div className="flex flex-wrap items-center justify-between w-[90%] mx-auto">
            {/* creation date  */}
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[30%] relative">
              Creation Date
              <input
                type="date"
                id="creationDate"
                name="creationDate"
                className="border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
                {...register("creationDate", { required: true })}
              />
              {errors.creationDate && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Creation Date
                </span>
              )}
            </label>

            {/* approval date  */}
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[30%] relative">
              Approval Date
              <input
                type="date"
                id="approvalDate"
                name="approvalDate"
                className="border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
                {...register("approvalDate", { required: true })}
              />
              {errors.approvalDate && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Approval Date
                </span>
              )}
            </label>

            {/* delivery date  */}
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[30%] relative">
              Delivery Date
              <input
                type="date"
                id="deliveryDate"
                name="deliveryDate"
                className="border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
                {...register("deliveryDate", { required: true })}
              />
              {errors.deliveryDate && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Delivery Date
                </span>
              )}
            </label>
          </div>

           {/* lower part  */}
          <div className="w-full flex items-start justify-between">
            {/* left side  */}
            <div className="w-[50%] grid grid-cols-3 gap-x-14 gap-y-5 mx-auto items-center pt-[150px]">
                   
                   {
                    ReportFilters.map((filter , index) => (
                      <label key={index} className="flex gap-x-2">
                        <input
                        type="checkbox"
                        id={filter.name}
                        name={filter.name}
                        />
                       <p className="text-grey-50 font-semibold"> {filter.name}</p>
                      </label>
                    ))
                   }

            </div>

            {/* right side  */}
            <div className="w-[45%] flex flex-col gap-y-4">

              {/* Purchase Order type  */}
            
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[80%] mx-auto relative">
              Purchase Order Type
              <select
              id="purchaseOrdertype"
              name="purchaseOrdertype"
              {...register("purchaseOrdertype" , {required:true})}
              className=" bg-white border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
              >
                <option value="all">All</option>
              </select>
              {errors.purchaseOrdertype && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Purchase Order Type
                </span>
              )}
            </label>

               {/* Location  */}
            
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[80%] mx-auto relative">
              Location/Shipping Address
              <select
              id="location"
              name="location"
              {...register("location" , {required:true})}
              className=" bg-white border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
              >
                <option value="all">All</option>
              </select>
              {errors.location && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Location
                </span>
              )}
            </label>

            {/* Supplier */}
            
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[80%] mx-auto relative">
             Supplier
              <select
              id="supplier"
              name="supplier"
              {...register("supplier" , {required:true})}
              className=" bg-white border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
              >
                <option value="all">All</option>
              </select>
              {errors.supplier && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Supplier
                </span>
              )}
            </label>

            {/* Categories */}
            
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[80%] mx-auto relative">
              Categories
              <select
              id="categories"
              name="categories"
              {...register("categories" , {required:true})}
              className=" bg-white border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
              >
                <option value="all">All</option>
              </select>
              {errors.categories && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Categories
                </span>
              )}
            </label>


            {/* Budgets */}
            
            <label className="font-semibold text-lg text-black text-opacity-80 flex flex-col gap-y-3 w-[80%] mx-auto relative">
              Budgets
              <select
              id="budgets"
              name="budgets"
              {...register("budgets" , {required:true})}
              className=" bg-white border-2 border-grey-100 rounded-lg p-3 w-full text-black text-sm"
              >
                <option value="all">All</option>
              </select>
              {errors.budgets && (
                <span className="text-xs text-red-5 absolute -bottom-5 left-3">
                  Please Enter Budget
                </span>
              )}
            </label>
            <div className="flex items-start gap-x-4 w-[90%] mx-auto pl-5">
              
            <button  className="mt-2 px-3 py-2 border-2 border-grey-100 hover:bg-green-5 hover:text-white text-black text-opacity-80 font-semibold rounded-md">
            Save Filter
          </button>

             <Link to={"/admin/reports/run-report"}>
             
            <button  className=" mt-2 px-3 py-2.5 rounded-md bg-green-5 text-white  text-opacity-80 font-semibold ">
            Run Report
          </button>
             </Link>
            </div>
            </div>
          </div>
        
        </form>
      </div>
    </div>
  );
};
