import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { ImCheckboxChecked } from "react-icons/im";
import { ImCheckboxUnchecked } from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logIn } from '../../../services/operations/userAPI';


const LoginForm = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();

      const dispatch = useDispatch();
      const navigate = useNavigate();
    const [showPassword , setShowPassword] = useState(false)
    const [keepmesignedIn , setkeepmesignedIn] = useState(false)

    function submitLogin(data) {
        // console.log(data)
        dispatch(logIn(data , navigate))
    }
  return (
    <>
    <form onSubmit={handleSubmit(submitLogin)} className='w-full mt-14 ' >

        <label  className=' w-full relative text-lg font-semibold text-black flex flex-col gap-y-3 '>
            <p>Email Address <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type='email'
            id='email'
            name='email'
            placeholder="hello@example.com"
            className='w-[100%] rounded-lg h-[48px]  bg-white text-black  outline outline-[1px] px-2 py-1 font-normal text-sm'
            {...register("email" , {
                required: {
                    value:true,
                    message:"Please Enter Your Email"
                }
            })}
            />
            {errors.email && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.email.message}</span>
            )}

        </label>

        <label  className=' mt-6 w-full relative text-lg font-semibold text-black flex flex-col gap-y-3'>
            <p>Password <sup className='text-green-5 text-lg '>*</sup></p>
            <input
            type={`${showPassword === true ? "text" : "password"}`}
            id='password'
            name='password'
            className='w-[100%]  rounded-lg h-[48px]  bg-white text-black  outline outline-[1px] px-2 py-1 font-normal text-sm'
            {...register("password" , {
                required: {
                    value:true,
                    message:"Please Enter Your Password"
                }
            })}
            />
            {errors.password && (
                <span className='absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal'>{errors.password.message}</span>
            )}
          {/* eyeicon  */}
          <span
           className='absolute top-[60%]  right-2 cursor-pointer text-grey-50 '
           onClick={() => {setShowPassword( (prev) => !prev)}}>
            {
                showPassword ? ( <IoIosEye className='text-2xl' />) : ( <IoIosEyeOff className='text-2xl' />)
            }
          </span>

          {/* Forgot Password  */}
          <span className='absolute top-2 right-0 text-sm text-green-5'>
            <Link to={"/forgot-password"}>Forgot Password</Link>
          </span>
        </label>

        <div className='mt-6 flex flex-row-reverse gap-x-3 items-center justify-end text-grey-50'>
           <p> Keep me signed in</p>
           <span className='cursor-pointer text-green-5'
           onClick={() => {setkeepmesignedIn( (prev) => !prev)}}>
            {
                keepmesignedIn ? (<ImCheckboxChecked />) : (<ImCheckboxUnchecked />)
            }
            </span>
        </div>

        <button type='submit' className='w-full bg-green-5 text-white text-center rounded-lg  py-4 mt-5'>
            Login
        </button>
         
    </form>
    </>
  )
}

export default LoginForm