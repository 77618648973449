import React from 'react'
import { useSelector } from 'react-redux'

export const UpdateProfilePic = () => {
    const {user} = useSelector((state) =>  state.profile)
  return (
    <div className='flex items-center gap-x-2'>
        <img  className='h-10 w-10 rounded-full object-cover ' src={user?.photo}/> 

        <div>
            <p className='font-semibold text-black text-lg '>{user?.name}</p>
        </div>
    </div>
  )
}
