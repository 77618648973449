import React from 'react'
import { UpdateProfilePic } from './UpdateProfilePic'
import { useForm } from 'react-hook-form'
import { FloatingLabel } from 'flowbite-react'

export const AccountSettings = () => {
    const {
        register,
        handleSubmit,
        formState:{errors},
        setValue,
        getValues
    } = useForm()
  return (
    <div className='w-[75%] h-fit' >
        <div className='w-full flex flex-col gap-y-5'>
            <UpdateProfilePic/>

            <div>
                <p className='my-2 text-2xl font-semibold text-black text-opacity-80'>Organisation Information</p>

                <form className='grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3 mt-8' onSubmit={handleSubmit((data) => console.log(data))}>
              
                <FloatingLabel variant="outlined"
                 label="Business Name"
                 {...register("companyName" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="Email Address"
                 {...register("companyEmail" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="Country"
                 {...register("country" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="City"
                 {...register("city" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="Business Number"
                 {...register("companyNumber" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="FAX"
                 {...register("FAX" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="PostCode"
                 {...register("postCode" , {required:true})}  />

                 
                <FloatingLabel variant="outlined"
                 label="State"
                 {...register("state" , {required:true})}  />

                 {/* <button type='submit'>a</button> */}
                </form>
            </div>
        </div>
    </div>
  )
}
