import React from 'react'

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
} from  'chart.js'

import { Bar } from 'react-chartjs-2'
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
)
export const TotalSpentChart = () => {
  const data = {
    labels: ['6 Dec'],
    datasets : [
      {
        label:'Purchase Orders',
        data:[36000],
        backgroundColor:'#a5ceb3',
        boderColor:'black',
        borderWidth:1,
        barPercentage:0.5,
        categoryPercentage:0.3,
        borderRadius:3,

      },
      {
        label:'Invoices',
        data:[48000],
        backgroundColor:'#4DAF6E',
        boderColor:'black',
        borderWidth:1,
        barPercentage:0.5,
        categoryPercentage:0.3,
        borderRadius:3,
      },
      {
        label:'Expenses',
        data:[32000],
        backgroundColor:'#a5ceb3',
        boderColor:'black',
        borderWidth:1,
        barPercentage:0.5,
        categoryPercentage:0.3,
        borderRadius:3,
      }
      
    ],
    
  }

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 8000, // Increment by 8k (8000)
          callback: function (value, index, values) {
            return value === 0 ? '0 INR' : value / 1000 + 'k INR'; // Convert values to 'Xk INR'
          },
        },
      },
    },
  };
  return (
    <div className='flex flex-col gap-y-8'>
        <p className='text-black font-inter h-[20%]'>Total Spent </p>

       <div className='my-6 h-[60%] w-full'>
         <Bar
             data={data}
             options={options}
            />; 
       </div>
    </div>
  )
}
