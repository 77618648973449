import React from "react";
import Create from "../admin_common/Create";
import Draft from "../admin_common/Draft";
import Pending from "../admin_common/Pending";
import { GetPurchase } from "./GetPurchase/GetPurchase";


export default function PurchaseRequests() {
 
  return (
    <div className=" w-[calc(100vw-280px)] min-h-screen bg-grey-25 py-10">
      <div className="w-[95%] mx-auto flex flex-col items-center gap-x-4  ">
        <div className="flex items-center justify-start w-full gap-x-5">
          <Pending
            pending={2}
            linkedto={"/admin/purchase-requests/pending-purchase-requests"}
          />
          <Draft
            drafts={4}
            linkedto={"/admin/purchase-requests/draft-purchase-requests"}
          />
          <Create
            linkto={"/admin/purchase-requests/create-purchase-requisition"}
          />
        </div>
           
           {/* table  */}
        <div className="w-full mt-10">
          <GetPurchase />
         {/* <GetPurchaseCopy /> */}
        </div>
      </div>
    </div>
  );
}
