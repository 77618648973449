import {useState} from "react";
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import Spinner from "../components/common/Spinner";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../services/operations/userAPI";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
export const ResetPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const location = useLocation();
      const [loading, setLoading] = useState(false)
      const [showPasswordOne , setShowPasswordOne] = useState(false)
      const [showPasswordTwo , setShowPasswordTwo] = useState(false)

      const submitReset = async(data) => {
        if(data.newPassword !== data.confirmPassword){
            toast.error("Password dont match")
            return;
        }
          setLoading(true);
        const token = location.pathname.split('/').at(-1);
        dispatch(resetPassword(data.newPassword ,data.confirmPassword ,token , navigate));
          setLoading(false)
      }
  return (
    <div className="absolute top-0 left-0 w-screen min-h-screen h-auto flex justify-center items-center bg-grey-25  z-20 py-10">
      <div className="min-h-[70%] min-w-[40%] flex flex-col gap-y-6 px-6 py-8 bg-white rounded-2xl shadow-md ">
        {/* heading  */}
        <Link to={"/"}>
          <div className='w-full flex justify-center items-center'>
                <div className=' flex  items-center mt-1 relative'>
                <FaCaretLeft className='text-4xl  text-green-5 font-semibold absolute top-0 right-5'/>
                <FaCaretRight className='text-4xl text-green-5 '/>
                </div>
                <p className='text-4xl font-extrabold text-green-5 tracking-[0.1em] '>Procura<span className='font-extralight'>Flow</span></p>
            </div>
          </Link>

        <p className="text-2xl font-semibold text-black font-inter text-center">
          Reset Password
        </p>

        <form onSubmit={handleSubmit(submitReset)}>
          <label className=" mt-6 w-full relative text-lg font-semibold text-black flex flex-col gap-y-3">
            <p>
               New Password <sup className="text-green-5 text-lg ">*</sup>
            </p>
            <input
              type={`${showPasswordOne === true ? "text" : "password"}`}
              id="newPassword"
              name="newPassword"
              className="w-[100%]  rounded-lg h-[48px]  bg-white text-black  outline outline-[1px] px-2 py-1 font-normal text-md"
              {...register("newPassword", {
                required: {
                  value: true,
                  message: "Please Enter Your Password",
                },minLength:{value:6, message:"Minimum 6 characters are required"}
              })}
            />
            {errors.newPassword && (
              <span className="absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal">
                {errors.newPassword.message}
              </span>
            )}
            {/* eyeicon  */}
            <span
              className="absolute top-[60%]  right-2 cursor-pointer text-grey-50 "
              onClick={() => {
                setShowPasswordOne((prev) => !prev);
              }}
            >
              {showPasswordOne ? (
                <IoIosEye className="text-2xl" />
              ) : (
                <IoIosEyeOff className="text-2xl" />
              )}
            </span>
          </label>

          <label className=" mt-6 w-full relative text-lg font-semibold text-black flex flex-col gap-y-3">
            <p>
               Confirm Password <sup className="text-green-5 text-lg ">*</sup>
            </p>
            <input
              type={`${showPasswordTwo === true ? "text" : "password"}`}
              id="confirmPassword"
              name="confirmPassword"
              className="w-[100%]  rounded-lg h-[48px]  bg-white text-black  outline outline-[1px] px-2 py-1 font-normal text-md"
              {...register("confirmPassword", {
                required: {
                  value: true,
                  message: "Please Enter Your Password",
                },minLength:{value:6, message:"Minimum 6 characters are required"}
              })}
            />
            {errors.confirmPassword && (
              <span className="absolute -bottom-6 left-1 bg-grey-25 text-green-5 text-xs px-2 py-1 rounded-md font-normal">
                {errors.confirmPassword.message}
              </span>
            )}
            {/* eyeicon  */}
            <span
              className="absolute top-[60%]  right-2 cursor-pointer text-grey-50 "
              onClick={() => {
                setShowPasswordTwo((prev) => !prev);
              }}
            >
              {showPasswordTwo ? (
                <IoIosEye className="text-2xl" />
              ) : (
                <IoIosEyeOff className="text-2xl" />
              )}
            </span>
          </label>

          <button type="submit" className="bg-green-5 text-white w-full py-3 rounded-lg mt-5">
                  {loading ? <Spinner/> : <p>Reset Password</p> }
          </button>
        </form>
      </div>
    </div>
  );
};
