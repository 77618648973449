import React from "react";
import { useForm } from "react-hook-form";
import CountryCode from "../../../../data/countrycode.json"

export const AddSupplier = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <div className="w-[calc(100vw-280px)] min-h-screen flex items-center">
      <div className="w-full bg-white py-8 px-4">
        <form className="flex-col flex gap-y-6" onSubmit={handleSubmit((data) => {console.log(data)})}>
          <div className="w-[90%] mx-auto flex items-center justify-between gap-x-7">
            <label className="flex flex-col  text-lg text-black font-semibold gap-y-3 relative w-full">
              Supplier’s Name
              <input
                type="text"
                placeholder="Enter Supplier Name"
                id="supplierName"
                name="supplierName"
                {...register("supplierName", {
                  required: true,
                })}
                className="bg-grey-25 tex-black text-sm rounded-lg p-4 outline outline-1 outline-grey-100 font-normal "
              />
              {errors.supplierName && (
                <span className="absolute -bottom-3 left-1 text-xs text-red-5">
                  Please Enter Supplier Name
                </span>
              )}
            </label>

            <label className="flex flex-col  text-lg text-black font-semibold gap-y-3 relative w-full">
              Unique Code
              <input
                type="text"
                placeholder="Enter Enter Code"
                id="uniqueCode"
                name="uniqueCode"
                {...register("uniqueCode", {
                  required: true,
                })}
                className="bg-grey-25 tex-black text-sm rounded-lg p-4 outline outline-1 outline-grey-100 font-normal"
              />
              {errors.uniqueCode && (
                <span className="absolute -bottom-3 left-1 text-xs text-red-5">
                  Please Enter Supplier Name
                </span>
              )}
            </label>

            <label className="flex flex-col  text-lg text-black font-semibold gap-y-3 relative w-full">
              Automatic Purchase Order
              <input
                type="text"
                placeholder="Enter Enter Purchase Order "
                id="purchaseOrder"
                name="purchaseOrder"
                {...register("purchaseOrder", {
                  required: true,
                })}
                className="bg-grey-25 tex-black text-sm rounded-lg p-4 outline outline-1 outline-grey-100 font-normal"
              />
              {errors.purchaseOrder && (
                <span className="absolute -bottom-3 left-1 text-xs text-red-5">
                  Please Enter Purchase Order{" "}
                </span>
              )}
            </label>
          </div>

          <div className="w-[90%] mx-auto flex items-center justify-between gap-x-7">
            <label className="flex flex-col  text-lg text-black font-semibold gap-y-3 relative w-full">
              Name
              <input
                type="text"
                placeholder="Enter  Name"
                id="Name"
                name="Name"
                {...register("Name", {
                  required: true,
                })}
                className="bg-grey-25 tex-black text-sm rounded-lg p-4 outline outline-1 outline-grey-100 font-normal "
              />
              {errors.Name && (
                <span className="absolute -bottom-3 left-1 text-xs text-red-5">
                  Please Enter Name
                </span>
              )}
            </label>

            {/* phoneNo  */}
            <div className="flex flex-col text-lg font-semibold gap-2">
              <label htmlFor="phonenumber" className="lable-style">
                Phone Number
              </label>

              <div className="flex gap-5 text-sm h-full">
                <div className="flex w-[81px] flex-col gap-2">
                  <select
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="Enter first name"
                    className="p-4 w-[80px] rounded-lg bg-grey-25 outline outline-1 outline-grey-100"
                    {...register("countrycode", { required: true })}
                  >
                    {CountryCode.map((ele, i) => {
                      return (
                        <option key={i} value={ele.code}>
                          {ele.code} -{ele.country}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="flex w-[calc(100%-90px)] flex-col gap-2">
                  <input
                    type="number"
                    name="phonenumber"
                    id="phonenumber"
                    placeholder="12345 67890"
                    className="bg-grey-25 rounded-lg outline outline-1 outline-grey-100 p-4"
                    {...register("phoneNo", {
                      required: {
                        value: true,
                        message: "Please enter your Phone Number.",
                      },
                      maxLength: { value: 12, message: "Invalid Phone Number" },
                      minLength: { value: 10, message: "Invalid Phone Number" },
                    })}
                  />
                </div>
              </div>
              {errors.phoneNo && (
                <span className="-mt-1 text-[12px] text-yellow-100">
                  {errors.phoneNo.message}
                </span>
              )}
            </div>

            <label className="flex flex-col  text-lg text-black font-semibold gap-y-3 relative w-full">
              Email Address
              <input
                type="email"
                placeholder="hello@example.com "
                id="email"
                name="email"
                {...register("email", {
                  required: true,
                })}
                className="bg-grey-25 tex-black text-sm rounded-lg p-4 outline outline-1 outline-grey-100 font-normal"
              />
              {errors.email && (
                <span className="absolute -bottom-3 left-1 text-xs text-red-5">
                  Please Enter Email{" "}
                </span>
              )}
            </label>
          </div>

          <button type="submit" className="bg-green-5 text-white p-3 font-semibold rounded-lg w-[150px] ml-8 mt-8">Create </button>
        </form>
      </div>
    </div>
  );
};
