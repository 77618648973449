import { toBeEmpty } from '@testing-library/jest-dom/dist/matchers';
import React, { useEffect, useState } from 'react'
import { FaIndianRupeeSign } from 'react-icons/fa6';

export const RunReport = () => {

  /////////////Dummy Report Data/////////////////////
  const dummyData = [
    {
      status :"Pending",
      location: "Main Location",
      supplier:"Amazon",
      gross:1400,
      deliveryDate:"2024-05-02",
    },
    {
      status :"Draft",
      location: "Main Location",
      supplier:"Amazon",
      gross:1400,
      deliveryDate:"2024-05-02",
    },

  ]
  ///////////////////////////////////////////////
  const [loading, setLoading] = useState(false);
  const[filteredData , setFilteredData] = useState([]);
  useEffect(()=> {
     setLoading(true)
     setFilteredData(dummyData)
     setLoading(false)
  },[])

  return (
    <div  className="w-[calc(100vw-280px)] min-h-screen flex flex-col gap-y-10 py-20">

  {
    loading ? (<p>Loading.....</p>) :
               filteredData.length ? (
               <div>
                 <div  className='w-[90%] mx-auto overflow-hidden rounded-2xl'>
        <table className='w-full'>
          <thead className='h-[50px] bg-grey-75 text-black text-opacity-60  font-inter  '>
            <tr className='text-center'>
              <th>No.</th>
              <th>Status</th>
              <th>Location/Purchaser</th>
              <th>Supplier</th>
              <th>Gross</th>
              <th>Delivery</th>
            </tr>
          </thead>
          <tbody>
             {
              filteredData.map((report , index) => (
                <tr className='h-[50px] bg-white text-black text-opacity-60 font-inter text-center'>
                  <td>{`${index <10 ? `0${index+1}` : `${index+1}`}`}</td>
                  <td> <p className={` py-1 text-sm rounded-2xl ${report.status !== "Pending" ? " text-blue-5 bg-blue-5 bg-opacity-40" : " text-orange-5 bg-orange-5 bg-opacity-40"}`}>{report?.status}</p></td>
                  <td>{report?.location}</td>
                  <td>{report.supplier}</td>
                  <td><p className='flex items-center gap-x-1 w-full justify-center '><FaIndianRupeeSign/>{report.gross}</p></td>
                  <td>{report.deliveryDate}</td>
                </tr>
              ))
             }
          </tbody>
        </table>
                </div>

                <div className='w-[90%] mx-auto flex justify-end mt-10  '>
                  <button  className=" mt-2 px-6 py-2.5 rounded-md bg-green-5 text-white  text-opacity-80 font-semibold pl-5">Export</button>
                </div>
               </div>
               ) : (<p>No Data Found</p>)
  }
    </div>
  )
}
