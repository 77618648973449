import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const AddStockBalance = () => {
  /////////////////////Dummy Data ///////////////
  const dummyCatgeories = ["Category1", "Category2", "Category3"];

  const dummySuppliers = ["Supplier1", "Supplier2", "Supplier3"];
  ///////////////////////////////////////////////
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setCategories(dummyCatgeories);
    setSuppliers(dummySuppliers);
  }, []);

 

  const onDownload = (data) => {
    console.log(data);
  };
  return (
    <div className="w-[calc(100vw-280px)] min-h-screen flex items-center">
      <div className="w-full min-h-[60vh] bg-white p-5 gap-y-6 flex flex-col">
        {/* section-1  */}
        <div>
          <div className="flex items-center gap-x-3 text-xl">
            <div className="border-2 border-black text-black rounded-full flex justify-center items-center h-[40px] w-[40px] font-semibold">
              <p>1</p>
            </div>
            <p className="text-xl font-semibold text-black">
              Download a Template File
            </p>
          </div>

          <div className="w-[80%] mx-auto">
            <form onSubmit={handleSubmit(onDownload)}>
              <label
                htmlFor="category"
                className="flex items-center justify-between w-full text-lg text-black font-semibold my-3 pr-4 relative"
              >
                Category
                <select
                  name="category"
                  id="category"
                  {...register("category" , {required:true})}
                  className="bg-grey-25  font-normal text-sm rounded-lg py-2 px-5 w-[300px]"
                >
                  <option value="">Select Category</option>
                  {categories.length !== 0 && (
                    <>
                      {categories.map((category, index) => (
                        <option value={category} key={index}>
                          {category}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {errors.category && (
                  <span className="absolute -bottom-3 text-xs text-red-5">
                    Please Enter Category
                  </span>
                )}
              </label>

              <label
                htmlFor="supplier"
                className="flex items-center justify-between w-full text-lg text-black font-semibold my-3 pr-4 relative"
              >
                Supplier
                <select
                  name="supplier"
                  id="supplier"
                  {...register("supplier" , {required:true})}
                  className="bg-grey-25  font-normal text-sm rounded-lg py-2 px-5 w-[300px]"
                >
                  <option value="" className="text-sm">
                    Select Supplier
                  </option>
                  {suppliers.length !== 0 && (
                    <>
                      {suppliers.map((supplier, index) => (
                        <option
                          value={supplier}
                          id="supplier"
                          key={index}
                          className="text-sm"
                        >
                          {supplier}
                        </option>
                      ))}
                    </>
                  )}
                </select>
                {errors.supplier && (
                  <span className="absolute -bottom-3 text-xs text-red-5">
                    Please Enter Supplier
                  </span>
                )}
              </label>

              <div className="w-full flex justify-end pr-4">
                <button
                  type="submit"
                  className="px-2 w-[300px] py-1 rounded-2xl bg-green-5 text-white"
                >
                  Download
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* section-2  */}
        <div>
          <div className="flex items-center gap-x-3 text-xl">
            <div className="border-2 border-black text-black rounded-full flex justify-center items-center h-[40px] w-[40px] font-semibold">
              <p>2</p>
            </div>
            <p className="text-xl font-semibold text-black">
              Open the download file and fill in the details.
            </p>
          </div>
        </div>

        {/* section-3  */}
        <div className="">
          <div className="flex items-center gap-x-3 text-xl">
            <div className="border-2 border-black text-black rounded-full flex justify-center items-center h-[40px] w-[40px] font-semibold">
              <p>3</p>
            </div>
            <p className="text-xl font-semibold text-black">
              Upload the filled up file here.
            </p>
          </div>

          <div className=" flex justify-between items-center w-[80%] mx-auto pr-4">
            <p className="text-lg text-black text-opacity-50 font-semibold">Note: you can add or upload a max of 1000 rows per upload</p>

            <div className="flex flex-col gap-y-5">
              <input
                type="file"
                onChange={() => {}}
                className="bg-grey-25 rounded-lg w-[300px] p-3"
                placeholder="Select"
                accept="image/png, image/gif, image/jpeg"
              />
              <button
                onClick={() => {}}
                className="px-2 w-[300px] py-1 rounded-2xl bg-green-5 text-white"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
