import React from 'react'
import CreateFormTemplate from '../../../common/CreateFormTemplate'

const CreatePurchaseRequisition = () => {
  return (
    <div className=' absolute top-[1rem] left-[0px] w-screen  bg-grey-25 min-h-screen flex  justify-center pb-10'>
             <CreateFormTemplate 
             deliveryDate={true}
             location={true}
             department={true}
             references = {true}
             addItems={true}
             attachements={true}
             note={true}
             type={"PurchaseRequest"}
            />
    </div>

  )
}

export default CreatePurchaseRequisition