import React from 'react'
import CreateFormTemplate from '../../../common/CreateFormTemplate'

const CreateQuotationRequisition = () => {
  return (
    <div className=' absolute top-[1rem] left-[0px] w-screen  bg-grey-25 min-h-screen flex  justify-center pb-10'>
    <CreateFormTemplate 
    deliveryDate={true}
    location={true}
   
    note={true}
   />
</div>
  )
}

export default CreateQuotationRequisition